<nav class="navbar navbar-default headerMainArea ">
  <div class="container noPadding">

    <!-- Mobile view -->
    <div class="navbar navbar-default hidden visible-xs mobileViewHeader" role="navigation">
      <div class="navbar-header">
        <ul class="mobileIcons" *ngIf="!completeSignUp" >
          <!-- <li *ngIf="userType != '' && userType != null && userType != undefined">
            <app-notification (notificationClicked)="onNotificationClicked($event)"></app-notification>
          </li> -->

          <li>
            <button type="submit" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
          </li>
        </ul>
        <div class="logoArea text-xs-center noPadding" [ngClass]="[showOherOptions == false ? '' : 'noOptionHeader']">
          <div class="navbar-header logo" [class.activeDomain] = "activeSubDomain">
            <a *ngIf="!completeSignUp" (click)="homePageLink()">
              <img [src]="!activeSubDomain ? logoPath : '../../../assets/images/subDomainIcon.png'"/>
            </a>
            <a *ngIf="completeSignUp" (click)="logOut()">
              <img [src]="!activeSubDomain ? logoPath : '../../../assets/images/subDomainIcon.png'"/>
            </a>
          </div>
        </div>
        <div class="text-right header1AccountArea text-xs-center noPadding" *ngIf="completeSignUp">
          <ul class="setMenuRight">
            <li>
              <a (click)="logOut()">Logout</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="navbar-collapse collapse">
        <ul class="nav navbar-nav">
          <li>
            <div class="container-fluid noPadding">
              <div *ngIf="userType != '' && userType != null && userType != undefined && showOherOptions != true && !completeSignUp">
                <div class="col-xs-12 text-center header1AccountArea  noPadding" *ngIf="userId != ''">
                  <ul class="nav navbar-nav ">
                    <li class="dropdown" *ngIf="!activeSubDomain"><a class="dropdown-toggle" data-toggle="dropdown" href="#"><span [routerLink]="['/feed']">Feed</span></a></li>
                    <li class="dropdown" *ngIf="!activeSubDomain">
                      <a class="dropdown-toggle" data-toggle="dropdown" href="#">
                        <span>Coaches</span>
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                        <i class="fa fa-angle-up" aria-hidden="true"></i>
                      </a>
                      <ul class="dropdown-menu">
                        <li><a [routerLink]="['/coach-list']" [queryParams]="{ coachesType: 'all'}"
                            queryParamsHandling="merge">All Coaches</a></li>
                        <li><a [routerLink]="['/coach-list']" [queryParams]="{ coachesType: 'favorite'}"
                            queryParamsHandling="merge">Favorites</a></li>
                        <li><a [routerLink]="['/coach-list']" [queryParams]="{ coachesType: 'follow'}" queryParamsHandling="merge">Following</a></li>
                      </ul>
                    </li>
                    <li class="dropdown">
                      <a class="dropdown-toggle" data-toggle="dropdown" href="#">
                        <!-- <span>Contracts</span> -->
                        <span>Engagements</span>
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                        <i class="fa fa-angle-up" aria-hidden="true"></i>
                      </a>
                      <ul class="dropdown-menu">
                        <li><a (click)="koacheeOfferListLink()">Contracts</a></li>
                        <li><a (click)="invoiceListLink()">Invoices</a></li>
                        <li><a [routerLink]="['/sessions']">Sessions</a></li> 
                      </ul>
                    </li>
                    <li class="dropdown">
                      <a class="dropdown-toggle messageMenuLink" data-toggle="dropdown" href="#">
                        <span>Messages</span>
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                        <i class="fa fa-angle-up" aria-hidden="true"></i>
                      </a>
                      <ul class="dropdown-menu">
                        <li><a (click)="messagesPageLink()">Messages</a></li>
                        <li><a (click)="allNotificationsLink()">Notifications</a></li>
                      </ul>
                    </li>
                    <li class="dropdown">
                      <a [routerLink]="['/dashboard']" *ngIf="userType == 'Coach'">Dashboard</a>
                    </li>
                    <li class="dropdown">
                      <span *ngIf="((userType == 'Coach') && (!activeSubDomain))"  [routerLink]="['/advertise']" class="advLogo"><img src='{{advertiseLogo}}'><span class="underText">Advertise</span></span>
                    </li>
                  </ul>
                </div>
                <div class="text-right header1AccountArea text-xs-center noPadding" *ngIf=" userId != ''">
                  <ul class="setMenuRight">
                    <li>
                      <ul class="nav navbar-nav rightSideUserMenu">
                        <li class="dropdown">
                          <a class="dropdown-toggle" data-toggle="dropdown" href="#">
                            <span class="currentUserName">{{userName}}</span>
                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                            <i class="fa fa-angle-up" aria-hidden="true"></i>
                            <ng-container *ngIf="userProfileImage != ''; else noUserImage">
                              <img src="{{userProfileImage}}" (error)="genricFunc.errorHandler($event)" alt="" class="profileImageInTopBar">
                            </ng-container>
                            <ng-template #noUserImage>
                                <img src="../../../assets/images/female.png" alt="" class="profileImageInTopBar">
                            </ng-template>
                          </a>
                          <ul class="dropdown-menu">
                            <li>
                              <a (click)="genricFunc.redirect(userType, handle)">My Profile</a>
                            </li>
                            <li class="dropdown" *ngIf="userType == 'Coach' && showKoacheeSuitePage > 0">
                              <a [routerLink]="['/coaching-suite']">Coaching Suite</a>
                            </li>
                            <li class="dropdown" *ngIf="userType == 'Coach' && showKoacheeSuitePage == 'null'">
                              <a [routerLink]="['/koachee-suite']">Coaching Suite</a>
                            </li>
                            <li>
                              <a [routerLink]="['/settings']">Settings</a>
                            </li>
                            <!-- <li>
                              <a [routerLink]="['/sessions']">Sessions</a>
                            </li> -->
                            <li>
                              <a [routerLink]="['/my-network']">My Network</a>
                            </li>
                            <!-- <li *ngIf="userType == 'Coach'">
                              <a [routerLink]="['/expertProfileReview']">Expert profile</a>
                            </li> -->
                            <li><a href="https://koachee.freshdesk.com/support/solutions" target="_blank">Help &
                                Support</a></li>
                            <li><a (click)="logOut()">Logout</a></li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                    </li>
                  </ul>
                </div>
              </div>

              <ng-container *ngIf="showLoginLink == true && showOherOptions != true;">
                <div
                  class="col-xs-12 col-sm-6 col-md-6 col-lg-6 text-right header1AccountArea text-xs-center pull-right logoutBtnArea"
                  *ngIf="userId == ''">
                  <span>Already have an account?</span> <a (click)="loginPageLink()" class="blueLink">Log in</a>
                </div>
              </ng-container>
              <ng-container *ngIf="showSignUpLink == true && showOherOptions != true;">
                <div
                  class="col-xs-12 col-sm-6 col-md-6 col-lg-6 text-right header1AccountArea text-xs-center pull-right logoutBtnArea"
                  *ngIf="userId == ''">
                  <span>Don't have an account?</span> <a (click)="signUpLink()" class="blueLink">Sign Up</a>
                </div>
              </ng-container>

              <ng-container *ngIf="showSignUpLink == false && showLoginLink == false && showOherOptions != true;">
                <div
                  class="col-xs-12 col-sm-6 col-md-6 col-lg-6 text-right header1AccountArea text-xs-center pull-right logoutBtnArea normalLogoutPage"
                  *ngIf="userId == ''">
                  <a *ngIf="!activeSubDomain"  [routerLink]="['/feed']" class="blueLink">FEED</a>
                  <a *ngIf="!activeSubDomain" [routerLink]="['/coach-list']" [queryParams]="{ coachesType: 'all'}"
                  queryParamsHandling="merge" class="blueLink">COACHES</a>
                  <a (click)="loginPageLink()" class="blueLink">LOG IN</a>
                  <a (click)="signUpLink()" class="blueLink">SIGN UP</a>
                </div>
              </ng-container>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- other view -->
    <div class="container-fluid noPadding hidden visible-lg visible-md visible-sm">
      <div class="logoArea text-xs-center noPadding" [ngClass]="[showOherOptions == false ? '' : 'noOptionHeader']">
        <div class="navbar-header logo" [class.activeDomain] = "activeSubDomain">
          <a *ngIf="!completeSignUp" (click)="homePageLink()">
            <img [src]="!activeSubDomain ? logoPath : '../../../assets/images/subDomainIcon.png'" alt=""/>
          </a>  
          <a *ngIf="completeSignUp" (click)="logOut()">
            <img [src]="!activeSubDomain ? logoPath : '../../../assets/images/subDomainIcon.png'" alt=""/>
          </a>
        </div>
      </div>
      <div class="text-right header1AccountArea text-xs-center noPadding" *ngIf="completeSignUp">
        <ul class="setMenuRight">
          <li>
            <a (click)="logOut()">Logout</a>
          </li>
        </ul>
      </div>
      <div *ngIf="userType != '' && userType != null && userType != undefined && showOherOptions != true && !completeSignUp">
        <div
          class="col-xs-10 col-sm-5 col-md-6 col-lg-9 text-right text-xs-left  headerMenu header1AccountArea  noPadding"
          *ngIf="userId != ''">
          <ul class="nav navbar-nav headerFont">
            <li class="dropdown" *ngIf="!activeSubDomain" [ngClass]="[currentpage == 'feed' ? 'activeMenu' : '']"><a class="dropdown-toggle"
                data-toggle="dropdown" href="#"><span [routerLink]="['/feed']">Feed</span></a></li>
            <li class="dropdown" *ngIf="!activeSubDomain" [ngClass]="[currentpage == 'searchCoach' ? 'activeMenu' : '']">
              <a class="dropdown-toggle" [routerLink]="['/coach-list']" [queryParams]="{ coachesType: 'all'}"
              queryParamsHandling="merge">
                <span>COACHES</span>
              </a>
              <ul class="dropdown-menu">
                <li><a [routerLink]="['/coach-list']" [queryParams]="{ coachesType: 'all'}"
                    queryParamsHandling="merge">Search</a></li>
                <li><a [routerLink]="['/coach-list']" [queryParams]="{ coachesType: 'favorite'}"
                    queryParamsHandling="merge">Favorites</a></li>
                <li><a [routerLink]="['/coach-list']" [queryParams]="{ coachesType: 'follow'}" queryParamsHandling="merge">Following</a></li>
              </ul>
            </li>

            <li class="dropdown"
              [ngClass]="[currentpage == 'coacheeProposal' || currentpage == 'coacheeOffer' || currentpage == 'invoiceList' ? 'activeMenu' : '']">
              <a class="dropdown-toggle" (click)="koacheeOfferListLink()">
                <span>Engagements</span>
                <!-- <span>CONTRACTS</span> -->
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="koacheeOfferListLink()">Contracts</a></li>
                <li><a (click)="invoiceListLink()">Invoices</a></li>
                <li><a [routerLink]="['/sessions']">Sessions</a></li>               
              </ul>
            </li>
            <li class="dropdown"
              [ngClass]="[currentpage == 'allNotification' || currentpage == 'message' ? 'activeMenu' : '']">
              <a class="dropdown-toggle messageMenuLink" (click)="messagesPageLink()">
                <span>MESSAGES</span>
              </a>
              <ul class="dropdown-menu">
                <li><a (click)="messagesPageLink()">Messages</a></li>
                <li><a (click)="allNotificationsLink()">Notifications</a></li>
              </ul>
            </li>
            <li class="dropdown" [ngClass]="[currentpage == 'dashboard' ? 'activeMenu' : '']">
              <a [routerLink]="['/dashboard']" *ngIf="userType == 'Coach'">Dashboard</a>
            </li>
          </ul>
        </div>
        <div class="text-right header1AccountArea text-xs-center noPadding" *ngIf=" userId != ''">
          <ul class="setMenuRight">
            <li [routerLink]="['/advertise']" *ngIf="((userType == 'Coach') && (!activeSubDomain))"  class="advLogo"><img src='{{advertiseLogo}}'><span class="underText">Advertise</span></li>
            <li>
              <app-notification (notificationClicked)="onNotificationClicked($event)"></app-notification>
            </li>
            <li>
              <ul class="nav navbar-nav rightSideUserMenu">
                <li class="dropdown">
                  <a class="dropdown-toggle" data-toggle="dropdown" href="">
                    <ng-container *ngIf="userProfileImage != ''; else noUserImage">
                      <img src="{{userProfileImage}}" alt="" (error)="genricFunc.errorHandler($event)" class="profileImageInTopBar" >
                    </ng-container>
                    <ng-template #noUserImage>
                      <img src="../../../assets/images/female.png" alt=""  class="profileImageInTopBar" >
                    </ng-template>
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <a (click)="genricFunc.redirect(userType, handle)">My Profile</a>
                    </li>
                    <li class="dropdown" *ngIf="userType == 'Coach' && showKoacheeSuitePage > 0">
                      <a [routerLink]="['/coaching-suite']">Coaching Suite</a>
                    </li>
                    <li class="dropdown" *ngIf="userType == 'Coach' && showKoacheeSuitePage == 'null'">
                      <a [routerLink]="['/koachee-suite']">Coaching Suite</a>
                    </li>
                    <li>
                      <a [routerLink]="['/settings']">Settings</a>
                    </li>
                    <!-- <li>
                      <a [routerLink]="['/sessions']">Sessions</a>
                    </li> -->
                    <li>
                      <a [routerLink]="['/my-network']">My Network</a>
                    </li>
                    <li><a (click) = "helpAndSupport()" href="https://koachee.freshdesk.com/support/solutions" target="_blank">Help & Support</a>
                    </li>
                    <li><a (click)="logOut()">Logout</a></li>
                    <!-- <li *ngIf="userType == 'Coach'">
                      <a [routerLink]="['/expertProfileReview']">Expert profile</a>
                    </li> -->
                 
                  </ul>
                </li>
              </ul>
            </li>
            <li>
            </li>
          </ul>
        </div>
      </div>

      <ng-container *ngIf="showLoginLink == true && showOherOptions != true;">
        <div
          class="col-xs-12 col-sm-6 col-md-6 col-lg-6 text-right header1AccountArea text-xs-center pull-right logoutBtnArea"
          *ngIf="userId == ''">
          <span>Already have an account?</span>
          <a (click)="loginPageLink()" class="blueLink">Log in</a>
        </div>
      </ng-container>
      <ng-container *ngIf="showSignUpLink == true && showOherOptions != true;">
        <div
          class="col-xs-12 col-sm-6 col-md-6 col-lg-6 text-right header1AccountArea text-xs-center pull-right logoutBtnArea"
          *ngIf="userId == ''">
          <span>Don't have an account?</span>
          <a (click)="signUpLink()" class="blueLink">Sign Up</a>
        </div>
      </ng-container>

      <ng-container *ngIf="showSignUpLink == false && showLoginLink == false && showOherOptions != true;">
        <div class="menuWithutLoginCenter" *ngIf="userId == ''">
          <a *ngIf="!activeSubDomain" [routerLink]="['/feed']" class="blueLink">FEED</a>
          <a *ngIf="!activeSubDomain" [routerLink]="['/coach-list']" [queryParams]="{ coachesType: 'all'}" queryParamsHandling="merge" class="blueLink">COACHES</a>
        </div>
        <div
          class="col-xs-12 col-sm-2 text-right header1AccountArea text-xs-center pull-right logoutBtnArea"
          *ngIf="userId == ''">
          <a (click)="loginPageLink()" class="blueLink">Log in</a>
          <a (click)="signUpLink()" class="blueLink">Sign Up</a>
        </div>
      </ng-container>
    </div>
  </div>
</nav>

<div class="notificationBarMain verficationBar" *ngIf="isUserVerified != 1 && isLoggedIn == true && hideVerificationBar == false">
  <app-header-email-verification></app-header-email-verification>
</div>

<!-- <li><a (click)="logOut()">Logout</a></li> -->

