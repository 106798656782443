import { Injectable, RendererFactory2, Renderer2, Inject } from '@angular/core';
import { Constants } from '../../consts';
import { LocalStorageComponent } from '../local-storage/local-storage';
import { Router, NavigationEnd } from '@angular/router';
import { ToasterService } from '../../services/toaster/toaster.service';
import { ShareDataProviderComponent } from '../share-data-provider/share-data-provider.component';
import { environment } from '../../../environments/environment';
import * as allRawLinks from '../../../assets/json/knowledgeBase.json';
import initHelpHero from 'helphero';
import { DOCUMENT } from '@angular/common';

import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as moment from 'moment';
import 'moment-timezone';
import * as FileSaver from 'file-saver';

declare let ga: Function;
declare let gtag: Function;

@Injectable({
	providedIn: 'root'
})
export class GenericFunctionsComponent {
	private renderer2: Renderer2;

	route: string;
	noImageUrl: any = "../../../assets/images/female.png";

	public constructor(
		private localStore: LocalStorageComponent,
		private _router: Router,
		private rendererFactory: RendererFactory2,
		private shareUserData: ShareDataProviderComponent,
		private toast: ToasterService,
		@Inject(DOCUMENT) private _document: Document,
		@Inject(PLATFORM_ID) private platformId: Object
	) {
		this.renderer2 = this.rendererFactory.createRenderer(null, null);
	}

	// helpHeroFunc() {
	// 	if (isPlatformBrowser(this.platformId)) {
	// 		var hlp = initHelpHero('by9jVEIPuDy');
	// 		hlp.identify(
	// 			this.getLoggedInUserID(),
	// 			{
	// 				name: this.getLoggedInFirstName(),
	// 				role: this.getLoggedInUserType(),
	// 				userId: this.getLoggedInUserID(),
	// 				created_at: this.getLoggedInUserCreated()
	// 			}
	// 		);
	// 	}
	// }

	addGoogleAnalyticsPage(pageName: String) {
		ga('set', 'page', pageName);
		ga('send', 'pageview');
		if (environment.production == true) {
			gtag('event', 'conversion', { 'send_to': 'AW-612976669/TSSqCOC8rdwBEJ2QpaQC' });
		}
	}

	switchOffLoader(loader: any) {
		setTimeout(() => {
			loader = false
		}, 15000);
	}

	getLoggedInUserID() {
		if (localStorage.getItem(Constants.profileKey)) {
			let data = JSON.parse(this.localStore.getProfile())
			if (data != undefined) {
				return data.ID;
			}
		}
		return '';
	}

	getLoggedInUserHandle() {
		if (localStorage.getItem(Constants.profileKey)) {

			let data = JSON.parse(this.localStore.getProfile())
			if (data != undefined) {
				return data.Handle;
			}
		}
		return '';
	}

	getLoggedInEmailID() {
		if (localStorage.getItem(Constants.profileKey)) {
			let data = JSON.parse(this.localStore.getProfile())
			if (data != undefined) {
				return data.Email;
			}
		}
		return '';
	}

	getLoggedInUserProfileImageURL() {
		if (localStorage.getItem(Constants.profileKey)) {
			let data = JSON.parse(this.localStore.getProfile())
			if (data.ProfileImageURL != undefined && data.ProfileImageURL != '' && data.ProfileImageURL != 'NULL' && data.ProfileImageURL != null) {
				return data.ProfileImageURL;
			}
		}
		return '';
	}

	getLoggedInUserName() {
		if (localStorage.getItem(Constants.profileKey)) {
			let data = JSON.parse(this.localStore.getProfile())
			if (data != undefined) {
				return data.Name;
			}
		}
		return '';
	}

	getLoggedInFirstName() {
		if (localStorage.getItem(Constants.profileKey)) {
			let data = JSON.parse(this.localStore.getProfile())
			if (data != undefined) {
				return data.FirstName;
			}
		}
		return '';
	}

	getLoggedInUserProvider() {
		if (localStorage.getItem(Constants.profileKey)) {
			let data = JSON.parse(this.localStore.getProfile())
			if (data != undefined) {
				return data.AuthProvider;
			}
		}
		return '';
	}

	getLoggedInUserType() {
		if (localStorage.getItem(Constants.profileKey)) {
			let data = JSON.parse(this.localStore.getProfile())
			if (data != undefined) {
				// if ((data.ActiveType != null) && (data.ActiveType != undefined)) {
				// 	return data.ActiveType;
				// } else {
				// 	return data.Type;
				// }
				return data.Type;
			}
		}
		return '';
	}

	isUserLoggedIn() {
		if (this.localStore.getProfile() == undefined) {
			// this._router.navigateByUrl(Constants.homePath);
			return false;
		}
		return true;
	}

	isUserLogOut() {
		if (this.localStore.getProfile() == undefined) {
			return true;
		}
		return false;
	}


	getNotificationCountSec() {
		if (localStorage.getItem(Constants.notificationCountKey)) {
			let data = JSON.parse(this.localStore.getNotificationCount())
			if (data != undefined) {
				return data;
			}
		}
		return 0;
	}

	setNotificationCountSec(count: any) {
		localStorage.setItem(Constants.notificationCountKey, count)
	}

	removeNotificationCount() {
		localStorage.setItem(Constants.notificationCountKey, '')
	}

	getUnreadMessagesCountSec() {
		if (localStorage.getItem(Constants.unreadMessagesCountKey)) {
			let data = JSON.parse(this.localStore.getUnreadMessagesCount())
			if (data != undefined) {
				return data;
			}
		}
		return '';
	}

	getIsverifiedEmail() {
		if (localStorage.getItem(Constants.profileKey)) {
			let data = JSON.parse(this.localStore.getProfile())
			if (data != undefined) {
				return data.StatusID;
			}
		}
		return '';
	}

	getLoggedInUserCreated() {
		if (localStorage.getItem(Constants.profileKey)) {
			let data = JSON.parse(this.localStore.getProfile())
			if (data != undefined) {
				return data.Created;
			}
		}
		return '';
	}

	getNewDateFormat() {
		var date = new Date();
		var hours = date.getHours();
		var hr = hours < 10 ? '0' + hours : hours;
		var minutes = date.getMinutes();
		var min = (minutes < 10) ? '0' + minutes : minutes;
		var newTimeString = hr + ':' + min;
		return 'Today ' + newTimeString;
	}


	setUnreadMessagesCountSec(count: any) {
		localStorage.setItem(Constants.unreadMessagesCountKey, count)
	}

	amplitudeFunc(eventName: any) {
		let loggedInUserId: any = 0
		let loggedInUserName: string = ''
		let loggedInUserType: string = ''
		if (environment.isAmplitudeActive) {
			const s = this.renderer2.createElement('script');
			s.type = 'text/javascript';

			if (this.isUserLoggedIn()) {
				loggedInUserId = this.getLoggedInUserID()
				loggedInUserName = this.getLoggedInUserName()
				loggedInUserType = this.getLoggedInUserType()
			}
			else {
				loggedInUserId = 0
				loggedInUserName = 'Guest User'
				loggedInUserType = 'Guest User'
			}

			s.text = "amplitude.getInstance().setUserProperties({'User ID': '" + loggedInUserId + "', ' Type': '" + loggedInUserType + "', 'Name': '" + loggedInUserName + "' });amplitude.getInstance().logEvent('" + eventName + "');";
			this.renderer2.appendChild(this._document.body, s);
		}
	}

	// dummyObject: Object;
	// array: any = [];
	postSignUpToRequest(signUpResponse: any, toast: ToasterService, dummyObject: Object, array: any) {
		if (Constants.currentPage == 'SignUp') {
			if (signUpResponse.status == 1) {
				// Get Skills from server at login time
				if (signUpResponse.response.validSkills) {
					for (let i = 0; i < signUpResponse.response.validSkills.length; i++) {
						dummyObject = {
							'id': i,
							'itemName': signUpResponse.response.validSkills[i]
						}
						array.push(dummyObject);
					}
					this.localStore.setSkills(array);
				}
				if (signUpResponse.response.data) {
					// Status = 4 = Email Pending Verificaion
					//if (signUpResponse.response.UserStatus == 4) {
					// toast.success(Constants.emailVerificationNewPending)
					//	this._router.navigateByUrl(Constants.verificationPath);
					//return
					// Status = 5 = Pending User Type
					//} else 
					if (signUpResponse.response.UserStatus == 5 || signUpResponse.response.UserStatus == 4) {
						if (signUpResponse.response.data.Type == "") {
							this._router.navigateByUrl(Constants.completeSignUpAccountPath);
						} else {
							if (signUpResponse.response.data.Type == 'Coach') {
								this.redirect(signUpResponse.response.data.Type, signUpResponse.response.data.Handle)
								// this._router.navigateByUrl(Constants.thankYouPath);
							} else {
								this.localStore.setCompleteProfilePage("completeProfile")
								this._router.navigateByUrl(Constants.homePath);
								// this._router.navigateByUrl(Constants.thankYouCoacheePath);
							}
						}
						// Status = 1 = Active
					} else if (signUpResponse.response.UserStatus == 1) {
						if (signUpResponse.response.data.Type == "") {
							this._router.navigateByUrl(Constants.completeSignUpAccountPath);
						}
						// else if (signUpResponse.response.data.Type == "Coach" && this.shareUserData.withoutLoginUserData == '' && this.shareUserData.withoutLoginUserData == undefined && this.shareUserData.withoutLoginUserData == null) {
						// 	this.redirect(signUpResponse.response.data.Type, signUpResponse.response.data.Handle)
						// }
						// else if (signUpResponse.response.data.Type == "Coachee" && this.shareUserData.withoutLoginUserData == '' && this.shareUserData.withoutLoginUserData == undefined && this.shareUserData.withoutLoginUserData == null) {
						// 	this.localStore.clearPublishProfile();
						// 	this._router.navigateByUrl(Constants.homePath);
						// } 
						else {
							// this.userWithoutLoginRouting('signup');
							if (signUpResponse.response.data.Type == 'Coach') {
								this.redirect(signUpResponse.response.data.Type, signUpResponse.response.data.Handle)
								// this._router.navigateByUrl(Constants.thankYouPath);
							} else {
								this.localStore.setCompleteProfilePage("completeProfile")
								this._router.navigateByUrl(Constants.homePath);
								// this._router.navigateByUrl(Constants.thankYouCoacheePath);
							}
							// this._router.navigateByUrl(Constants.thankYouPath);
						}
					} else {
						setTimeout(() => {
							this._router.navigateByUrl(Constants.verificationPath);
						}, 1500);
					}
					if (this.localStore.setProfile(JSON.stringify(signUpResponse.response.data))) {
						this.localStore.setToken(signUpResponse.response.token);
					}

				} else {
					toast.error(signUpResponse.response.message, Constants.errorTitle);
				}
			}
			else if (signUpResponse.status == 0) {
				toast.error(signUpResponse.response.err, Constants.errorTitle);
			} else {
				console.log(signUpResponse);
			}
		}
	}

	postLoginToRequest(loginResponse: any, toast: ToasterService, dummyObject: Object, array: any) {
		if (Constants.currentPage == 'login') {
			if (loginResponse.status == 1) {
				// Get Skills from server at login time
				if (loginResponse.response.validSkills) {
					for (let i = 0; i < loginResponse.response.validSkills.length; i++) {
						dummyObject = {
							'id': i,
							'itemName': loginResponse.response.validSkills[i]
						}
						array.push(dummyObject);
					}
					this.localStore.setSkills(array);
				}
				if (loginResponse.response.data) {
					// Status = 4 = Email Pending Verificaion
					// if (loginResponse.response.StatusCode == 4) {
					// toast.success(Constants.emailVerificationNewPending)
					// this._router.navigateByUrl(Constants.verificationPath);
					// return
					// Status = 5 = Pending User Type
					// } else
					if (loginResponse.response.StatusCode == 5) {
						if (loginResponse.response.data.Type == "") {
							this._router.navigateByUrl(Constants.completeSignUpAccountPath);
						} else {
							this._router.navigateByUrl(Constants.homePath);
						}
						// Status = 1 = Active
					} else if (loginResponse.response.StatusCode == 1 || loginResponse.response.StatusCode == 4) {
						if (loginResponse.response.data.Type == "") {
							this._router.navigateByUrl(Constants.completeSignUpAccountPath);
						}
						else if (this.shareUserData.withoutLoginUserData == '' || this.shareUserData.withoutLoginUserData == undefined || this.shareUserData.withoutLoginUserData == null) {
							this.localStore.clearPublishProfile();
							this._router.navigateByUrl(Constants.homePath);
						} else {
							this.userWithoutLoginRouting('login');
						}
					}
					else if (loginResponse.response.StatusCode == -1) {
						this.toast.error(Constants.deletedUser)
						return;
					}
					else {
						setTimeout(() => {
							this._router.navigateByUrl(Constants.verificationPath);
						}, 1500);
					}
					if (this.localStore.setProfile(JSON.stringify(loginResponse.response.data))) {
						this.localStore.setToken(loginResponse.response.token);
					}
				} else {
					toast.error(loginResponse.response.message, Constants.errorTitle);
				}
			} else if (loginResponse.status == 0) {
				toast.error(loginResponse.response.err, Constants.errorTitle);
			} else {
				console.log(loginResponse);
			}
		}
	}

	redirect(usrType: any, handle: any, amplitudeEvent: any = '', seoTagData: any = {}) {


		this.shareUserData.seoTag = {
			name: seoTagData.Name,
			description: seoTagData.DetailDescription,
			image: seoTagData.ProfileImageURL,
			url: environment.angularSiteURL + 'coach/' + seoTagData.Handle,
			headline: seoTagData.Headline
		}

		if (handle == this.getLoggedInUserHandle() && usrType == 'Coach') {
			this._router.navigateByUrl(Constants.coachMyProfilePath)
		} else if (handle != this.getLoggedInUserHandle() && usrType == 'Coach') {
			this._router.navigate(['coach', handle])
		} else if (handle == this.getLoggedInUserHandle() && usrType == 'Coachee') {
			this._router.navigateByUrl(Constants.koacheeMyProfilePath)
		} else if (handle != this.getLoggedInUserHandle() && usrType == 'Coachee') {
			this._router.navigate(['coachee', handle])
		}
		else if (usrType == 'Coachee') {
			this._router.navigate(['coachee', handle])
		}
		if (amplitudeEvent != '') {
			switch (amplitudeEvent) {
				case 'reviews': this.amplitudeFunc(Constants.reviewsEvent); break;
				case 'more': this.amplitudeFunc(Constants.moreEvent); break;
				case 'viewFullProfile': this.amplitudeFunc(Constants.viewFullProfileEvent); break;
				case 'CoachProfileButton': this.amplitudeFunc(Constants.CoachProfileButtonEvent); break;
				case 'homeCoachProfileBtn': this.amplitudeFunc(Constants.CoachProfileButtonEvent); break;
				default: break;
			}
		}
	}

	userWithoutLoginRouting(val: any) {
		let userData = this.shareUserData.withoutLoginUserData;
		let userType = this.shareUserData.withoutLoginType;
		if (userType == 'GetAll') {
			this._router.navigate(['coach-list'], {
				queryParams: { 'coachesType': 'all' }
			});
		} else if (userType == 'profile') {
			this._router.navigate(['coach', userData]);
		} else if (userType == 'feed') {
			if (userData == 0) {
				this._router.navigateByUrl(Constants.feedPath);
			} else {
				this._router.navigateByUrl(Constants.feedPath + '/' + userData);
			}
		} else if (userType == 'Message') {
			setTimeout(() => {
				if (this.getLoggedInUserID() == userData.ID) {
					this._router.navigateByUrl(Constants.homePath);

				} else {
					const userInfo = {
						'ID': userData.ID,
						'ProfileImageURL': userData.ProfileImageURL,
						'Name': userData.Name
					}
					// if (userData.FreeConsultStatus == false) {
					this.shareUserData.chatMessageUserData = JSON.stringify(userInfo);
					this._router.navigateByUrl(Constants.chatPath);
					// }
					// if (userData.FreeConsultStatus == true || userData.ContractsID != null) {
					// this._router.navigateByUrl(Constants.homePath);
					// this.redirect('Coach', userData.Handle)
					// 	}
				}
			}, 300);
		}
		else {
			if (val == 'login') {
				this.redirect('Coach', userData.Handle)
				// this._router.navigateByUrl(Constants.homePath);
			}
			if (val == 'signup') {
				if (this.getLoggedInUserType() == 'Coach') {
					this.redirect(this.getLoggedInUserType(), this.getLoggedInUserHandle())
					// this._router.navigateByUrl(Constants.thankYouPath);
				} else {
					this.localStore.setCompleteProfilePage("completeProfile")
					this._router.navigateByUrl(Constants.homePath);
					// this._router.navigateByUrl(Constants.thankYouCoacheePath);
				}
			}
			// else {
			// 	if (this.getLoggedInUserType() == 'Coach') {
			// 		console.log("2")
			// 		this.redirect(this.getLoggedInUserType(), this.getLoggedInUserHandle())
			// 	}
			// 	else {
			// 		console.log("3")
			// 		this._router.navigateByUrl(Constants.homePath);
			// 	}
			// }
		}
	}

	showNonEditableModal(singleData: any) {
		this.shareUserData.singleData = ''
		this.shareUserData.showNonEditableModal = true
		this.shareUserData.singleData = singleData

		return this.shareUserData.singleData
	}

	closeNonEditableModal() {
		this.shareUserData.showNonEditableModal = false
	}

	seperatePackageData(packageData: any) {
		this.shareUserData.singlePackageData = []
		this.shareUserData.multiplePackageData = []
		this.shareUserData.freeConsultationData = []
		packageData.forEach((element: any) => {
			if (element.PackageSession == 1 && element.PackageRate > 0) {
				this.shareUserData.singlePackageData.push(element)
			}
			else if (element.PackageSession > 1 && element.PackageRate > 0) {
				this.shareUserData.multiplePackageData.push(element)
			}
			else {
				this.shareUserData.freeConsultationData.push(element)
			}
		});
		return [this.shareUserData.singlePackageData,
		this.shareUserData.multiplePackageData, this.shareUserData.freeConsultationData]
	}

	getUserSkill(val: any) {
		let showSkills = '';
		const skillData = val.toString().split(',');
		let userSkills = skillData.sort()
		let allSkills = this.localStore.getSkills()
		let count = 0;

		allSkills.forEach((skill: any) => {
			if (userSkills[count] == skill.id) {
				if (count == 0) {
					showSkills = skill.itemName
				} else {
					showSkills = showSkills + ', ' + skill.itemName
				}
				count++
			}
		});
		return showSkills
	}

	getAllLinks(pageName: any, pageElement: any) {
		let allLinks = allRawLinks['knowledgeBase']
		let url: any = '';
		allLinks.forEach((Links: any) => {
			if (pageName == Links.page && pageElement == Links.element) {
				url = Links.link
			}
		})
		return url || ''
	}

	isPasswordCorrect(password: any) {
		var trigger = password,
			regexp = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-])(?=.*?[0-9]).{8,}$')
		return regexp.test(trigger);
	}

	isEmpty(val: any) {
		if (val == null || val == '' || val == undefined) {
			return false
		}
		return true
	}

	isAllowNumbersOrFloat(val: any) {
		var regexp = new RegExp('^[0-9]+(.[0-9]{1,2})?$')
		var reg = regexp.test(val);
		return reg;
	}

	isNumbers(val: any) {
		var regexp = new RegExp('^[0-9]*$')
		var reg = regexp.test(val);
		return reg;
	}

	isMobileNumbers(val: any) {
		var regexp = new RegExp('^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$')
		var reg = regexp.test(val);
		return reg;
	}

	isPhoneNumbers(val: any) {
		var regexp = new RegExp('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$')
		var reg = regexp.test(val);
		return reg;
	}

	removeSpaces(val: any) {
		let returnVal = val.replace(/\s/g, "");
		return returnVal;
	}

	isNameCorrect(name: any) {
		var trigger = name,
			// regexp = new RegExp('^[a-zA-Z0-9 ]*$')
			regexp = new RegExp('^[a-zA-Z ]*$')
		return regexp.test(trigger);
	}
	isflNameCorrect(name: any) {
		var trigger = name,
			regexp = new RegExp(/^[A-Za-z ][A-Za-z0-9 _\-\`\!\*\{\}\[\]\:\;\'\?]*$/gm)
		// regexp = new RegExp('^[a-zA-Z0-9 `~!^*()-_=+[]{};:'"<>?.|]*$')
		// regexp = new RegExp('^[a-zA-Z ]*$')
		return regexp.test(trigger);
	}


	removeLinkFromUrl(val: any) {
		if (val) {
			var url = val.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('.com/')[1];
			if (url == undefined) {
				return val;
			} else {
				return url;
			}
		} else {
			return val;
		}
	}

	isHandleCorrect(val: any) {
		var trigger = val,
			regexp = new RegExp('^[a-z0-9 ]*$')
		return regexp.test(trigger);
	}

	isContainSpace(val: any) {
		var trigger = val,
			regexp = new RegExp("[^\s-]")
		return regexp.test(trigger);
	}

	hasWhiteSpace(val: any) {
		return val.indexOf(' ') >= 0;
	}




	isUrl(url: any) {
		var trigger = url,
			regexp = new RegExp('^(https?:\\/\\/)' + // protocol
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
				'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
				'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
				'(\\#[-a-z\\d_]*)?$', 'i');
		return !!regexp.test(url);
	}
	// isYoutubeUrlValid(url: any) {
	// 	var regexp = (/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
	// 	return regexp.test(url);
	// }

	YouTubeGetID(url: any) {
		url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
		return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
	}

	setUserWithoutLogin(coachData: any, loginType: any) {
		this.shareUserData.withoutLoginUserData = coachData;
		this.shareUserData.withoutLoginType = loginType;
	}

	resetUserWithoutLogin() {
		this.shareUserData.withoutLoginUserData = '';
		this.shareUserData.withoutLoginType = '';
	}

	chatMessage(index: any, coachLists: any, amplitudeEvent: any = '', pageNameEvent: any = '', randomEvent: any = '') {
		if (index != undefined) {
			this.shareUserData.chatMessageUserData = JSON.stringify(coachLists[index]);
			this._router.navigateByUrl(Constants.chatPath);
		}
		this.removeNotificationCount();
	}

	chatMessageBox(profileData: any) {
		this.amplitudeFunc(Constants.contactCoachEvent)
		let userInfo;
		if (this.isEmpty(profileData.LastName) == true) {
			userInfo = {
				'ID': profileData.ID,
				'ProfileImageURL': profileData.ProfileImageURL,
				'Name': profileData.Name + ' ' + profileData.LastName,
				'StatusID': profileData.StatusID
			}
		} else {
			userInfo = {
				'ID': profileData.ID,
				'ProfileImageURL': profileData.ProfileImageURL,
				'Name': profileData.Name,
				'StatusID': profileData.StatusID
			}
		}
		this.removeNotificationCount();
		this.shareUserData.chatMessageUserData = JSON.stringify(userInfo);
		this._router.navigateByUrl(Constants.chatPath);
	}

	hideHireModal(val: any) {
		this.shareUserData.alreadyHired = val;
	}

	setUtcDate(val: any) {
		let UTCDate = new Date(val).getUTCDate()
		let UTCYear = new Date(val).getUTCFullYear()
		let UTCMonth = new Date(val).getUTCMonth() + 1
		let DateHours = new Date(val).getUTCHours();
		let DateMins = new Date(val).getUTCMinutes();
		let DateSec = new Date(val).getUTCSeconds();
		let UTCMainDate = UTCYear + '-' + UTCMonth + '-' + UTCDate + ' ' + DateHours + ':' + DateMins + ':' + DateSec;
		return UTCMainDate;
	}

	createSocialLink(image: any, headline: any, usrUrl: any, name: any) {
		return 'https://koachee.page.link/?link=' + usrUrl + '&si=' + image + '&st=' + encodeURIComponent(name) + '&sd=' + encodeURIComponent(headline)
	}


	calculatePercentage(val: any, type: any) {
		let latestVal = ((this.isEmpty(val[0][type]) != false) ? val[0][type] : 0);
		let oldVal = ((this.isEmpty(val[1][type]) != false) ? val[1][type] : 0);
		let diff = latestVal - oldVal;
		let percentage: any;
		percentage = ((diff / oldVal) * 100);
		return percentage
	}

	errorHandler(event: any) {
		event.target.src = "../../../../assets/images/female.png";
	}

	titleCaseWord(word: string) {
		if (!word) return word;
		return word[0].toUpperCase() + word.substr(1).toLowerCase();
	}

	parseBody(body: any) {
		return body.replace(/(https?:\/\/[^\s]+)/g, "<a href='$1' target='_blank' >$1</a>")
	}

	selectedTimezoneToUtc(time: any, timezone: string) {
		return moment.tz("2015-06-01 " + time, timezone).utc().format('HH:mm');
	}

	utcToSelectedTimezone(time: any, timezone: string) {
		var timeString = '20150601 ' + time + ':00'; // in utc
		var utcTime = moment.utc(timeString, 'YYYYMMDD HH:mm:ss');
		var mainTime = utcTime.clone().tz(timezone);
		return mainTime.format('HH:mm')
	}

	selectedTimezoneToUtcWithDate(time: any, timezone: string) {
		return moment.tz(time, timezone).utc().format('YYYY-MM-DD HH:mm:ss');
	}

	currentUtcToSelectedTimezone(time: any, timezone: string) {
		return moment.utc(time, 'YYYY-MM-DD hh:mm:ss A').tz(timezone).format('dddd MMM DD, YYYY [at] hh:mm A');
	}

	utcToSelectedTimezoneFrontend(time: any, timezone: string) {
		return moment.utc(time, 'YYYY-MM-DD hh:mm:ss A').tz(timezone).format('hh:mm A');
	}

	utcToSelectedTimezoneForBackend(time: any, timezone: string) {
		return moment.utc(time, 'YYYY-MM-DD hh:mm:ss A').tz(timezone).format('HH:mm');
	}

	downLoadFile(data: any, type: string, fileName: string) {
		let blob = new Blob([data], { type: type });
		FileSaver.saveAs(blob, fileName)
	}
}
