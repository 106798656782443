<div class="content">
  <ngx-loading [show]="loading" [config]="{}"></ngx-loading>
  <app-cus-header1 [totalBadge]='badgeCountNumber' [currentpage]="'home'"></app-cus-header1>
  <div class="fullGreySec" *ngIf="!showPageNotFound">
    <div class="fullHomeArea">
      <div class="featuredCoachSec" *ngIf="featuredCoach.length > 0;">
        <div *ngIf="onMobile == false">
          <div *ngIf="currentCoachDataLoaded == true" class="toggleArea"
            [ngClass]="[selectredRow == 'slider1' ? 'showToggle' : '']">
            <app-show-current-card [currentCoachData]=currentCoachData [loggedInUserData]=loggedInUserData
              (notifyhome)="onNotifyClicked($event)"></app-show-current-card>
          </div>
        </div>
        <div *ngIf="onMobile == true">
          <div *ngIf="currentCoachDataLoaded == true && clickedOnSameCard == false" class="toggleArea"
            [ngClass]="[selectredRow == 'slider1' ? 'showToggle' : '']">
            <app-show-current-card [currentCoachData]=currentCoachData [loggedInUserData]=loggedInUserData
              (notifyhome)="onNotifyClicked($event)"></app-show-current-card>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="homeCoachesList">
              <!-- <h4 class="headingLeftMargin">This Month</h4> -->
              <div class="row" *ngIf="currentCoachDataLoaded == true">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" *ngIf="featuredCoach.length > 0;">
                  <h3 class="marginTop2em">
                    <div class="fullWidth text-center">
                      <span class="homeSkillheading">Featured Coaches</span>
                    </div>
                  </h3>
                  <div class="row marginTop1em coachSlider">
                    <swiper [config]="config" class="mySwiper">
                      <div swiperSlide *ngFor="let featCoach of featuredCoach; let i = index">
                        <div class="singleCoachBox" (click)="select(i, featCoach, 'slider1')"
                          [ngClass]="{active: isActive(i, 'slider1')}">
                          <app-home-coach-card [coachData]='featCoach'></app-home-coach-card>
                        </div>
                      </div>
                    </swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="sponsoredCoachs.length > 0 && sponsoredCoachs != null;">
        <div *ngIf="onMobile == false">
          <div *ngIf="currentCoachDataLoaded == true" class="toggleArea"
            [ngClass]="[selectredRow == 'slider0' ? 'showToggle' : '', activeRow == 'sponsoredCoachsSec' ? 'currentUpperRow' : '']">
            <app-show-current-card [currentCoachData]=currentCoachData [loggedInUserData]=loggedInUserData
              (notifyhome)="onNotifyClicked($event)"></app-show-current-card>
          </div>
        </div>
        <div *ngIf="onMobile == true">
          <div *ngIf="currentCoachDataLoaded == true && clickedOnSameCard == false" class="toggleArea"
            [ngClass]="[selectredRow == 'slider0' ? 'showToggle' : '', activeRow == 'sponsoredCoachsSec' ? 'currentUpperRow' : '']">
            <app-show-current-card [currentCoachData]=currentCoachData [loggedInUserData]=loggedInUserData
              (notifyhome)="onNotifyClicked($event)"></app-show-current-card>
          </div>
        </div>

        <div class="container">
          <div class="row">
            <div class="homeCoachesList">
              <div class="row" *ngIf="currentCoachDataLoaded == true">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                  *ngIf="sponsoredCoachs.length > 0 && sponsoredCoachs != null;">
                  <h3 class="marginTop2em">
                    <div class="fullWidth text-center">
                      <span class="homeSkillheading">Sponsored Coaches</span>
                      <a class="expertiseLink" (click)="gotoExpertise('sponsored')">
                        Explore All
                      </a>
                    </div>
                  </h3>
                  <div class="row marginTop1em coachSlider">
                    <!-- <div *ngFor="let sponsored of sponsoredCoachs; let i = index">
                      <div class="singleCoachBox" (click)="select(i, sponsored, 'slider0')"
                        [ngClass]="{active: isActive(i, 'slider0')}">
                        <app-home-coach-card [coachData]='sponsored' [loggedInUserData]=loggedInUserData>
                        </app-home-coach-card>
                      </div>
                    </div> -->
                    <swiper [config]="config" class="mySwiper">
                      <div swiperSlide *ngFor="let sponsored of sponsoredCoachs; let i = index">
                        <div class="singleCoachBox" (click)="select(i, sponsored, 'slider0')"
                          [ngClass]="{active: isActive(i, 'slider0')}">
                          <app-home-coach-card [coachData]='sponsored' [loggedInUserData]=loggedInUserData>
                          </app-home-coach-card>
                        </div>
                      </div>
                      <div class="singleCoachBox exploreCard" *ngIf="sponsoredCoachs.length == 8"
                        (click)="gotoExpertise('sponsored')">
                        <div><i class="fa fa-plus-circle " aria-hidden="true"></i></div>
                        <h3>EXPLORE ALL</h3>
                        <h3>SPONSORED COACHES</h3>
                      </div>
                    </swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="homeSkillArea" [ngClass]="[activeRow == 'skillSec' ? 'currentUpperRow' : '']">
        <div *ngFor="let currentSkill of allSkills; let count = index">
          <div *ngIf="onMobile == false">
            <div *ngIf="currentCoachDataLoaded == true" class="toggleArea"
              [ngClass]="[selectredRow == 'currentSkill'+count ? 'showToggle' : '']">
              <app-show-current-card [currentCoachData]=currentCoachData [loggedInUserData]=loggedInUserData
                (notifyhome)="onNotifyClicked($event)"></app-show-current-card>
            </div>
          </div>
          <div *ngIf="onMobile == true">
            <div *ngIf="currentCoachDataLoaded == true && clickedOnSameCard == false" class="toggleArea"
              [ngClass]="[selectredRow == 'currentSkill'+count ? 'showToggle' : '']">
              <app-show-current-card [currentCoachData]=currentCoachData [loggedInUserData]=loggedInUserData
                (notifyhome)="onNotifyClicked($event)"></app-show-current-card>
            </div>
          </div>

          <div class="container">
            <div class="row">
              <div class="homeCoachesList">
                <div class="row" *ngIf="currentCoachDataLoaded == true">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h3 class="marginTop2em">
                      <div class="fullWidth text-center">
                        <span class="homeSkillheading">{{currentSkill.skill}} COACHES</span>
                        <a class="expertiseLink" (click)="gotoExpertise(currentSkill.expertiseUrlAddress)">
                          Explore All
                        </a>
                      </div>
                    </h3>
                    <div class="row marginTop1em coachSlider">
                      <!-- <div *ngFor="let currentSkillCoach of currentSkill.skillData; let i = index">
                        <div class="singleCoachBox" (click)="select(i, currentSkillCoach, 'currentSkill'+count)"
                          [ngClass]="{active: isActive(i, 'currentSkill'+count)}" >
                          <app-home-coach-card [coachData]='currentSkillCoach' [loggedInUserData]=loggedInUserData
                            [skill]='currentSkill.skill'></app-home-coach-card>
                        </div> -->
                        <swiper [config]="config" class="mySwiper">
                          <div swiperSlide *ngFor="let currentSkillCoach of currentSkill.skillData; let i = index">
                            <div class="singleCoachBox" (click)="select(i, currentSkillCoach, 'currentSkill'+count)"
                              [ngClass]="{active: isActive(i, 'currentSkill'+count)}"
                              *ngIf="i< currentSkill.skillData.length">
                              <app-home-coach-card [coachData]='currentSkillCoach' [loggedInUserData]=loggedInUserData
                                [skill]='currentSkill.skill'></app-home-coach-card>
                            </div>
                          </div>
                          <div class="singleCoachBox exploreCard" *ngIf="currentSkill.skillData.length == 8"
                            (click)="gotoExpertise(currentSkill.expertiseUrlAddress)">
                            <div><i class="fa fa-plus-circle " aria-hidden="true"></i></div>
                            <h3>EXPLORE ALL</h3>
                            <h3>{{currentSkill.skill | FirstLetter}} COACHES</h3>
                          </div>
                        </swiper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="lastSearchCoachs.length > 0 && lastSearchCoachs != null && isLoggedIn;">
        <div *ngIf="onMobile == false">
          <div *ngIf="currentCoachDataLoaded == true" class="toggleArea"
            [ngClass]="[selectredRow == 'slider2' ? 'showToggle' : '', activeRow == 'lastSearchSec' ? 'currentUpperRow' : '']">
            <app-show-current-card [currentCoachData]=currentCoachData [loggedInUserData]=loggedInUserData
              (notifyhome)="onNotifyClicked($event)"></app-show-current-card>
          </div>
        </div>
        <div *ngIf="onMobile == true">
          <div *ngIf="currentCoachDataLoaded == true && clickedOnSameCard == false" class="toggleArea"
            [ngClass]="[selectredRow == 'slider2' ? 'showToggle' : '', activeRow == 'lastSearchSec' ? 'currentUpperRow' : '']">
            <app-show-current-card [currentCoachData]=currentCoachData [loggedInUserData]=loggedInUserData
              (notifyhome)="onNotifyClicked($event)"></app-show-current-card>
          </div>
        </div>

        <div class="container">
          <div class="row">
            <div class="homeCoachesList">
              <div class="row" *ngIf="currentCoachDataLoaded == true">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                  *ngIf="lastSearchCoachs.length > 0 && lastSearchCoachs != null;">
                  <h3 class="marginTop2em">
                    <div class="fullWidth text-center">
                      <span class="homeSkillheading">Recently Viewed</span>
                    </div>
                  </h3>
                  <div class="row marginTop1em coachSlider">
                    <swiper [config]="config" class="mySwiper">
                      <div swiperSlide *ngFor="let lastCoach of lastSearchCoachs; let i = index">
                        <div class="singleCoachBox" (click)="select(i, lastCoach, 'slider2')"
                          [ngClass]="{active: isActive(i, 'slider2')}">
                          <app-home-coach-card [coachData]='lastCoach' [loggedInUserData]=loggedInUserData>
                          </app-home-coach-card>
                        </div>
                      </div>
                    </swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>
<app-cus-footer></app-cus-footer>


<app-send-message-modal></app-send-message-modal>

<!-- <div class="popupBg" *ngIf="showPublishProfilePopup && showPublishProfilePopupOneTime">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 marginTop1em publishProfile">
            <app-incomplete-profile [showPublishMsg]=showPublishMsg [showAsPopup]="true" [popupData]=popupData
              [popupDataStatus]=popupDataStatus></app-incomplete-profile>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-right marginTop2em">
            <button type="submit" class="btn btnBlue" data-dismiss="modal" (click)="closePublishPopup()">Skip</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!-- 
<div class="popupBg" *ngIf="showPublishProfilePopup && showPublishProfilePopupOneTime">
  <div class="modal-dialog">
    <div class="modal-content borderNone">
      <div class="modal-header modalTopBorder">
        <h4 class="modal-title modalHeader16px">Your Coaching Profile is not Visible to Coachees!</h4>
      </div>
      <div class="modal-body">
        <p class="uncompleteProfile">
          Your coaching profile is not yet published. Go to Settings and provide some essential details before
          publishing
          your profile.
        </p>
      </div>
      <div class="modal-footer ">
        <a class="linkBtn" (click)="closePublishPopup()">Skip for Now!</a>
        <button type="submit" class="btn blueBtn messageBtn modalSettingButton" (click)="completePublishProfile()"
          data-dismiss="modal">Settings</button>
      </div>
    </div>
  </div>
</div> -->