<ngx-loading [show]="loading" [config]="{}"></ngx-loading>
<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <button type="submit" class="close" (click)="closeeModal()">&times;</button>
            <h4 class="modalHeader16px">Schedule Session</h4>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-4">
                    <div class="row logocss">
                        <div><img src={{currentModel.logoPath}} width="70px" class="img-fluid" alt="...." />
                        </div>
                    </div>
                    <div class="row col-left">
                        <p>Define Name (Kochee)</p>
                        <h3><b>One-on-One</b></h3>
                        <p><i class="fa fa-clock-o" aria-hidden="true"></i> &nbsp; {{currentModel.durationHours}} Hours
                            {{currentModel.durationMinutes}} Mins</p>
                        <p><i class="fa fa-video-camera" aria-hidden="true"></i> &nbsp;
                            Web conferencing detail provided upon confirmation</p>
                    </div>
                </div>
                <div class="col-md-8 calmainbox">
                    <div class="col-md-8 col-sm-8 colEight">
                        <div class="selectBox">
                            <i class="fa fa-globe" aria-hidden="true"></i>
                            <select [(ngModel)]="currentModel.selectedTimeZone" name="timeZone"
                                (ngModelChange)="getTimeZone(currentModel.selectedTimeZone)" id="timezone"
                                aria-label="Default select example" class="form-select">
                                <option disabled value="">Select Your Timezone</option>
                                <option *ngFor="let timeZone of currentModel.timeZoneList" [ngValue]='timeZone'>
                                    {{timeZone}}
                                </option>
                            </select>
                        </div>
                        <h4 class="calhead"><b>Select a Date & Time</b></h4>
                    </div>
                    <div class="col-md-4 col-sm-4 ">
                    </div>
                    <div class="row colOuter">
                        <div class="col-md-8 col-sm-8">
                            <!-- <mat-calendar *ngIf="currentModel.loadCalendar" [selected]="selectedDate"
                                [dateClass]="dateClass()" [startAt]="startAt" [minDate]="minDate"
                                (selectedChange)="onSelect($event)" (click)="demo()"></mat-calendar> -->
                            <mat-calendar *ngIf="currentModel.loadCalendar" [selected]="selectedDate"
                                [dateClass]="dateClass()" [startAt]="startAt" [minDate]="minDate"
                                (selectedChange)="onSelect($event)" (click)="demo()"></mat-calendar>
                        </div>
                        <div *ngIf="currentModel.timebtn" class="col-md-4 col-sm-4 alignList">
                            <div class="showSelectedDate">
                                {{selectedDate | date:'EEEE, MMMM, d'}}
                            </div>
                            <div *ngIf="currentModel.timings.length>0; else noRecord">
                                <ul class="timebtnul caltimelist">
                                    <li class="timebtnlist" *ngFor="let time of currentModel.timings; let i = index">
                                        <button (click)="confirmTime(i)" *ngIf="!time.booked"
                                            [disabled]="time.status == 1" type="button"
                                            [ngClass]="time.status == 1 ? 'not-allowed' : ''" class="timebtn">

                                            <!-- <button (click)="confirmTime(i)" *ngIf="!time.booked" type="button" class="timebtn"> -->
                                            {{showConvertedTime(time.timeSlot)}}
                                        </button>

                                        <button type="button" *ngIf="time.booked"
                                            class="timeConfirmBtnLeft">{{showConvertedTime(time.timeSlot)}}</button>&nbsp;
                                        <button type="button" *ngIf="time.booked"
                                            (click)="showConfirmBox(time.timeSlot, i)"
                                            class="timeConfirmBtnRight">Confirm</button>
                                    </li>
                                </ul>

                            </div>
                            <ng-template #noRecord>
                                <div class="col-xs-12 noPadding">
                                    <b>No slot available</b>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <app-cus-footer></app-cus-footer>
        <div class="popupBg" *ngIf="(selectDateTimeModal)">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="submit" class="close" data-dismiss="modal" (click)="closeModal()">&times;</button>
                        <h4 class="modal-title modalHeader16px">Confirm</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="col-lg-12">
                                    <div class="notoficationPopuptext">
                                        Are you sure you want to confirm the Date & Time?<br>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <a class="linkBtn" data-dismiss="modal" (click)="closeModal()">Cancel</a>
                        <button type="submit" class="btn btnBlue" (click)="dateTimeConfirm()">Yes</button>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="modal-footer">
            <button type="submit" (click)="closeeModal()" class="btn gmailBtn">Close</button>
        </div>
    </div>
</div>


<div class="popupBg confirmPopup" *ngIf="currentModel.showConfirmModal == true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <button type="submit" class="close" (click)="closeConfirmBox()">&times;</button>
                <h4 class="modalHeader16px">Confirm Session</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-xs-12">
                        Are you sure to confirm this session?
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <a class="linkBtn" (click)="closeConfirmBox()">Cancel</a>
                <button type="submit" class="btn blueBtn" (click)="bookScheduling()">Confirm</button>
            </div>
        </div>
    </div>
</div>