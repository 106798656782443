import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiManagerComponent } from '../../providers/api-manager/api-manager';
import { GenericFunctionsComponent } from '../../providers/generic-functions/generic-functions';
import { map } from "rxjs/operators";
import { Constants } from '../../consts';
import { allEventsData } from '../../models/profile/accountModel.model';

@Injectable({
  providedIn: 'root'
})
export class SchedulerService {

  constructor(
    private apiManager: ApiManagerComponent,
    private genericFunc: GenericFunctionsComponent,
  ) { }

  scheduler(data: any): Observable<any> {
    return this.apiManager.postMethod(Constants.scheduler, false, data).pipe(map((apiResponse) => {
      return apiResponse
    }));
  }

  getScheduler(data: any): Observable<any> {
    return this.apiManager.postMethod(Constants.getScheduler, false, data).pipe(map((apiResponse) => {
      return apiResponse
    }));
  }

  insertSchedule(data: any): Observable<any> {
    return this.apiManager.postMethod(Constants.insertSchedule, false, data).pipe(map((apiResponse) => {
      return apiResponse
    }));
  }

  getScheduleByDate(data: any): Observable<any> {
    return this.apiManager.postMethod(Constants.getScheduleByDate, false, data).pipe(map((apiResponse) => {
      return apiResponse
    }));
  }

  getAllSchedules(data: any): Observable<any> {
    return this.apiManager.postMethod(Constants.getAllSchedules, false, data).pipe(map((apiResponse) => {
      return apiResponse
    }));
  }

  cancelSchedules(data: any): Observable<any> {
    return this.apiManager.postMethod(Constants.cancelSchedules, false, data).pipe(map((apiResponse) => {
      return apiResponse
    }));
  }

  userIntegration(data: any): Observable<any> {
    return this.apiManager.postMethod(Constants.userIntegration, false, data).pipe(map((apiResponse) => {
      return apiResponse
    }));
  }

  enableSchedulePlan(data: any): Observable<any> {
    return this.apiManager.postMethod(Constants.enableSchedulePlan, false, data).pipe(map((apiResponse) => {
      return apiResponse
    }));
  }
  videoCallSchedule(data: any): Observable<any> {
    return this.apiManager.postMethod(Constants.videoCallSchedule, false, data).pipe(map((apiResponse) => {
      return apiResponse
    }));
  }
  getBeforeCallData(data: any): Observable<any> {
    return this.apiManager.postMethod(Constants.getBeforeCallData, false, data).pipe(map((apiResponse) => {
      return apiResponse
    }));
  }
  insertVideoCallHistory(data: any): Observable<any> {
    return this.apiManager.postMethod(Constants.insertVideoCallHistory, false, data).pipe(map((apiResponse) => {
      return apiResponse
    }));
  }

  getAllAvailabily(data: any): Observable<allEventsData[]> {
    return this.apiManager.postMethod(Constants.getAllAvailabilites, false, data).pipe(map((apiResponse) => {
      return apiResponse
    }));
  }

  deleteAvailability(data: any): Observable<any> {
    return this.apiManager.postMethod(Constants.deleteAvailability, false, data).pipe(map((apiResponse) => {
      return apiResponse
    }));
  }

  getSingleAvailability(availabilityId: any): Observable<any> {
    return this.apiManager.getMethod(Constants.getSingleAvailability + availabilityId, false).pipe(map((apiResponse) => {
      return apiResponse
    }));
  }

  getVideoCallToken(data: any): Observable<any> {
    return this.apiManager.postMethod(Constants.getVideoCallToken, false, data).pipe(map((apiResponse) => {
      return apiResponse
    }));
  }
  // startRedecording(data: any): Observable<any> {
  //   return this.apiManager.postMethod(Constants.startRedecording, false, data).pipe(map((apiResponse) => {
  //     return apiResponse
  //   }));
  // }
  stopRedecording(data: any): Observable<any> {
    return this.apiManager.postMethod(Constants.stopRedecording, false, data).pipe(map((apiResponse) => {
      return apiResponse
    }));
  }
  downloadVideoCall(data:any): Observable<any> {
    return this.apiManager.postMethod(Constants.downloadVideoCall, false, data).pipe(map((apiResponse) => {
      return apiResponse
    }));
  }
 

}
