import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'UpdateSkills'
})

export class  UpdateSkills implements PipeTransform {
    transform(value: string): string {
        if(value != '' && value != undefined && value != null) {
            let allSkills = '';
            let i = 1;
            var splitted = value.split(","); 
            splitted.forEach(element => {
                if(i == 1) {
                    allSkills = element;
                } else {
                    allSkills = element + ', ' + allSkills
                }
                i++;
            });
            return allSkills;
        } else {
            return '';
        }
    }
}