<div class="modal-dialog">
  <div class="modal-content fullWidth">
    <!-- <div class="modal-header">
      <h4 class="modal-title modalHeader16px">Contact</h4>
    </div> -->
    <div class="modal-body fullWidth">
      <h3 class="defaultHeading"><b>Invoice Details</b></h3>
      <button type="submit" (click)="clearData()" class="close" data-dismiss="modal">&times;</button>

      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding marginTop2em">
        <div class="panel panel-default marginTop0Imp coachMainInfoSec">
          <div class="panel-body">
            <div class="col-xs-12 col-sm-8">
              <div class="offerProfileImageSec">
                <div
                  *ngIf="invoicedata.ProfileImageURL != 'NULL' && invoicedata.ProfileImageURL != null && invoicedata.ProfileImageURL != ''; else noImageTemplate">
                  <img src="{{invoicedata.ProfileImageURL}}" (error)="genericFunc.errorHandler($event)" class="hirePersonImage">
                </div>
                <ng-template #noImageTemplate>
                  <img src="../../../assets/images/female.png" class="hirePersonImage">
                </ng-template>
              </div>
              <div class="coachInfo contractModalInfo">
                <div class="col-lg-12 noPadding">
                  <h4 class="col-lg-12 noPadding">
                    <b>{{invoicedata.Name}}</b></h4>
                </div>
                <div class="col-lg-12 noPadding marginTop10px coachInvDesc coachInfoDesc"
                  *ngIf="genericFunc.isEmpty(invoicedata.description) == true">{{invoicedata.description}}</div>
                <div class="marginTop1em fullWidth" *ngIf="genericFunc.isEmpty(invoicedata.contractDesc) == true">
                  <div class="col-lg-12 noPadding marginTop10px coachInfoDesc showCursor"
                    (click)="invoiceOutputFunc(invoicedata.Id)">
                    <b>Contract: </b> {{invoicedata.contractDesc | TruncatePipe : [60, '...']}}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-4">
              <div class="coachInfo proposalStatusInfo">

                <div class="col-lg-12 noPadding ">
                  <h4 class="col-lg-12 noPadding nocursor orangeHeading" *ngIf="invoicedata.status != 'Paid'">
                    <b>{{invoicedata.status}}</b></h4>
                  <h4 class="col-lg-12 noPadding nocursor blueHeading" *ngIf="invoicedata.status == 'Paid'">
                    <b>{{invoicedata.status}}</b></h4>
                </div>

                <div class="col-lg-12 noPadding marginTop4px coachInfoDate"
                  *ngIf="(genericFunc.isEmpty(invoicedata.invoiceAmount) == true)">
                  Invoiced Amount: <b>${{invoicedata.invoiceAmount }}</b> For {{invoicedata.sessions}} Sessions
                </div>
                <div class="col-lg-12 noPadding marginTop4px coachInfoDate"
                  *ngIf="(promotionAmount != 0 && invoicedata.status == 'Paid')">
                  Koachee Promotion: <b>${{promotionAmount}}</b>
                </div>

                <div class="col-lg-12 noPadding marginTop4px coachInfoDate"
                  *ngIf="invoicedata.status != 'Due' && currentUserType == 'Coach' && filterData == 'Coach && invoicedata.koacheeFee != null'; else estimatedKoacheeFee">
                  Koachee Fee: <b>${{(invoicedata.koacheeFee).toFixed(2)}}</b>
                </div>
                <ng-template #estimatedKoacheeFee>
                  <div class="col-lg-12 noPadding marginTop4px coachInfoDate"
                    *ngIf="currentUserType == 'Coach' && filterData == 'Coach' && feeData != undefined">
                    Estimated Koachee Fee:<b>${{feeData.KoacheeFee}}</b>
                  </div>
                </ng-template>

                <div class="col-lg-12 noPadding marginTop4px coachInfoDate"
                  *ngIf="((promotionAmount != 0 && invoicedata.paidAmount != 0 && currentUserType == 'Coachee' && invoicedata.status == 'Paid') || (promotionAmount != 0 && invoicedata.paidAmount != 0 && currentUserType == 'Coach' && filterData == 'Coachee' && invoicedata.status == 'Paid'))">
                  Total Paid: <b>${{invoicedata.paidAmount}}</b>
                </div>

                <div class="col-lg-12 noPadding marginTop4px coachInfoDate"
                  *ngIf="(paidToCoach != 0 && currentUserType == 'Coach' && filterData == 'Coach' && invoicedata.status == 'Paid')">
                  Paid to Coach: <b>${{paidToCoach}}</b>
                </div>

                <!-- <div class="col-lg-12 noPadding marginTop4px coachInfoDate"
                  *ngIf="genericFunc.isEmpty(invoicedata.status) == true">
                  Status: {{invoicedata.status}}
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 noPaddingLeft noPaddingRightXs">
        <div class="panel panel-default marginTop0Imp coachMainInfoSec contractPaymentSec">
          <div class="panel-body">
            <h3 class="fullWidth defaultHeading"><b>Invoice Detail</b></h3>
            <div class="historyData">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 marginTop1em noPadding">
                <div class="marginTop4px col-xs-12  breakWordAll fullWidth">
                  <div class="col-xs-6 noPaddingLeft">
                    Generated
                  </div>
                  <div class="col-xs-6 noPaddingLeft"
                    *ngIf="genericFunc.isEmpty(invoicedata.invoiceDateCreated) == true">
                    {{invoicedata.invoiceDateCreated | date:'MMM d, y'}}
                  </div>
                </div>
                <div class="marginTop4px col-xs-12  fullWidth">
                  <div class="col-xs-6 noPaddingLeft">
                    Due
                  </div>
                  <div class="col-xs-6 noPaddingLeft" *ngIf="genericFunc.isEmpty(invoicedata.dateDue) == true">
                    {{invoicedata.dateDue | date:'MMM d, y'}}
                  </div>
                </div>
                <div class="marginTop4px col-xs-12 fullWidth">
                  <div class="col-xs-6 noPaddingLeft">
                    <span *ngIf="invoicedata.Type != 'escrow'">Paid</span>
                    <span *ngIf="invoicedata.Type == 'escrow'">Escrow</span>
                  </div>
                  <div class="col-xs-6 noPaddingLeft"
                    *ngIf="genericFunc.isEmpty(invoicedata.datePaid) == true;else emptyDatePaid">
                    {{invoicedata.datePaid | date:'MMM d, y'}}
                  </div>
                  <ng-template #emptyDatePaid>
                    <div class="col-xs-6 noPaddingLeft">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -
                    </div>
                  </ng-template>

                </div>
                <div class="marginTop4px col-xs-12 fullWidth" *ngIf="invoicedata.Type == 'escrow'">
                  <div class="col-xs-6 noPaddingLeft">
                    Release
                  </div>
                  <div class="col-xs-6 noPaddingLeft"
                    *ngIf="genericFunc.isEmpty(invoicedata.dateReleasedRequested) == true;else emptyReleaseDate">
                    {{invoicedata.dateReleasedRequested | date:'MMM d, y'}}
                  </div>
                  <ng-template #emptyReleaseDate>
                    <div class="col-xs-6 noPaddingLeft">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -
                    </div>
                  </ng-template>
                </div>
                <div class="marginTop4px col-xs-12  fullWidth" *ngIf="invoicedata.dateReleasedRequested">
                  <div class="col-xs-6 noPaddingLeft">
                    Transfer (Estimated)
                  </div>
                  <div class="col-xs-6 noPaddingLeft"
                    *ngIf="genericFunc.isEmpty(invoicedata.dateFundsAvailable) == true;else emptyDateFund">
                    {{estimatedDate | date:'MMM d, y'}}
                  </div>
                  <ng-template #emptyDateFund>
                    <div class="col-xs-6 noPaddingLeft">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6  noPaddingRight noPaddingLeftXs ">
        <div class="panel panel-default marginTop0Imp coachMainInfoSec contractHistorySec invoiceHistorySec">
          <div class="panel-body">
            <h3 class="fullWidth defaultHeading"><b> Transaction History</b></h3>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding">
              <div class="marginTop10px fullWidth">
                <div class="col-xs-4 ">
                  <b>Date</b>
                </div>
                <div class="col-xs-8 noPaddingRight ">
                  <b>Message</b>
                </div>
              </div>
            </div>
            <div class="transactionHistoryData" *ngIf="transactionData.length > 0">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding">
                <div class="marginTop10px fullWidth" *ngFor="let transaction of transactionData">
                  <div class="col-xs-4">
                    {{unixTime(transaction.Created) | date:'MMM d, y'}}
                  </div>
                  <div class="col-xs-8 noPaddingRight">
                    <span>
                      {{transaction.Message}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="modal-footer fullWidth optionsSetting">
      <a class="btn blueBtn equalSizeBtn orangeBtn noMargin" (click)="clearData()" data-dismiss="modal">Close</a>
    </div>
  </div>