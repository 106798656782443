import { Constants } from "../../consts";

export class SchedulerModal {
  modal: boolean = false
  day01: any = '';
  timestart1: any = '';
  endtime01: any = '';
  day02: any = '';
  timestart02: any = '';
  endtime02: any = '';
  selectedTimeZone: any = '';
  logoPath = Constants.favicon
  timeZoneList: any = [];
  timebtn: boolean = false
  timeConfirmBtn900: boolean = false
  confirmMainBtn900: boolean = true
  selectDateTimeModal: boolean = false
  currentIndex: number = -1;
  timeZoneUserTime: any = []
  timings: any = []
  intervalTimelist: any = []
  loadCalendar: boolean = false
  getSchedulerAPI: any;
  
  datesToHighlight: any = [];

  fullScheduleData: any;
  selectedElement: any;
  durationHours: string;
  durationMinutes: string;
  showConfirmModal: boolean = false
  selectedTime: string;
  selectedIndex: number;
  datestore: any = [];
  coachTimeZone: any;
  coachScheduleDate: any;
  coacheeScheduleDate: any;
}