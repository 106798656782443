import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { GenericFunctionsComponent } from '../../../providers/generic-functions/generic-functions';
import { Router } from '@angular/router';
import { ShareDataProviderComponent } from '../../../providers/share-data-provider/share-data-provider.component';

@Component({
  selector: 'app-home-coach-card',
  templateUrl: './home-coach-card.component.html',
  styleUrls: ['./home-coach-card.component.css'],
  providers: []
})
export class HomeCoachCardComponent implements OnInit {
  @Input() coachData: any;
  @Input() loggedInUserData: any;
  @Input() skill: any;
  @Input() expertiseName: any;
  public currentUserID: any;
  public isLoggedIn: any = false;

  constructor(
    private genericFunc: GenericFunctionsComponent,
    private _router: Router,
    public shareUserData: ShareDataProviderComponent,
  ) {
  }

  ngOnInit() {
    this.currentUserID = this.genericFunc.getLoggedInUserID();
    this.isLoggedIn = this.genericFunc.isUserLoggedIn();
  }

  getYear(val: any) {
    return (new Date(val)).getFullYear();
  }
}
