import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '../../consts';
import { ShareDataProviderComponent } from '../../providers/share-data-provider/share-data-provider.component';
import { GenericFunctionsComponent } from '../../providers/generic-functions/generic-functions';

@Component({
  selector: 'app-send-message-modal',
  templateUrl: './send-message-modal.component.html',
  styleUrls: ['./send-message-modal.component.css']
})
export class SendMessageModalComponent implements OnInit {

  constructor(
    private _router: Router,
    public genericFunc: GenericFunctionsComponent
  ) {}

  ngOnInit() {
  }

  loginPageLink() {
    this._router.navigateByUrl(Constants.loginPath);
  }
  SignupPageLink() {
    this._router.navigateByUrl(Constants.signUpPath);
  }
}
