import { Component, OnInit, Input, ChangeDetectorRef, EventEmitter, Output, ViewRef } from '@angular/core';
import { ToasterService } from '../../../services/toaster/toaster.service';
import { ApiManagerComponent } from '../../../providers/api-manager/api-manager';
import { Constants } from '../../../consts';
import { GenericFunctionsComponent } from '../../../providers/generic-functions/generic-functions';
import { LocalStorageComponent } from '../../../providers/local-storage/local-storage';
import { ShareDataProviderComponent } from '../../../providers/share-data-provider/share-data-provider.component';

@Component({
  selector: 'app-hire-coach',
  templateUrl: './hire-coach.component.html',
  styleUrls: ['./hire-coach.component.css']
})
export class HireCoachComponent {
  @Input() profileData: any;
  @Input() singlePackage: any;
  @Input() multiplePackage: any;
  @Input() checkCurrentUserProfile: any;
  @Input() showAlreadyHire: any;
  @Input() rehire: any = false;
  @Input() proposalData: any;
  @Input() expertise: any;
  @Input() freePackage: any
  @Input() isShowFreeDemoRate: any
  @Output() notify: EventEmitter<string> = new EventEmitter<string>();

  onClick(val: any = '') {
    this.notify.emit(val)
  }

  public categoryDesc: any = '';
  public agree: boolean = false;
  public loading = false;
  public hireCoachRate: number = 0;
  public selectedSession: number = 0;
  public offerSenderUsername: any = '';
  public howItWorksLink: any;
  public hireCoachSkills: any = [];
  public showSkills: any = [];
  public skills: any
  public rehireSkills: any
  public session: any
  public packageType: any
  public rehireSkillsSelected: any = [];
  public sendOfferAPI: any
  public packageID: any;


  constructor(
    public toast: ToasterService,
    public apiManager: ApiManagerComponent,
    public genericFunc: GenericFunctionsComponent,
    public shareUserData: ShareDataProviderComponent,
    public reload: ChangeDetectorRef,
    public localStore: LocalStorageComponent,
  ) {
    this.offerSenderUsername = this.genericFunc.getLoggedInUserName();
  }

  ngOnChanges() {
    this.onload();
  }

  onload() {
    this.skills = this.genericFunc.getUserSkill(this.profileData.Expertise).split(',')

    if (this.rehire == true) {
      this.hireCoachRate = 0
      this.session = this.proposalData.SessionsIncluded
      // this.hireCoach
      if (this.genericFunc.isEmpty(this.proposalData.CategoryIDs) == true) {
        this.rehireSkills = this.genericFunc.getUserSkill(this.proposalData.CategoryIDs).split(',')
      }

      this.rehireSkillsSelected = [];
      this.skills.forEach((mainSkill: any) => {
        let obj = {
          'name': mainSkill,
          'selected': 0
        }
        this.rehireSkillsSelected.push(obj)
      })
      let counter = 0
      this.skills.forEach((mainSkill: any) => {
        this.rehireSkills.forEach((rehireSkill: any) => {
          if (this.genericFunc.removeSpaces(mainSkill) == this.genericFunc.removeSpaces(rehireSkill)) {
            let obj = {
              'name': mainSkill,
              'selected': 1
            }
            this.rehireSkillsSelected[counter] = obj
            this.hireCoachSkill(mainSkill)
          }
        });
        counter = counter + 1
      });

      if (this.proposalData) {
        this.categoryDesc = this.proposalData.Description;
      }
    }

    this.howItWorksLink = this.genericFunc.getAllLinks('hire-popup', 'how it works')
    this.reload.detectChanges();
  }

  hireCoachSkill(item: any) {
    if (this.hireCoachSkills.indexOf(item) !== -1) {
      let itemIndex = this.hireCoachSkills.indexOf(item)
      this.hireCoachSkills.splice(itemIndex, 1)
    } else {
      this.hireCoachSkills.push(item);
    }
  }

  hireCoach() {
    if (this.hireCoachSkills.length == 0) {
      this.toast.error(Constants.categoryError, Constants.errorTitle);
    }
    else if (this.profileData.FreeConsultStatus == false && this.singlePackage.length == 0 && this.multiplePackage.length == 0) {
      this.toast.error(Constants.noRatesDefined, Constants.errorTitle);
    }
    else if (((this.hireCoachRate == 0) || (this.hireCoachRate == null) || (this.hireCoachRate == undefined)) && (this.packageType != 'free')) {
      this.toast.error(Constants.noPackageId, Constants.errorTitle);
    }
    else if (this.agree == false) {
      this.toast.error(Constants.agreeError, Constants.errorTitle);
    } else {
      let skillID: any = [];
      let allSkills = this.localStore.getSkills()

      this.hireCoachSkills.forEach((currentSkill: any) => {
        allSkills.forEach((allSkill: any) => {
          if (allSkill.itemName.replace(/\s/g, '') == currentSkill.replace(/\s/g, '')) {
            skillID.push(allSkill.id)
          }
        });
      });
      if (this.packageType == 'free') {
        this.hireCoachRate = 0
        this.selectedSession = 1
      }
      this.loading = true
      let data = {
        "description": this.categoryDesc,
        "offerReceiverID": this.profileData.ID,
        "categoryIDs": skillID,
        "rate": this.hireCoachRate,
        "senderUserName": this.genericFunc.getLoggedInFirstName(),
        "session": this.selectedSession,
        "accountLink": this.profileData.accountLink,
        "coachEmail": this.profileData.Email,
        "coachName": this.profileData.Name,
        "packageId": this.packageID
      }
      this.sendOfferAPI = this.apiManager.postMethod(Constants.sendOffer, true, data).subscribe((getHireCoachData) => {
        this.loading = false;
        if (getHireCoachData.response.data == "Stripe account is wrong") {
          this.toast.error("Your coach stripe account is not properly setup. Please contact your coach.", Constants.errorTitle);
        } else {
          if (getHireCoachData.response.data) {
            this.toast.success(getHireCoachData.response.data);
            this.genericFunc.hideHireModal(true);
            this.shareUserData.alreadyHiredPopup = false;
            this.shareUserData.alreadyHiredDemoPopup = false;
            this.resetValues();
            document.body.style.overflow = 'auto';
            this.onClick('Hire Request Send');
            this.reload.detectChanges();
          } else {
            this.toast.error(getHireCoachData.response.err, Constants.errorTitle);
          }
        }

      }, (error) => {
        this.toast.error(error, Constants.errorTitle);
      });
    }
  }

  setSessionValue(packageRate: any, packageSession: any, selectedPackage: any, packageID: any) {
    this.packageID = packageID;
    this.hireCoachRate = packageRate;
    this.selectedSession = packageSession;
    this.packageType = selectedPackage
  }

  resetValues() {
    this.categoryDesc = '';
    this.agree = false;
    this.hireCoachRate = 0
    this.selectedSession = 0;
    if (this.rehire == false) {
      this.hireCoachSkills = []
    }
  }

  cancelSec() {
    this.resetValues();
    this.onClick()
    document.body.style.overflow = 'auto';
  }

  ngOnDestroy() {
    this.loading = false;
    if (this.sendOfferAPI) {
      this.sendOfferAPI.unsubscribe();
    }
  }
}
