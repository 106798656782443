<div class="row">
  <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 singleUserImage">
    <div
      *ngIf="coachData.ProfileImageURL != 'NULL' && coachData.ProfileImageURL != null && coachData.ProfileImageURL != ''; else noImageTemplate">
      <img src="{{coachData.ProfileImageURL}}" (error)="genericFunc.errorHandler($event)" [alt]="coachData.Name">
    </div>
    <ng-template #noImageTemplate>
        <img src="../../../../assets/images/female.png" [alt]="coachData.Name">
    </ng-template>
    <!-- <span class="fa fa-check"></span> -->
  </div>
  <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 noPadding cardInfo">
    <!-- <span *ngIf="coachData.ImpressionView == 'Sponsored'" > -->
    <span class="sponsoredUser" *ngIf="coachData.sponsoredID != null && coachData.sponsoredID != undefined && expertiseName == 'Sponsored'">Sponsored</span>
    <span class="sponsoredUser" *ngIf="coachData.sponsoredID != null && coachData.sponsoredID != undefined && expertiseName != 'Sponsored' && coachData.PlanId != 1">Sponsored</span>
  <!-- </span> -->
    <h3 class="breakWordAll" [ngClass]="[coachData.sponsoredID != null && coachData.sponsoredID != undefined ? 'marginTop0Imp' : '']">{{coachData.Name}}</h3>

    <div class="row singleUserRatingArea">
      <div class="col-xs-12">
        Coach since {{coachData.Since}}
      </div>
      <div class="col-xs-12" *ngIf="skill" >
        {{skill}} Coach
      </div>
      <div class="col-xs-12">
        <span *ngIf="coachData.State">{{coachData.State}},</span> <span *ngIf="coachData.Country">{{coachData.Country}}</span>
      </div>
      <!-- <div class="col-xs-6" *ngIf="coachData.PackageRate">
        Rates: <b>${{coachData.PackageRate}}</b>
      </div> -->
      <!--   <div class="col-xs-6">
         NPS: <b>{{coachData.NpsScore}}</b> 
      </div>-->
    </div>
  </div>
  <div class="col-xs-12 pull-left">
    <p class="singleUserDesc" *ngIf="coachData.Headline != '' && coachData.Headline != null;">
      <span *ngIf="coachData.Headline.length < 1; else lessDesc">
        {{coachData.Headline}}...
      </span>
      <ng-template #lessDesc>
        <span>
          {{coachData.Headline | TruncatePipe : [60, '...']}}
        </span>
      </ng-template>
      <!-- <button type="submit" (click)="genericFunc.setUserWithoutLogin(coachData.Handle,'profile')" class="linkBtn linkBtnSec fullProfileSec" data-toggle="modal" data-target="#myModal" *ngIf="!isLoggedIn">View Full Profile</button> -->
      <span class="fullProfileSec" (click)="genericFunc.redirect(coachData.Type, coachData.Handle, 'viewFullProfile')">View Full Profile</span>
    </p>
  </div>
</div>
<div class="row singleCardBottomSec">
  <div class="col-xs-6 singleMessageSec text-center">
    <span class="msgSpan" (click)="genericFunc.chatMessageBox(coachData)"
      *ngIf="currentUserID != coachData.ID && isLoggedIn">CONTACT COACH</span>
    <span class="msgSpan" data-toggle="modal" data-target="#myModal"
      (click)="genericFunc.setUserWithoutLogin(coachData, 'Message')"
      *ngIf="currentUserID != coachData.ID && !isLoggedIn">CONTACT COACH</span>
  </div>
  <div class="col-xs-6 singleUserRatingSec">
    <div class="ratingArea"
      *ngIf="coachData.Ratings != undefined && coachData.Ratings != null && coachData.Ratings != 0; else noRatingSec">
      <div class="ratings">
        <div class="empty-stars"></div>
        <div class="full-stars" [style.width.%]=coachData.Ratings></div>
      </div>
    </div>
    <ng-template #noRatingSec>
      <div class="ratings">
        <div class="empty-stars"></div>
      </div>
    </ng-template>
  </div>
</div>