import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ReplaceCoachName'
})

export class ReplaceCoachNamePipe implements PipeTransform {
    transform(value: any, args?: any): any {

        var str = value;
        var newstr = str.replace('[Coach Name]', args);
        
        return newstr;
    }
}