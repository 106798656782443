import { Component, OnInit, ChangeDetectorRef, PLATFORM_ID, Inject } from '@angular/core';
import { Constants } from '../../../consts';
import { Router } from '@angular/router';
// Created to pass data from one component to other
import { ShareDataProviderComponent } from '../../../providers/share-data-provider/share-data-provider.component';
import { SocialAuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { LocalStorageComponent } from '../../../providers/local-storage/local-storage';
import { ToasterService } from '../../../services/toaster/toaster.service';
import * as EmailValidator from 'email-validator';
import { GenericFunctionsComponent } from '../../../providers/generic-functions/generic-functions';
import { loginModel } from '../../../models/auth/loginModel.model';
import { LogInService } from '../../../services/auth/log-in/log-in.service';
import { SocketServiceService } from '../../../services/socket/socket-service.service';
import { Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { getSubDomain } from '../../../state/subDomain/subDomain.selectors';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [loginModel, LogInService]
})
export class LoginComponent implements OnInit {
  public loading = false;
  public activeSubDomain = false;
  public currentProfile: any;


  constructor(
    private authService: SocialAuthService,
    private _router: Router,
    private localStore: LocalStorageComponent,
    private shareSignUpData: ShareDataProviderComponent,
    public reload: ChangeDetectorRef,
    private toast: ToasterService,
    private genericFunc: GenericFunctionsComponent,
    public currentModel: loginModel,
    private titleService: Title,
    private store: Store<AppState>,
    public logInService: LogInService,
    public socketInstance: SocketServiceService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    if(isPlatformBrowser(this.platformId)){
      this.store.select(getSubDomain).subscribe((getSubDomain:any)=>{
        this.activeSubDomain = getSubDomain.enableSubDomain
      });
      this.loading = false;
      if (this.genericFunc.isUserLogOut() == false) {
        this.socketAfterApiCall()
        this._router.navigateByUrl(Constants.homePath);
      }
      this.genericFunc.amplitudeFunc(Constants.loginEvent);
      Constants.currentPage = 'login';
      this.titleService.setTitle(Constants.loginTitle);
    }
  }

  socketAfterApiCall() {
    this.configureSocket();
    this.socketBroadcastSettings();
  }
  
  ngOnInit() {
    // if(isPlatformBrowser(this.platformId)){
    this.authService.authState.subscribe((user) => {
      if (this.currentModel.isNeedToCheckLoginGoogle == false && this.currentModel.isNeedToCheckLoginFacebook == false) {
        return
      }
      this.shareSignUpData.signUpStorage = JSON.stringify(user);
      if (this.currentModel.loggedIn) {
        this.currentModel.loggedIn = (user != null);
      }
      else {
        this.currentModel.user = user;
        if (this.currentModel.user != null) {
          if (this.loading == false) {

            this.loginApi(user);
            //this.authService.signOut()  
            this.currentModel.isNeedToCheckLoginGoogle = false
            this.currentModel.isNeedToCheckLoginFacebook = false
            this.currentModel.user = null
          }
        }
      }
    });
    if (!(this.reload as any).destroyed) {
      this.reload.detectChanges();
    }
  // }
  }

  configureSocket() {
    this.socketInstance.connectSocket();
  }

  socketBroadcastSettings() {
    this.socketInstance.broadCastSocket.subscribe(
      value => {
        if (value) {
        }
      }
    );
  }

  isPasswordShow() {
    this.currentModel.passwordShow = !this.currentModel.passwordShow;
  }

  loginApi(user: any) {
    if (Constants.currentPage == 'login') {
      let data = {
        "emailid": user.email,
        "provider": this.currentModel.currentLoginProvider
      }
      console.log("loginWithSocialAPI data ", data);
      this.currentModel.currentLoginProvider = '';
      this.loading = true;
      this.currentModel.loginWithSocialAPI = this.logInService.loginWithSocial(data).subscribe((loginResponse) => {
        console.log("loginWithSocialAPI loginResponse ", loginResponse);
        this.localStore.setKoacheeSuitePlan(loginResponse.response.koacheeSuitePlanID)
        this.loading = false;
        this.genericFunc.postLoginToRequest(loginResponse, this.toast, this.currentModel.dummyObject, this.currentModel.array)
        this.socketAfterApiCall()
      }, (error) => {
        this.loading = false;
        this.toast.error(error, Constants.errorTitle);
      });
    }
  }
  forgotPasswordRedirect() {
    this._router.navigateByUrl(Constants.forgotPasswordPath);
  }

  traditionalLogin(event: any, btnClick: boolean) {
    if ((event.keyCode == 13) || (btnClick == true)) {
      this.currentModel.errorMessage = false;
      this.currentModel.emailValidation = EmailValidator.validate(this.currentModel.loginEmail);

      if (this.currentModel.loginEmail == "" || this.currentModel.loginEmail == null) {
        this.toast.error(Constants.emailError, Constants.errorTitle);
        this.currentModel.errorMessage = true;
      }
      else if (this.currentModel.emailValidation == false && this.currentModel.loginEmail != null) {
        this.toast.error(Constants.emailValidationError, Constants.errorTitle);
        this.currentModel.errorMessage = true;
      }

      else if (this.currentModel.loginPassword == "" || this.currentModel.loginPassword == null) {
        this.toast.error(Constants.passwordError, Constants.errorTitle);
        this.currentModel.errorMessage = true;
      }

      else if (this.currentModel.errorMessage == false) {
        this.loading = true
        let data = {
          "emailid": this.currentModel.loginEmail,
          "password": this.currentModel.loginPassword
        }
        console.log("loginWithtradAPI data ", data);

        this.currentModel.getLoginDataAPI = this.logInService.getLoginData(data).subscribe((loginResponse) => {
          console.log("loginWithtradAPI loginResponse ", loginResponse);

          this.loading = false
          if (loginResponse.status == 1) {
            this.localStore.setKoacheeSuitePlan(loginResponse.response.koacheeSuitePlanID)

            // Get Skills from server at login time
            if (loginResponse.response.validSkills) {
              for (let i = 0; i < loginResponse.response.validSkills.length; i++) {
                this.currentModel.dummyObject = {
                  'id': i,
                  'itemName': loginResponse.response.validSkills[i]
                }
                this.currentModel.array.push(this.currentModel.dummyObject);
              }
              this.localStore.setSkills(this.currentModel.array);
            }
            this.socketAfterApiCall()
            // if (loginResponse.response.data) {
            //  Check if login status is 4 (Pending verification) then it will redirect to verify page
            //   if (loginResponse.response.data.StatusID == 4) {
            //     this.toast.success(Constants.emailVerificationOldPending)
            //     Constants.verificationEmailId = this.currentModel.loginEmail
            //     Constants.verificationName = loginResponse.response.data.Name
            //     this._router.navigateByUrl(Constants.homePath);
            //     return
            //   }
            if (loginResponse.response.data.StatusID == -1) {
              this.toast.error(Constants.deletedUser)
              return;
            }
            if (this.localStore.setProfile(JSON.stringify(loginResponse.response.data))) {
              this.localStore.setToken(loginResponse.response.token);
            }

            if (loginResponse.response.data.StatusID == 5 ) {
              if (loginResponse.response.data.Type == "") {
                this._router.navigateByUrl(Constants.completeSignUpAccountPath);
              } 
              else {
                this._router.navigateByUrl(Constants.homePath);
              }
            }
            else if (loginResponse.response.StatusCode == 1 || loginResponse.response.StatusCode == 4 ) {
              if (loginResponse.response.data.Type == "") {
                this._router.navigateByUrl(Constants.completeSignUpAccountPath);
              }
              else if (this.shareSignUpData.withoutLoginUserData == '' || this.shareSignUpData.withoutLoginUserData == undefined || this.shareSignUpData.withoutLoginUserData == null) {
                this.localStore.clearPublishProfile();
                this._router.navigateByUrl(Constants.homePath);
              } else {
                this.genericFunc.userWithoutLoginRouting('login');
              }
            }
            // } 
            else {
              this.toast.error(loginResponse.response.err, Constants.errorTitle);
            }
          } else if (loginResponse.status == 0) {
            this.toast.error(loginResponse.response.err, Constants.errorTitle);
          } else {
          }
        }, (error) => {
          this.toast.error(error, Constants.errorTitle);
        });
      }
    }
  }

  signInWithGoogle(): void {
    this.currentModel.isNeedToCheckLoginGoogle = true
    this.currentModel.currentLoginProvider = 'GOOGLE'
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFacebook(): void {
    this.currentModel.isNeedToCheckLoginFacebook = true
    this.currentModel.currentLoginProvider = 'FACEBOOK'
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  ngOnDestroy() {
    this.loading = false;
    if (this.currentModel.loginWithSocialAPI) {
      this.currentModel.loginWithSocialAPI.unsubscribe();
    }
    if (this.currentModel.getLoginDataAPI) {
      this.currentModel.getLoginDataAPI.unsubscribe();
    }
  }

}