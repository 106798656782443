import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CusHeader1Component } from '../../components/cus-header1/cus-header1.component';
import { NotificationComponent } from '../../components/notification/notification.component';
import { NgxLoadingModule } from 'ngx-loading';
import { FirstLettersofName } from '../../pipe/name-first-letter/name-first-letter.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SafeHtmlPipe } from '../../pipe/safeHtml/safe-html.pipe';
import { SendMessageModalComponent } from '../../components/send-message-modal/send-message-modal.component';
import { UpdateSkills } from '../../pipe/update-skills/update-skills.pipe';
import { HomeService } from '../../services/search/home/home.service';
import { CusFooterComponent } from '../../components/cus-footer/cus-footer.component';
import { ToasterService } from '../../services/toaster/toaster.service';
import { ApiManagerComponent } from '../../providers/api-manager/api-manager';
// import { HttpClientModule } from '@angular/common/http';
import { GenericFunctionsComponent } from '../../providers/generic-functions/generic-functions';
import { LocalStorageComponent } from '../../providers/local-storage/local-storage';
import { SocketServiceService } from '../../services/socket/socket-service.service';
import { ContractDetailModalComponent } from '../../components/contract-detail-modal/contract-detail-modal.component';
// import { ExpertiseLandingComponent } from '../../pages/expertiseLanding/expertiseLanding.component';
import { ShowCurrentCardComponent } from '../../components/search/show-current-card/show-current-card.component';
import { HomeCoachCardComponent } from '../../components/search/home-coach-card/home-coach-card.component';
import { TruncatePipe } from '../../pipe/truncate-words/truncate-words.pipe';
import { HeaderEmailVerificationComponent } from '../../components/header-email-verification/header-email-verification.component';
import { ReplaceCoachNamePipe } from '../../pipe/replace-coach-name/replace-coach-name.pipe';
import { InvoiceDetailModalComponent } from '../../components/invoice-detail-modal/invoice-detail-modal.component';
import { HireCoachComponent } from '../../components/profile/hire-coach/hire-coach.component';
import { FormsModule } from '@angular/forms';
import { FirstLetter } from '../../pipe/first-letter/first-letter.pipe';
import { LinkifyPipe } from '../../pipe/make-clickable-links/make-clickable-links.pipe';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { UserLinkPipe } from '../../pipe/userLink/user-link.pipe';
import { SchedulerComponent } from '../../pages/scheduler/scheduler.component';
import { MatModule } from '../mat-module/mat.module';
import { CameraPreviewComponent } from '../../components/meeting/camera-preview/camera-preview.component';
import { RoundTranparentIconButtonComponent } from '../../components/meeting/round-tranparent-icon-button/round-tranparent-icon-button.component';
import { InputOutputSettingsComponent } from '../../components/meeting/input-output-settings/input-output-settings.component';
import { SoundMeterComponent } from '../../components/meeting/sound-meter/sound-meter.component';
import { SoundVisualizerComponent } from '../../components/meeting/sound-visualizer/sound-visualizer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NotepadComponent } from '../../components/notepad/notepad.component';
import { LoaderioComponent } from '../../pages/loaderio/loaderio.component';
import { GridsterModule } from 'angular-gridster2';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    // HttpClientModule,
    RouterModule,
    NgxLoadingModule,
    FormsModule,
    SwiperModule,
    MatModule,
    FontAwesomeModule,
    GridsterModule
    // HttpClientModule
  ],
  declarations: [
    CameraPreviewComponent,
    RoundTranparentIconButtonComponent,
    InputOutputSettingsComponent,
    SoundMeterComponent,
    SoundVisualizerComponent,
    ShowCurrentCardComponent,
    HomeCoachCardComponent,
    CusHeader1Component,
    CusFooterComponent,
    NotificationComponent,
    HeaderEmailVerificationComponent,
    FirstLettersofName,
    ContractDetailModalComponent,
    SendMessageModalComponent,
    TruncatePipe,
    HireCoachComponent,
    ReplaceCoachNamePipe,
    SafeHtmlPipe,
    InvoiceDetailModalComponent,
    SchedulerComponent,
    UpdateSkills,
    FirstLetter,
    LinkifyPipe,
    UserLinkPipe,
    NotepadComponent,
    LoaderioComponent
  ],
  providers:[
    HomeService,
    CusHeader1Component,
    GenericFunctionsComponent,
    ApiManagerComponent,
    LocalStorageComponent,
    SocketServiceService,
    ToasterService,
    LinkifyPipe,
    UserLinkPipe
  ],
  exports: [
    CameraPreviewComponent,
    RoundTranparentIconButtonComponent,
    InputOutputSettingsComponent,
    SoundMeterComponent,
    SoundVisualizerComponent,
    ShowCurrentCardComponent,
    HomeCoachCardComponent,
    HireCoachComponent,
    CusHeader1Component,
    CusFooterComponent,
    NgxLoadingModule,
    ContractDetailModalComponent,
    NotificationComponent,
    HeaderEmailVerificationComponent,
    SendMessageModalComponent,
    FirstLettersofName,
    TruncatePipe,
    ReplaceCoachNamePipe,
    SafeHtmlPipe,
    InvoiceDetailModalComponent,
    SchedulerComponent,
    UpdateSkills,
    FirstLetter,
    LinkifyPipe,
    SwiperModule,
    UserLinkPipe,
    MatModule,
    NotepadComponent,
    LoaderioComponent
  ]
})
export class SharedModule { }
