import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ShareDataProviderComponent } from '../../providers/share-data-provider/share-data-provider.component';
import { GenericFunctionsComponent } from '../../providers/generic-functions/generic-functions';

@Component({
  selector: 'app-contract-detail-modal',
  templateUrl: './contract-detail-modal.component.html',
  styleUrls: ['./contract-detail-modal.component.css']
})
export class ContractDetailModalComponent implements OnInit {
  @Input() contractDetails: any;
  @Input() dropdown: any;
  @Output() contractOutput: EventEmitter<any> = new EventEmitter<any>();
  public offer: any;
  public currentUserId: any;
  public currentUserName: any;
  public currentUserType: any;

  constructor(
    public genericFunc: GenericFunctionsComponent,
    public shareUserData: ShareDataProviderComponent
  ) { }

  ngOnInit() {
    this.offer = this.shareUserData.contractUserDetails;
    this.currentUserId = this.genericFunc.getLoggedInUserID();
    this.currentUserType = this.genericFunc.getLoggedInUserType();
    this.currentUserName = this.genericFunc.getLoggedInUserName();
  }

  clearData(){
    this.shareUserData.showContractDetailModal = false;
    this.offer = '';
    this.shareUserData.contractUserDetails = '';
    this.shareUserData.contractPaymentHistory = '';
  }

  contractOutputFunc(val: any) {
    let data = {
      "invoiceID": val,
      "opponentID": this.offer.ID
    }
    
    this.contractOutput.emit(data)
    this.clearData();
  }
}
