import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Constants } from '../../consts';
import { ApiManagerComponent } from '../../providers/api-manager/api-manager';
import { SubDomainService } from '../../services/subDomain/sub-domain.service';
import { subDomainUpdate } from '../../state/subDomain/subDomain.actions';
import { getSubDomain } from '../../state/subDomain/subDomain.selectors';
import { AppState } from '../../store/app.state';

@Injectable({
  providedIn: 'root'
})
export class SubDomainGuard implements CanActivate {
  public activeSubDomain: boolean = false;
  public checkApiCall: boolean = false;

  constructor(
    private store: Store<AppState>,
    public _router: Router,
    private subDomainSer: SubDomainService
    // public apiManager: ApiManagerComponent
  ) {
    this.store.select(getSubDomain).subscribe((getSubDomain: any) => {
      this.activeSubDomain = getSubDomain.enableSubDomain
      this.checkApiCall = getSubDomain.apiCall;
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if(this.subDomainSer.checkSubDomain()) {
      this._router.navigateByUrl(Constants.subDomainPath);
      return false
    } else {
      return true
    }
  }
}
