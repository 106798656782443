import { Injectable } from '@angular/core';
// import { Http, Headers, Response } from '@angular/http';
import { Constants } from '../../consts';
import { LocalStorageComponent } from '../../providers/local-storage/local-storage'
import { ToasterService } from '../../services/toaster/toaster.service';
import { CusHeader1Component } from '../../components/cus-header1/cus-header1.component';
// import * as $ from 'jquery';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class ApiManagerComponent {
  public headerToken: any;
  public isLoggedIn: boolean = true;
  homeworld: Observable<{}>;

  public constructor(
    private toast: ToasterService,
    private http: HttpClient,
    private headerSec: CusHeader1Component,
    private localStore: LocalStorageComponent,
  ) {
  }

  getMethod(url: string, isHeaderRequired: boolean = true, param: Object = null) {
    return this.homeworld = this.http.get(url, { headers: this.getHeaderJson() })
      .pipe(
        map((response) => {
          return this.isTokenExpired(response);
        }),
        catchError(error => {
          // return this.handleError(error)
          return ""
        })
      )
  }

  // Following is the get method without token
  getMethodWithoutToken(url: string, isHeaderRequired: boolean = true, param: Object = null) {
    return this.homeworld = this.http.get(url, { headers: this.getHeaderJsonStaticToken() })
      .pipe(
        map((response) => {
          // return response.json();
          return this.isTokenExpired(response);
        }),
        catchError(error => {
          if(url != Constants.testApi) {
            // return this.handleError(error)
            return ""
          }
          return
        })
      );
  }

  // Following is the post method without token
  postMethodWithoutToken(url: string, isHeaderRequired: boolean = true, param: Object) {
    return this.homeworld = this.http.post(url, param, { headers: this.getHeaderJsonStaticToken() })
      .pipe(
        map((response) => {
          // login successful if user.status = success in the response
          return this.isTokenExpired(response);
        }),
        catchError(error => {
          // return this.handleError(error)
          return ""
        })
      );
  }

  postMethod(url: string, isHeaderRequired: boolean = true, param: Object) {
    return this.homeworld = this.http.post(url, param, { headers: this.getHeaderJson() })
      .pipe(
        map((response) => {
          // login successful if user.status = success in the response
          return this.isTokenExpired(response);
        }),
        catchError(error => {
          // return this.handleError(error)
          return ""
        })
      );
  }

  // function added for file upload
  postMethodFileUpload(url: string, param: Object) {
    return this.http.post(url, param, { headers: this.getFileHeaderJson() })
      .pipe(
        map((response) => {
          // login successful if user.status = success in the response
          return this.isTokenExpired(response);
        }),
      );
  }

  getHeaderJson() {
    let tokenSec;
    if (this.localStore.getToken() == null || this.localStore.getToken() == undefined) {
      tokenSec = ''
    } else {
      tokenSec = this.localStore.getToken()
    }

    var headerDict = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'token': tokenSec,
      'subdomainurl': `${window.location.protocol}//${window.location.host}/`
    });
    // headerDict.set('Content-Type', 'application/json');
    // headerDict.append('Access-Control-Allow-Origin', '*');
    // headerDict.append('token', this.localStore.getToken());
    return headerDict;
  }

  getHeaderJsonStaticToken() {
    // var headerDict = new HttpHeaders();
    var headerDict = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'token': Constants.token,
      'subdomainurl': `${window.location.protocol}//${window.location.host}/`
    });
    // headerDict.set('Content-Type', 'application/json');
    // headerDict.append('Access-Control-Allow-Origin', '*');
    // headerDict.append('token', Constants.token);
    return headerDict;
  }

  // header for file upload
  getFileHeaderJson() {
    let tokenSec;
    if (this.localStore.getToken() == null || this.localStore.getToken() == undefined) {
      tokenSec = ''
    } else {
      tokenSec = this.localStore.getToken()
    }
    var headerDict = new HttpHeaders({
      'token': tokenSec
    });
    // var headerDict = new HttpHeaders();
    // headerDict.append('token', this.localStore.getToken());
    return headerDict;
  }
  //Timeout
  isTokenExpired(response: any) {
    var responseData = response;
    if (responseData.response) {
      if (responseData.response.err) {
        var wordToFind = 'Timeout';
        var mainString = responseData.response.err;
        if (mainString.search(wordToFind) != -1) {
          this.toast.error("Sorry, our server did not respond. Please try again.", Constants.errorTitle);
          return "";
        }
      }

      if (responseData.response.message) {
        var wordToFind = 'expired';
        var mainString = responseData.response.message;
        if ((mainString.search(wordToFind) != -1) && (this.isLoggedIn)) {
          this.toast.error(responseData.response.message, Constants.errorTitle);
          this.isLoggedIn = false;
          this.headerSec.logOut();
          this.localStore.clearAllValues();
          return "";
        }
      }
    }
    return responseData;
  }
}
