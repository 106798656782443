import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GenericFunctionsComponent } from '../../providers/generic-functions/generic-functions';
import { ShareDataProviderComponent } from '../../providers/share-data-provider/share-data-provider.component';
import { koacheeOfferListModel } from '../../models/koacheeOfferList.model';
import { Constants } from '../../consts';
import { HomeComponent } from '../../pages/search/home/home.component';


@Component({
  selector: 'app-invoice-detail-modal',
  templateUrl: './invoice-detail-modal.component.html',
  styleUrls: ['./invoice-detail-modal.component.css'],
  providers: [koacheeOfferListModel]
})
export class InvoiceDetailModalComponent implements OnInit {
  @Input() invoicedata: any;
  @Input() feeData: any
  @Input() transactionData: any
  @Input() filterData: any
  @Output() invoiceOutput: EventEmitter<any> = new EventEmitter<any>();

  public estimatedDate: any;
  public currentUserType: any;
  public promotionAmount: any = 0;
  public paidToCoach: any = 0;
  constructor(
    public genericFunc: GenericFunctionsComponent,
    public shareUserData: ShareDataProviderComponent,
    public offerCurrentModel: koacheeOfferListModel,
    public HomeComponent: HomeComponent,
  ) {
  }

  ngOnInit() {
    this.estimatedDate = new Date(this.invoicedata.dateReleasedRequested);
    this.estimatedDate.setDate(this.estimatedDate.getDate() + 7);
    this.currentUserType = this.genericFunc.getLoggedInUserType();
    this.promotionAmount = (this.invoicedata.invoiceAmount - this.invoicedata.paidAmount).toFixed(2)
    this.paidToCoach = (this.invoicedata.paidAmount - this.invoicedata.koacheeFee).toFixed(2)
  }

  unixTime(paidDate: any) {
    var u = new Date(paidDate * 1000);
    let month = u.getUTCMonth() + 1;
    return u.getUTCFullYear() +
      '-' + ('0' + month).slice(-2) +
      '-' + ('0' + u.getUTCDate()).slice(-2) +
      ' ' + ('0' + u.getUTCHours()).slice(-2) +
      ':' + ('0' + u.getUTCMinutes()).slice(-2) +
      ':' + ('0' + u.getUTCSeconds()).slice(-2) +
      '.' + (u.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5)
  }
  
  invoiceOutputFunc(contractId: any) {
    let data = {
      "contractID": contractId,
      "type": this.genericFunc.getLoggedInUserType(),
      "filterType": this.filterData
    }
    this.invoiceOutput.emit(data)
    this.clearData();
  }

  clearData() {
    this.shareUserData.showInvoiceDetailModal = false;
    this.invoicedata = '';
  }
}
