import { Component, OnInit, Input, AfterViewInit, EventEmitter, Output, Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { LocalStorageComponent } from '../../providers/local-storage/local-storage';
import { Constants } from '../../consts';
import { Router } from '@angular/router';
// import { AuthService } from 'ng4-social-login';
import { GenericFunctionsComponent } from '../../providers/generic-functions/generic-functions';
import { SocketServiceService } from '../../services/socket/socket-service.service';
import { isPlatformBrowser, Location } from '@angular/common';
import { ShareDataProviderComponent } from '../../providers/share-data-provider/share-data-provider.component';
// import { ApiManagerComponent } from '../../providers/api-manager/api-manager';
import { getSubDomain } from '../../state/subDomain/subDomain.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { subDomainUpdate } from '../../state/subDomain/subDomain.actions';

declare const gapi: any;
 @Injectable({
   providedIn:'root'
 })
@Component({
  selector: 'app-cus-header1',
  templateUrl: './cus-header1.component.html',
  styleUrls: ['./cus-header1.component.css']
})
export class CusHeader1Component implements AfterViewInit {
  logoPath = Constants.logoImg;
  kWord = Constants.kWord;
  @Input() showSignUpLink: boolean = false;
  @Input() showOherOptions: boolean = false;
  @Input() showLoginLink: boolean = false;
  @Input() completeSignUp: boolean = false;
  @Input() totalBadge: any;
  @Input() currentpage: string = '';
  @Output() headerNotification: EventEmitter<string> = new EventEmitter<string>();

  // @Input() messageCounteHeader: number;
  public advertiseLogo : any = Constants.advertiseLogo;
  public userId = '';
  public userProfileImage = '';
  public userName = '';
  public userProvider = '';
  public userType = '';
  public notificationBarStatus: boolean = false;
  public loading: boolean = false;
  public notificationText = '';
  public href: any;
  public route: any;
  public feedbackMessage: string = '';
  public showMessageBadge: boolean = false;
  public hideVerificationBar: boolean = false;
  public showKoacheeSuitePage: any;
  public unreadMessagesCount: any = 0;
  public isUserVerified: any
  public isLoggedIn: any
  public userEmail: any
  public handle: any;
  public unreadNotification:any;
  public typeOfDevice: any;
  public deviceInfo: any;
  public activeSubDomain: boolean = false;
  
  constructor(
    // private authService: AuthService,
    private localStore: LocalStorageComponent,
    private _route: Router,
    private location: Location,
    public shareUserData: ShareDataProviderComponent,
    public socketInstance: SocketServiceService,
    private genricFunc: GenericFunctionsComponent,
    @Inject(PLATFORM_ID) private platformId: Object,
    private store: Store<AppState>
    // public apiManager: ApiManagerComponent,

  ) {
    if(isPlatformBrowser(this.platformId)){
      this.onload()
    }
    // this.socketInstance.connectSocket();
    // this.socketBroadcastSettings();
  }

  // configureSocket() {
  //   this.socketInstance.connectSocket();
  // }


  // socketAfterApiCall() {
  //   this.configureSocket();
  //   this.socketBroadcastSettings();
  // }

  onload() {
    this.store.select(getSubDomain).subscribe((getSubDomain:any)=>{
      this.activeSubDomain = getSubDomain.enableSubDomain
    });

    this.userId = this.genricFunc.getLoggedInUserID();
    this.userProfileImage = this.genricFunc.getLoggedInUserProfileImageURL();
    this.userName = this.genricFunc.getLoggedInUserName().trim();
    this.userProvider = this.genricFunc.getLoggedInUserProvider();
    this.userType = this.genricFunc.getLoggedInUserType();
    this.unreadMessagesCount = this.genricFunc.getUnreadMessagesCountSec();
    this.unreadNotification = this.genricFunc.getNotificationCountSec()
    this.isUserVerified = this.genricFunc.getIsverifiedEmail();
    this.isLoggedIn = this.genricFunc.isUserLoggedIn();
    this.userEmail = this.genricFunc.getLoggedInEmailID()
    this.handle = this.genricFunc.getLoggedInUserHandle()
    this.route = this.location.path();

    this.showKoacheeSuitePage = this.localStore.getKoacheeSuitePlan();
    
    if ((this.route.search('/verification') != -1) || (this.route.search('/complete-signup-account') != -1) || (this.route.search('/thank-you') != -1)) {
      this.hideVerificationBar = true;
    }

    if (this.isLoggedIn == false) {
      this.hideVerificationBar = true;
    }
    if ((this.unreadMessagesCount > 0) && (this.unreadMessagesCount != '')) {
      this.showMessageBadgeFunc();
    }
  }

  helpAndSupport(){
    this.genricFunc.amplitudeFunc(Constants.helpAndSupportEvent)
  }
  
  onClick() {
    this.headerNotification.emit('Notification Clicked')
  }

  onNotificationClicked(val: any) {
    if (val == 'Notification Clicked') {
      this.onClick();
    }
  }

  ngAfterViewInit() {
    if(isPlatformBrowser(this.platformId)){
    this.socketBroadcastSettings();
    this.route = this.location.path();
    if (this.route == '/' + Constants.chatPath) {
      setTimeout(() => {
        this.hideMessageBadgeFunc()
      }, 200);
    }
  }
  }

  // Links to the pages
  homePageLink() {
    this._route.navigateByUrl(Constants.homePath);
  }

  coachListPageLink() {
    this._route.navigateByUrl(Constants.coachListPath);
  }

  helpPageLink() {
    this._route.navigateByUrl(Constants.helpPath);
  }

  invoiceListLink() {
    this._route.navigateByUrl(Constants.invoiceListPath);
  }

  accountLink() {
    this._route.navigateByUrl(Constants.accountPath);
  }

  settingsLink() {
    this._route.navigateByUrl(Constants.settingsPath);
  }

  koacheeOfferListLink() {
    if (this.userType == 'Coach') {
      this._route.navigateByUrl(Constants.koacheeOffersListPath);
    } else {
      this._route.navigateByUrl(Constants.koacheeProposalsListPath);
    }
  }

  currentUserProfile() {
    this._route.navigateByUrl(Constants.chatPath);
  }

  loginPageLink() {
    this.genricFunc.resetUserWithoutLogin()
    this._route.navigateByUrl(Constants.loginPath);
  }

  signUpLink() {
    this.genricFunc.resetUserWithoutLogin()
    this._route.navigateByUrl(Constants.signUpPath);
  }

  // Link to message page also clearing the badge above message link
  messagesPageLink() {
    this.hideMessageBadgeFunc();
    this.localStore.clearUnreadMessagesCount()
    this._route.navigateByUrl(Constants.chatPath);
  }

  allNotificationsLink() {
    this._route.navigateByUrl(Constants.allnotificationspath);
  }

  logOut() {
    if(isPlatformBrowser(this.platformId)){
    this.genricFunc.amplitudeFunc(Constants.logOutEvent)
    if (this.userProvider != "KOACHEE") {
      // gapi.auth2.getAuthInstance().disconnect();
      // this.authService.signOut();
    }
    // let domainData = {
    //   enableSubDomain: false,
    //   apiCall: false,
    // }
    // this.store.dispatch(subDomainUpdate(domainData));
    this.localStore.clearAllValues();
    //TODO:AMAN this.socketInstance.closeSocket();
    setTimeout(() => {
      window.location.reload();
      this._route.navigateByUrl(Constants.homePath);
    }, 400);
  }
  }
  socketBroadcastSettings() {
    this.socketInstance.broadCastSocket.subscribe(
      value => {
        if (value) {
          this.checkNewSocketDataMessage(value);
        }
      }
    );
  }

  // Get Messages from socket data and show the bar under the header
  checkNewSocketDataMessage(socketMsg: any) {
    let type = socketMsg.type;
    this.href = this._route.url;
    if ((this.userId == socketMsg.receiverID) && (type == 'messageData') && (this.href != '/' + Constants.chatPath)) {
      // this.showMessageBadgeFunc();
      this.setNotification("You have recieved new message");
    } else if (this.userId == socketMsg.receiverID) {
      if ((type == 'OfferSend') || (type == 'OfferAccepted') || (type == 'OfferWithdrawn') || (type == 'InvoiceGenerated') || (type == 'OfferRejected') || (type == 'OfferPendingCompletion') || (type == 'OfferCompleted') || (type == 'Review') ||(type == 'Paid')||(type == 'Funded')||(type == 'Released')||(type == 'InsertSchedule')) {
        // this.showMessageBadge = false;
        this.setNotification("You have recieved new notification");
      } else {
        this.setNotification("You have recieved new feed");
      }
    }
  }

  // Show the banner, make badge value true
  showMessageBadgeFunc() {
    if (this.unreadMessagesCount <= 0) {
      this.unreadMessagesCount = this.unreadMessagesCount + 1;
      this.genricFunc.setUnreadMessagesCountSec(this.unreadMessagesCount);
    }
    this.showMessageBadge = true;
  }

  // Show the banner, make badge value false
  hideMessageBadgeFunc() {
    // this.genricFunc.removeNotificationCount();
    this.genricFunc.setUnreadMessagesCountSec(0);
    this.showMessageBadge = false;
  }

  setNotification(msg: any) {
    this.notificationText = msg;
    this.showNotificationBar();
  }

  closeNotificationBar() {
    this.notificationBarStatus = false;
  }

  showNotificationBar() {
    this.notificationBarStatus = true;
  }

  // generateLink() {
  //     var data = {
  //       "emailid": this.userEmail,
  //       "name": this.userName
  //     };
  //     this.loading = true;
  //     this.apiManager.postMethod(Constants.resendVerifyEmail, false, data).subscribe((apiResponse) => {
  //       this.loading = false
  //       if(apiResponse.response.data == "Email Sent") {
  //         // this.showSendButton = true;
  //         // this.toast.success(Constants.emailVerificationNewPending);
  //         // this._router.navigateByUrl(Constants.loginPath);
  //       }
  //     })
  // }
}
