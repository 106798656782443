<div class="userDataSec">
  <ngx-loading [show]="loadingExt" [config]="{}"></ngx-loading>

  <!-- <div class="mainBannerImage"
    *ngIf="currentCoachData.BannerImageURL != null && currentCoachData.BannerImageURL != '' && currentCoachData.BannerImageURL != undefined; else emptyBanner;"
    [ngStyle]="{ 'background-image': 'url(' + currentCoachData.BannerImageURL + ')'}">
  </div>
  <ng-template #emptyBanner>
  </ng-template> -->
  <div class="mainBannerImage">
  </div>
  <div class="container marginTop4emImp whiteBoxSec">
    <div class="row">
      <div class="col-xs-12 col-sm-7 breakWordAll">
        <h2 *ngIf="currentCoachData.Name">{{currentCoachData.Name}}</h2>
        <p *ngIf="currentCoachData.Headline != '' && currentCoachData.Headline != null && currentCoachData.Headline != undefined;"
          class="marginTop1em">
          <span *ngIf="currentCoachData.Headline.length < 1; else lessDesc1">
            {{currentCoachData.Headline}}
          </span>
          <ng-template #lessDesc1>
            <span>
              {{currentCoachData.Headline}}
            </span>
          </ng-template>
        </p>

        <div class="fullWidth marginTop1em"
          *ngIf="currentCoachData.DetailDescription != '' && currentCoachData.DetailDescription != null && currentCoachData.DetailDescription != undefined;">
          <div class="homeDesc detailDescSec">
            {{removeTags(currentCoachData.DetailDescription) | TruncatePipe : [250, '...']}}
          </div>
          <!-- <button type="submit" class="linkBtn linkBtnSec" data-toggle="modal" data-target="#myModal" *ngIf="!IsUserLoggedIn">more...</button> -->
          <a (click)="genericFunc.redirect(currentCoachData.Type, currentCoachData.Handle,'more')">more...</a>
        </div>
      </div>
      <div class="col-xs-12 col-sm-3 homeSkillsSec">
        <div class="homeExpertiseSec">
          <p><b>Expertise</b></p>
            <p class="userCategorySec">
              {{genericFunc.getUserSkill(currentCoachData.Expertise) | TruncatePipe : [30, '...']}}</p>
          <div class="fullWidth homePackageSec" *ngIf="currentCoachData.PackageRate">
            <!-- </span> -->
            <span class="fullWidth" *ngIf="currentCoachData.PackID != null">
              Packages Starting from
              <span><b>${{currentCoachData.PackageRate}}</b></span>
            </span>
            <!-- <div class="col-xs-12">
             NPS: {{currentCoachData.NpsScore}}
          </div>  -->
          </div>

          <div class="reviewAndRating homeRating">
            <div class="ratingPart">
              <div class="ratingArea"
                *ngIf="currentCoachData.Ratings != undefined && currentCoachData.Ratings != null && currentCoachData.Ratings != 0; else noRatingSec">
                <div class="ratings">
                  <div class="empty-stars"></div>
                  <div class="full-stars" [style.width.%]=currentCoachData.Ratings></div>
                </div>
              </div>
              <ng-template #noRatingSec>
                <div class="ratings noRatingSecHome">
                  <div class="empty-stars"></div>
                </div>
              </ng-template>
            </div>


            <!-- <button type="submit" class="linkBtn linkBtnSec" data-toggle="modal" data-target="#myModal" *ngIf="currentCoachData.Ratings != undefined && currentCoachData.Ratings != null && currentCoachData.Ratings != 0 && !IsUserLoggedIn; else noRatingSecText2">Reviews</button>
            <ng-template #noRatingSecText2>
              <span *ngIf="!IsUserLoggedIn">No ratings yet</span>
            </ng-template> -->

            <a (click)="genericFunc.redirect(currentCoachData.Type, currentCoachData.Handle, 'reviews')"
              *ngIf="currentCoachData.Ratings != undefined && currentCoachData.Ratings != null && currentCoachData.Ratings != 0; else noRatingSecText">Reviews</a>
            <ng-template #noRatingSecText>
              <span *ngIf="IsUserLoggedIn" >No ratings yet</span>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-2 followBtnSec">
        <div class="btnsSec">
          <a class="btn blueBtn homeBtn"
            (click)="redirect(currentCoachData.Handle, 'CoachProfileButton')">Coach's Profile</a>
        </div>
        <div *ngIf="!IsUserLoggedIn; else loggedInSec">

          <!-- <div class="btnsSec" (click)="genericFunc.setUserWithoutLogin(currentCoachData.Handle,'profile')">
            <button type="submit" class="btn blueBtn homeBtn" data-toggle="modal" data-target="#myModal">Coach's Profile</button>
          </div> -->
          <div class="btnsSec" *ngIf="currentCoachData.FreeConsultStatus == true && currentCoachData.FreeConsultAlreadyTaken == 0"
            (click)="genericFunc.setUserWithoutLogin(currentCoachData.Handle,'profile')">
            <button type="submit" class="btn orangeBtn whiteColor homeBtn" data-toggle="modal" data-target="#myModal">Request
              Free Consultation</button>
          </div>

          <div class="btnsSec"
            *ngIf="currentUserID != currentCoachData.ID && ((currentCoachData.FreeConsultStatus == false) || (currentCoachData.FreeConsultStatus == true && currentCoachData.FreeConsultAlreadyTaken > 0)) ">
            <button type="submit" data-target="#myModal" data-toggle="modal"
              (click)="genericFunc.setUserWithoutLogin(currentCoachData.Handle, 'profile')"
              class="btn orangeBtn whiteColor homeBtn">Hire {{currentCoachData.Name}}</button>
          </div>
          <!-- <div class="btnsSec">
            <button type="submit" (click)="setUserWithoutLogin(currentCoachData.Handle, 'profile')"
              class="btn homeSlideBtn" data-toggle="modal" data-target="#myModal">Follow</button>
          </div> -->
        </div>
        <ng-template #loggedInSec>
          <!-- <div class="btnsSec">
            <a class="btn blueBtn homeBtn"
              (click)="redirect(currentCoachData.Handle, 'CoachProfileButton')">Coach's Profile</a>
          </div> -->

          <div class="btnsSec"
            *ngIf="currentUserID != currentCoachData.ID && currentCoachData.FreeConsultStatus == true && currentCoachData.FreeConsultAlreadyTaken == 0 && currentCoachData.StatusID == 1 && isUserVerified == 1">
            <button type="submit" data-toggle="modal" (click)="freeDemoModel('free')" data-target="#popUpId"
              class="btn orangeBtn whiteColor homeBtn">Request Free Consultation</button>
          </div>

          <!-- <div class="btnsSec"
            *ngIf="currentUserID != currentCoachData.ID && currentCoachData.FreeConsultStatus == false && currentCoachData.ContractsID == null && currentCoachData.StatusID == 1 && loggedInUserData.StatusID == 1">
            <button type="submit" data-toggle="modal" (click)="freeDemoModel()"
              class="btn homeSlideBtn orangeBtn">Hire {{currentCoachData.Name}}</button>
          </div>
           -->
          <!-- <button class="btn homeSlideBtn orangeBtn" data-toggle="modal"
            *ngIf="popUpId == '' && ((currentCoachData.FreeConsultStatus == false) || (currentCoachData.FreeConsultStatus == true && currentCoachData.FreeConsultAlreadyTaken > 0)) && (this.currentUserID != this.currentCoachData.ID)"
            (click)="freeDemoModel('hire')" type="submit">1Hire
            {{currentCoachData.Name}}</button> -->
          <div class="btnsSec">
            <button
              *ngIf="((currentCoachData.FreeConsultStatus == false) || (currentCoachData.FreeConsultStatus == true && currentCoachData.FreeConsultAlreadyTaken > 0)) && (this.currentUserID != this.currentCoachData.ID)"
              class="btn orangeBtn whiteColor homeBtn" data-toggle="modal" (click)="freeDemoModel('hire')" type="submit"
              data-target="#popUpId">Hire
              {{currentCoachData.Name}}</button>
          </div>

          <div class="btnsSec"
            *ngIf="currentUserID != currentCoachData.ID && currentCoachData.FreeConsultStatus == true && currentCoachData.ContractsID == null && currentCoachData.StatusID == 1 && isUserVerified != 1">
            <button type="submit" data-target="#VerifyEmailAlertLoggedInUser" data-toggle="modal"
              class="btn orangeBtn whiteColor homeBtn">Request Free Consultation</button>
          </div>

          <div class="btnsSec"
            *ngIf="currentUserID != currentCoachData.ID && currentCoachData.FreeConsultStatus == true && currentCoachData.ContractsID == null && currentCoachData.StatusID != 1 && isUserVerified == 1">
            <button type="submit" data-target="#VerifyEmailAlertCurrentCoach" data-toggle="modal"
              class="btn orangeBtn whiteColor homeBtn">Request Free Consultation</button>
          </div>

          <!-- <div class="btnsSec" *ngIf="currentCoachData.Type == 'Coach' && currentType != 'Coach' && (currentCoachData.isFollow == false|| currentCoachData.isFollow == null)" >
            <button type="submit" class="btn homeSlideBtn" [disabled]="enableBtn == false" (click)="isFollowUser(currentCoachData.ID, true)"
              *ngIf="currentUserID != currentCoachData.ID">Follow</button>
          </div>

          <div class="btnsSec" *ngIf="currentCoachData.Type == 'Coach' && currentType != 'Coach' && currentCoachData.isFollow == true" >
            <button type="submit" class="btn unfollowBtn pull-right" [disabled]="enableBtn == false" (click)="isFollowUser(currentCoachData.ID, false)"
              *ngIf="currentUserID != currentCoachData.ID">Unfollow</button>
          </div> -->
        </ng-template>
      </div>
    </div>
  </div>
</div>
<app-send-message-modal></app-send-message-modal>

<div id="VerifyEmailAlertLoggedInUser" class="modal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="submit" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Not Allowed</h4>
      </div>
      <div class="modal-body">
        <p>Please verify your email before Free Consultation</p>
      </div>
      <div class="modal-footer">
        <a class="btn gmailBtn" data-dismiss="modal">Close</a>
        <!-- <button class="btn blueBtn" data-dismiss="modal"
          (click)="genericFunc.redirect(currentCoachData.Type, currentCoachData.Handle)">Coach's Profile
        </button> -->
      </div>
    </div>
  </div>
</div>

<div id="VerifyEmailAlertCurrentCoach" class="modal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="submit" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Not Allowed</h4>
      </div>
      <div class="modal-body">
        <p>You cannot get Free Consulation because {{currentCoachData.Name}} does not verifed email. </p>
      </div>
      <div class="modal-footer">
        <!-- <a class="linkBtn" data-dismiss="modal">Cancel</a>
        <button class="btn blueBtn" type="submit" data-dismiss="modal" (click)="genericFunc.chatMessageBox(currentCoachData)">
          Yes
        </button> -->
        <a class="btn gmailBtn" data-dismiss="modal">Close</a>
      </div>
    </div>
  </div>
</div>


<div id="alreadyFreeDemo" class="modal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="submit" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">Not Allowed</h4>
      </div>
      <div class="modal-body">
        <p>You either have a hire offer or an active Free Consultation with {{currentCoachData.Type}}. Click on Yes to
          contact with Coach</p>
      </div>
      <div class="modal-footer">
        <a class="linkBtn" data-dismiss="modal">Cancel</a>&nbsp;&nbsp;
        <button class="btn blueBtn" data-dismiss="modal" (click)="genericFunc.chatMessageBox(currentCoachData)">Yes
        </button>
      </div>
    </div>
  </div>
</div>
<!-- 
<app-hire-coach *ngIf=" openHireModal == true" [profileData]='profileData'
  [singlePackage]='shareUserData.singlePackage' [multiplePackage]='shareUserData.multiplePackage'
  [showAlreadyHire]='showAlreadyHire' [checkCurrentUserProfile]='checkCurrentUserProfile'
  (notify)="onNotifyClicked($event)"></app-hire-coach> -->

<app-hire-coach *ngIf="openHireModal" [profileData]='currentCoachData' [singlePackage]="shareUserData.singlePackage"
  [multiplePackage]="shareUserData.multiplePackage" [freePackage]='shareUserData.freeConsultData'
  [showAlreadyHire]='showAlreadyHire' (notify)="onNotifyClicked($event)" [checkCurrentUserProfile]='loggedInUserData'
  [isShowFreeDemoRate]="currentCoachData.FreeConsultData"></app-hire-coach>

 
<div id="popUpId" *ngIf="hireButtonVisibility == true && popUpId != ''" class="modal fade" role="dialog">
  <div class="modal-dialog">

    <div class="modal-content" *ngIf="popUpId == 'CoachEmailVerifAlert'">
      <div class="modal-header">
        <button type="submit" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modalHeader16px">Not Allowed</h4>
      </div>
      <div class="modal-body">
        <p>You cannot hire because {{currentCoachData.Name}} does not verifed email.</p>
      </div>
      <div class="modal-footer">
        <button type="submit" data-dismiss="modal" class="btn gmailBtn">Close</button>
        <!-- <a class="linkBtn" data-dismiss="modal">Cancel</a> -->
      </div>
    </div>
    <div class="modal-content" *ngIf="popUpId == 'zeroRateDialog'">
      <div class="modal-header">
        <button type="submit" class="close" data-dismiss="modal"  (click)="closeBtn(currentCoachData)">&times;</button>
        <h4 class="modal-title modalHeader16px">Not Allowed</h4>
      </div>
      <div *ngIf="currentCoachData" class="modal-body">
        <p>{{currentCoachData.Name}} have not published any rates yet. Message coach instead? </p>
      </div>
      <div *ngIf="currentCoachData" class="modal-footer">
        <a class="linkBtn" data-dismiss="modal"  (click)="closeBtn(currentCoachData)">Cancel</a>
        <button (click)="genericFunc.chatMessageBox(currentCoachData)" data-dismiss="modal" class="btn btnBlue"
          type="submit">Yes</button>
        <!-- <button type="submit" class="btn btnBlue" data-dismiss="modal">Close</button> -->
      </div>
    </div>
    <div class="modal-content" *ngIf="popUpId == 'hireCoachEmailVerifyandRateAlert'">
      <div class="modal-header">
        <button type="submit" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title modalHeader16px">Not Allowed</h4>
      </div>
      <div *ngIf="currentCoachData" class="modal-body">
        <p>{{currentCoachData.Name}} have not published any rates and not verified email yet . Message coach instead?
        </p>
      </div>
      <div *ngIf="currentCoachData" class="modal-footer">
        <a class="linkBtn" data-dismiss="modal">Cancel</a>
        <button (click)="genericFunc.chatMessageBox(currentCoachData)" data-dismiss="modal" class="btn btnBlue"
          type="submit">Yes</button>
      </div>
    </div>
    <div class="modal-content" *ngIf="popUpId == 'hireCoachEmailVerifyAlert'">
      <div class="modal-header">
        <button type="submit" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modalHeader16px">Not Allowed</h4>
      </div>
      <div class="modal-body">
        <p>Please verify your email before hire {{currentCoachData.Name}}.</p>
      </div>
      <div class="modal-footer">
        <button type="submit" data-dismiss="modal" class="btn gmailBtn">Close</button>
        <!-- <a class="linkBtn" data-dismiss="modal">Cancel</a> -->
      </div>
    </div>
    <div class="modal-content" *ngIf="popUpId == 'hireCoachAlert'">
      <div class="modal-header">
        <button type="submit" class="close" (click)="closeBtn(currentCoachData)"  data-dismiss="modal">&times;</button>
        <h4 class="modalHeader16px">Not Allowed</h4>
      </div>
      <div class="modal-body">
        <p>You either have a hire offer or an active contract with {{currentCoachData.Name}}.</p>
      </div>
      <div class="modal-footer">
        <button type="submit" data-dismiss="modal" (click)="closeBtn(currentCoachData)" class="btn gmailBtn">Close</button>
      </div>
    </div>
    <div class="modal-content" *ngIf="popUpId == 'deleteCoachAlert'">
      <div class="modal-header">
        <button type="submit" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modalHeader16px">Not Allowed</h4>
      </div>
      <div class="modal-body">
        <p>You cannot hire {{currentCoachData.Name}} because this account has been deleted by Koachee</p>
      </div>
      <div class="modal-footer">
        <button type="submit" data-dismiss="modal" class="btn gmailBtn">Close</button>
        <!-- <a class="linkBtn" data-dismiss="modal">Cancel</a> -->
      </div>
    </div>
  </div>
</div>