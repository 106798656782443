export const environment = {
  production: true,
  baseUrl: 'https://portal-prod-apilb.koachee.com',//'http://KoacheeDev-API-LB-12004713.us-east-1.elb.amazonaws.com',//'http://koacheeapistage-env.eba-fw5z259w.us-east-2.elasticbeanstalk.com',//"http://koacheeapiaws-env-3.eba-d2d3y3yp.us-east-1.elasticbeanstalk.com",//api url
  googleClientId: '674373885788-t8jk9g5drc6ngbj5sijpojb8dnm97vkl.apps.googleusercontent.com',
  angularSiteURL: 'https://portal.koachee.com/', // web url
  isAmplitudeActive: true, // web url
  stripeUrl: 'https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=ca_I4L2KBjv9FbVeeCIJAYLTY6IH192uejL&scope=read_write',
  facebookClientId: '2743090805781672',

  // Enable Maintaince Page
  enableMaintaince: false,

  // agora video call & whiteboard 
  videoCallAppID: '25c49252e00247aca852babe080bbb9f',
  whiteboardAppIdentifier: 'fByu8HOnEeysvrM9YOgM8g/6Izr0oQqOoEcLA',

};