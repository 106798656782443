import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'FirstLetter'
})

export class FirstLetter implements PipeTransform {
    transform(value: string, args: any[]): string {
        if (!value) { return ''; }
        return value.split(' ')[0];
    }
}