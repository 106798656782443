<app-notepad *ngIf="notes"></app-notepad>
<div class="footerArea">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-4 text-left" *ngIf="!activeSubDomain">
        <p>Copyright © 2019-2022 Koachee</p>
      </div>
      <div class="col-xs-12 col-sm-4 text-left" *ngIf="activeSubDomain">
        <p>Powered by <a href="https://www.koachee.com" target="_blank"><img src={{logoPath}} class="footerlogo" /></a>
        </p>
      </div>
      <div class="col-xs-12 col-sm-4 text-center versionData">
        <p>rev {{version}}</p>
      </div>
      <div class="col-xs-12 col-sm-4 text-right">
        <p>
          <a href="https://www.koachee.com/terms-of-service/" target="_blank">Terms of Services</a>&nbsp;
          |&nbsp;
          <a href="https://www.koachee.com/privacy-policy/" target="_blank">Privacy Policy</a>&nbsp;
        </p>
      </div>
    </div>
  </div>
</div>