import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Constants } from '../../consts';
import { ApiManagerComponent } from '../../providers/api-manager/api-manager';
import { LocalStorageComponent } from '../../providers/local-storage/local-storage';
import { ShareDataProviderComponent } from '../../providers/share-data-provider/share-data-provider.component';
import { subDomainUpdate } from '../../state/subDomain/subDomain.actions';
import { getSubDomain } from '../../state/subDomain/subDomain.selectors';
import { AppState } from '../../store/app.state';

@Component({
  selector: 'app-cus-footer',
  templateUrl: './cus-footer.component.html',
  styleUrls: ['./cus-footer.component.css']
})
export class CusFooterComponent implements OnInit {
  public version: any = Constants.versionNo;
  public notes: boolean = false;
  public activeSubDomain: boolean = false;
  public checkApiCall: boolean = false;
  logoPath = Constants.logoImg;
  constructor(
    public localStore: LocalStorageComponent,
    public sharedData: ShareDataProviderComponent,
    public apiManager: ApiManagerComponent,
    private store: Store<AppState>
  ) {
    if (this.localStore.getKoacheeSuitePlan() != 'null') {
      this.notes = true;
    }
  }

  ngOnInit() {
    this.store.select(getSubDomain).subscribe((getSubDomain: any) => {
      this.activeSubDomain = getSubDomain.enableSubDomain
      this.checkApiCall = getSubDomain.apiCall;
      this.sharedData.subDomainApiCall = getSubDomain.apiCall
    });

    // if(!this.checkApiCall) {
    //   this.checkActiveSubDomain()
    // }
  }

  // checkActiveSubDomain() {
  //   let enableSub;
  //   let mainUrl = window.location.href;
  //   // let mainUrl = "https://jeevan.koachee.com"
  //   let url = mainUrl.replace(/(^\w+:|^)\/\//, '');
  //   let domainName = url.split(".");

  //   const substring = "localhost:";

  //   if ((domainName) && (!domainName[0].includes(substring))) {
  //     if ((domainName[0] !== "portal") && (domainName[0] !== "portal-stage") && (domainName[0] !== "pre-prod")) {
  //       let data = {
  //         "Handle": domainName[0]
  //       }
  //       this.apiManager.postMethod(Constants.checkUserSubDomain, false, data).subscribe((jsonData: any) => {
  //         console.log("data from api call", jsonData)
  //         if (jsonData) {
  //           if (jsonData.status == 1) {
  //             if (jsonData.response) {
  //               if (jsonData.response.data === "true") {
  //                 enableSub = true;
  //               } else {
  //                 enableSub = false;
  //               }
  //               let domainData = {
  //                 enableSubDomain: enableSub,
  //                 apiCall: true,
  //               }
  //               this.store.dispatch(subDomainUpdate(domainData));
  //             }
  //           }
  //         }
  //       }, (error: any) => {
  //         enableSub = false;
  //       });
  //     }
  //   }
  // }
}
