import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { MediaService, MediaStreamType } from '../../../services/meeting/media.service';
import { SoundMeter } from '../../../services/meeting/sound-meter';

@Component({
  selector: 'app-sound-meter',
  templateUrl: './sound-meter.component.html',
  styleUrls: ['./sound-meter.component.css']
})
export class SoundMeterComponent implements AfterViewInit, OnDestroy {

  @ViewChild("meter") meterRef!: ElementRef;
  private soundMeter!: SoundMeter;
  private subscription?: Subscription;
  isBrowser: any;

  constructor(
    private mediaService: MediaService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (isPlatformBrowser(this.platformId)) { }
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.connect();
      this.subscription = this.mediaService.selectedAudioInputId.subscribe(id => {
        if (id) {
          this.connect(id);
        }
      });
    }
  }

  async connect(deviceId?: string) {
    const stream = await this.mediaService.getMediaStream(MediaStreamType.audio, undefined, undefined, undefined, deviceId);
    if (!stream) {
      return;
    }
    this.soundMeter = new SoundMeter(new AudioContext());
    this.soundMeter.connect(stream!,
      (instant) => this.meterRef ? this.meterRef.nativeElement.value = instant : null,
      (error) => console.debug('navigator.MediaDevices.getUserMedia error: ', error.message, error.name)
    );
  }

  ngOnDestroy(): void {
    if (this.soundMeter) {
      this.soundMeter.context.close();
    }
    this.subscription?.unsubscribe();
  }

}
