<div class="content">
  <app-cus-header1 [showSignUpLink]='true'></app-cus-header1>
  <ngx-loading [show]="loading" [config]="{}"></ngx-loading>
  <div class="coachBGSecNew">
  </div>
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-3 col-md-6 col-lg-offset-3 col-lg-6">
        <div class="panel panel-default marginTop2em panelSetting">
          <div class="panel-body">
            <div class="row loginArea inputWithIcon text-center">
              <h4>Log in and get to work!</h4>
              <!-- Login Area -->
              <form (keydown)="traditionalLogin($event, false)">
                <div class="col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8">
                  <div class="inputBox">
                    <span class="glyphicon glyphicon-user"></span>
                    <input type="email" name="loginEmail" maxlength="100" id="email" class="form-control"
                      placeholder="Email address" required="required" [(ngModel)]="currentModel.loginEmail">
                  </div>
                  <div class="inputBox pwdHideShow marginTop1emImp">
                    <span class="glyphicon glyphicon-lock"></span>
                    <input type="password" [type]="currentModel.passwordShow == false ? 'password' : 'text'" name="loginPassword" id="password" maxlength="500" class="form-control"
                      placeholder="Password" required="required" [(ngModel)]="currentModel.loginPassword">
                    <i class="fa pwdHideShowIcon" (click)="isPasswordShow()"
                      [ngClass]="[currentModel.passwordShow == false ? 'fa fa-eye' : 'fa fa-eye-slash']"
                      aria-hidden="true"></i>
                  </div>
                  <button class="btn btnBlue" type="button" (click)="traditionalLogin($event, true)">Login</button>
                </div>
              </form>

              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 marginTop1em text-center">
                <a (click)="forgotPasswordRedirect()">Forgot password?</a>
              </div>

            </div>
            <div class="row" *ngIf="!activeSubDomain" >
              <div class="btnSignUpArea text-center">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <h4>Or</h4>
                </div>
                <!-- <a [routerLink]="[ '/signup' ]" class="btn btnBlue">Sign Up</a> -->
                <!-- Social Media Sign Up Buttons -->
                <ul class="socialBtnRow">
                  <li><button class="btn gmailBtn" (click)="signInWithGoogle()" type="submit"><i class="fa fa-google"
                        aria-hidden="true"></i> Google</button></li>
                  <li>
                    <button class="btn facebookBtn" type="submit" (click)="signInWithFacebook()"><i
                        class="fa fa-facebook" aria-hidden="true"></i> Facebook</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-cus-footer></app-cus-footer>