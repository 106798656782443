<div id="myModal" class="modal fade" role="dialog">
  <div class="modal-dialog colWidth">

    <!-- Modal content -->
    <div class="modal-content ">
      <div class="modal-header padding5px">
        <button type="submit" (click)="genericFunc.resetUserWithoutLogin()" class="close"
          data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body ">
        <div class="row text-center">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 testt">
            <!-- <h4>To send message Login/Sign Up here.</h4> -->
            <h4 class="modalHeader22px">Continue Exploring Koachee</h4>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <button type="submit" class="btn blueBtn modalButtonsWidth" (click)="loginPageLink()"
              data-dismiss="modal">Login</button>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <span class="OrSection"><span>OR</span></span>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 test">
            <button type="submit" class="btn orangeBtn whiteColor modalButtonsWidth" (click)="SignupPageLink()"
              data-dismiss="modal">Create
              New Account</button>
          </div>
        </div>
      </div>
      <!-- <div class=" modal-footer">
        <a class="btn gmailBtn" data-dismiss="modal" (click)="genericFunc.resetUserWithoutLogin()">Close</a> -->
      <!-- <button type="submit" (click)="genericFunc.resetUserWithoutLogin()" class="btn btn-default hide" data-dismiss="modal">Close</button> -->
      <!-- </div> -->
    </div>
  </div>
</div>