import { Component, OnInit, ChangeDetectorRef, AfterViewInit, EventEmitter, Output, ViewRef } from '@angular/core';
import { Constants } from '../../consts';
import { ApiManagerComponent } from '../../providers/api-manager/api-manager';
import { SocketServiceService } from '../../services/socket/socket-service.service';
import { GenericFunctionsComponent } from '../../providers/generic-functions/generic-functions';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ShareDataProviderComponent } from '../../providers/share-data-provider/share-data-provider.component';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  @Output() notificationClicked: EventEmitter<string> = new EventEmitter<string>();

  public notificationData: any = [];
  public totalCount: number = 0;
  public loadingNotification = false;
  public userId = '';
  public badgeCountFunc: boolean = false;
  public userType: string = '';
  public getAllNotificationsAPI: any;
  public updateNotificationReadStatusAPI: any;

  constructor(
    public socketInstance: SocketServiceService,
    private _route: Router,
    private genricFunc: GenericFunctionsComponent,
    public reload: ChangeDetectorRef,
    public location: Location,
    public shareUserData: ShareDataProviderComponent,
    private apiManager: ApiManagerComponent,
  ) {
    this.userId = this.genricFunc.getLoggedInUserID();
    this.userType = this.genricFunc.getLoggedInUserType();
    this.getAllNotifications()
  }


  ngOnInit() {
    this.socketBroadcastSettings()
    if (this.totalCount) {
      this.totalCount = this.genricFunc.getNotificationCountSec();
    }
    // this.localStore.clearNotificationCount()
  }

  onClick() {
    this.notificationClicked.emit('Notification Clicked')
  }

  // Get the socket value from server
  socketBroadcastSettings() {
    this.socketInstance.broadCastSocket.subscribe(
      value => {
        if (value) {
          this.updateBadgeCount(value);
        }
      }
    );
  }

  // Update the badge counter when user recieve any notification accept message
  updateBadgeCount(socketMsg: any) {
    let type = socketMsg.type;
    if (this.userId == socketMsg.receiverID) {
      this.totalCount++;
      this.genricFunc.setNotificationCountSec(this.totalCount);
    }
  }

  badgeCount(counter: any) {
    // if (this.totalCount) {
      this.totalCount = counter;
    // }
  }

  // Get all the notifications of current user
  getAllNotifications(type: any = '') {
    if (type == 'Bell') {
      this.genricFunc.amplitudeFunc(Constants.bellNotificationEvent)
    }
    this.loadingNotification = true;
    this.getAllNotificationsAPI = this.apiManager.getMethod(Constants.getAllNotifications, true).subscribe((jsonData) => {
      if (jsonData.response) {
        if (jsonData.status == 1) {
          if (jsonData.response.allNotification) {
            this.notificationData = [];
            jsonData.response.allNotification.forEach((element: any) => {
              if (element.IsRead == false) {
                this.notificationData.push(element);
              }
            });
            if (jsonData.response.notificationCount) {
              if (jsonData.response.notificationCount.totalCount) {
                // if (this.totalCount) {
                  this.genricFunc.setNotificationCountSec(jsonData.response.notificationCount.totalCount);
                  this.totalCount = this.genricFunc.getNotificationCountSec();
                // }
              }
            }
            // this.reload.detectChanges();
          }
          this.loadingNotification = false;
        } else {
          // this.toast.error(jsonData.response.err, Constants.errorTitle);
        }
      }
    }, error => {
      this.loadingNotification = false;
    })
  }

  updateBadge(badgeNumber: any) {
    if (this.totalCount) {
      this.totalCount = badgeNumber;
    }
  }

  // mark the notofication status as read in database which we click from notification dropdown and redirected to the concerned page
  updateNotificationReadStatus(NotificationID: number, IsRead: boolean, eventNameText: any, EventID: any, SenderID: any, StatusID: any = '') {
    this.genricFunc.amplitudeFunc(Constants.viewNotificationEvent)
    setTimeout(() => {
      if (IsRead != true) {
        let data = {
          "notificationID": EventID
        }
        this.updateNotificationReadStatusAPI = this.apiManager.postMethod(Constants.updateNotificationReadStatus, false, data).subscribe((jsonData) => {
          if (jsonData.response) {
            if (jsonData.status == 1) {
              if (jsonData.response.count) {
                if (jsonData.response.count.totalCount) {
                  this.totalCount = jsonData.response.count.totalCount;
                  this.genricFunc.setNotificationCountSec(this.totalCount);
                }
              }
              this.reload.detectChanges();
              if (eventNameText == 'InvoiceGenerated') {
                if (this.userId == SenderID) {
                  this.shareUserData.viewingAs = 'Coachee'
                }
                this.checkUrl(Constants.invoiceListPath);
              } else if (eventNameText == 'Message') {
                this.checkUrl(Constants.chatPath)
                this._route.navigate(['/messaging'], {
                  queryParams: { 'Id': SenderID, 'Status': StatusID }
                });
              } else if (eventNameText == 'FeedCreated') {
                this.checkUrl(Constants.feedPath);
              } else if (eventNameText == 'LikePost') {
                this._route.navigateByUrl(Constants.feedPath + '/' + this.genricFunc.getLoggedInUserID());
              }
              else if (eventNameText == 'CommentPost') {
                this._route.navigateByUrl(Constants.feedPath + '/' + this.genricFunc.getLoggedInUserID());
              }
              else if (eventNameText == 'Review') {
                this._route.navigateByUrl(Constants.coachMyProfilePath);
              } else if (eventNameText == "InsertSchedule") {
                this._route.navigateByUrl(Constants.appointmentsPath);
              } else if (eventNameText == 'Paid' || eventNameText == 'Funded' || eventNameText == 'Released') {
                this._route.navigateByUrl(Constants.invoiceListPath);
              } else {
                if (this.userType == "Coach") {
                  if (this.userId == SenderID) {
                    this.shareUserData.viewingAs = 'Coachee'
                  }
                  this.checkUrl(Constants.koacheeOffersListPath);
                } else {
                  this.checkUrl(Constants.koacheeProposalsListPath);
                }
              }
            } else {
            }
          }
        }, error => {
        })
      } else {
        if (eventNameText == 'InvoiceGenerated') {
          if (this.userId == SenderID) {
            this.shareUserData.viewingAs = 'Coachee'
          }
          this.checkUrl(Constants.invoiceListPath);
        } else if (eventNameText == 'Message') {
          this.checkUrl(Constants.chatPath)
          // this.checkUrl(Constants.chatPath);
        } else if (eventNameText == 'FeedCreated') {
          this.checkUrl(Constants.feedPath);
        } else if (eventNameText == 'LikePost') {
          this._route.navigateByUrl(Constants.feedPath + '/' + this.genricFunc.getLoggedInUserID());
        }
        else if (eventNameText == 'CommentPost') {
          this._route.navigateByUrl(Constants.feedPath + '/' + this.genricFunc.getLoggedInUserID());
        } else if (eventNameText == 'Review') {
          this._route.navigateByUrl(Constants.coachMyProfilePath);
        } else if (eventNameText == "InsertSchedule") {
          this._route.navigateByUrl(Constants.appointmentsPath);
        } else if (eventNameText == 'Paid' || eventNameText == 'Funded' || eventNameText == 'Released') {
          this._route.navigateByUrl(Constants.invoiceListPath);
        } else {
          if (this.userType == "Coach") {
            if (this.userId == SenderID) {
              this.shareUserData.viewingAs = 'Coachee'
            }
            this.checkUrl(Constants.koacheeOffersListPath);
          } else {
            this.checkUrl(Constants.koacheeProposalsListPath);
          }
        }
      }
    }, 1200);
  }

  checkUrl(val: any) {
    if ('/' + val == this.location.path()) {
      this.onClick();
    } else {
      this._route.navigateByUrl(val);
    }

  }

  // redirectionMsgPage(val: any, SenderID: any, StatusID: any) {
  //   this.onClick();
  //   this._route.navigate(['/messaging'], {
  //     queryParams: { 'Id': SenderID, 'Status': StatusID }
  //   });
  // }


  ngOnDestroy() {
    if (this.getAllNotificationsAPI) {
      this.getAllNotificationsAPI.unsubscribe();
    }
    if (this.updateNotificationReadStatusAPI) {
      this.updateNotificationReadStatusAPI.unsubscribe();
    }
  }
}
