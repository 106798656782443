<div class="popupBg hireCoachPopup" 
  *ngIf="(((showAlreadyHire == false && shareUserData.alreadyHiredPopup == true) || (rehire == true && shareUserData.alreadyHiredPopup == true)))"
  >
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body noPadding">
        <ngx-loading [show]="loading" [config]="{}"></ngx-loading>
        <button type="submit" class="close" data-dismiss="modal" (click)="cancelSec()">&times;</button>
        <div class="content">
          <div class="hireCoachDiv">
            <div class="row noMargin">
              <h3 class="hireModalHeading">Hire {{profileData.Name}} {{profileData.LastName}}</h3>
              <div class="col-xs-12">
                <p class="fullWidth marginBottom1em marginTop2em">Please visit our knowlegde base to learn how the
                  hiring process and contracting works <a [href]="howItWorksLink" target="_blank"><i
                      class="fa fa-external-link" aria-hidden="true"></i></a></p>
                      <div class="col-lg-5 col-xs-12 col-sm-6 noPaddingLeft noPaddingXs ">
                        <div class="panel panel-default fullWidth">
                          <div class="panel-body hirePagePadding ">
                            <div class="col-xs-12 noPadding">
                              <h3><span>Rates</span></h3>
                              <div class="hireCoachRateSec marginTop1em" *ngIf="rehire == false">
                                <!-- free demo rates -->
                                <div class="col-lg-12 leftPadding25" *ngIf="freePackage.length > 0 && profileData.FreeConsultStatus == true && (profileData.FreeConsultAlreadyTaken == 0 )">
                                  <h3>Free Consultation</h3>
                                  <p class="marginBottom1em marginTop1em" *ngFor="let package of freePackage; let i=index">
                                    <span>
                                      <input type="radio" name="package" [id]='"PackageSecZero" + i'
                                        (click)="setSessionValue(package.PackageRate, package.PackageSession,'free', package.PackageId)">
                                      <label class="noMargin" [for]='"PackageSecZero" + i'
                                        (click)="setSessionValue(package.PackageRate, package.PackageSession,'free', package.PackageId)">
                                        <span class="lightColorSec"> For </span> {{package.PackageSession}}<span
                                          class="lightColorSec"> Session</span></label>
                                      <div class="hirePackTitle"> {{package.PackageTitle}}</div>
                                    </span>
                                  </p>
                                </div>
                                <!-- end -->
                                <div class="col-lg-12 leftPadding25" *ngIf="singlePackage.length > 0">
                                  <h3>Single Sessions</h3>
                                  <p class="marginBottom1em marginTop1em" *ngFor="let package of singlePackage; let i=index">
                                    <span
                                      *ngIf="package.PackageRate  != '' && package.PackageRate  != null && package.PackageRate  != undefined && package.PackageSession  != '' && package.PackageSession  != null && package.PackageSession  != undefined">
                                      <input type="radio" name="package" [id]='"PackageSecOne" + i'
                                        (click)="setSessionValue(package.PackageRate, package.PackageSession,'single', package.PackageId)">
                                      <label class="noMargin" [for]='"PackageSecOne" + i'
                                        (click)="setSessionValue(package.PackageRate, package.PackageSession,'single', package.PackageId)">${{package.PackageRate}}
                                        <span class="lightColorSec">for</span> {{package.PackageSession}} <span
                                          class="lightColorSec">Session</span></label>
                                      <div class="hirePackTitle">{{package.PackageTitle}}</div>
                                    </span>
                                  </p>
                                </div>
                                <div *ngIf="multiplePackage.length >0"
                                  class="col-xs-12 col-sm-12 col-md-12 col-lg-12 leftPadding25">
                                  <div>
                                    <h3>Packages</h3>
                                    <p class="marginBottom1em marginTop1em" *ngFor="let package of multiplePackage; let i=index">
                                      <span
                                        *ngIf="package.PackageRate  != '' && package.PackageRate  != null && package.PackageRate  != undefined && package.PackageSession  != '' && package.PackageSession  != null && package.PackageSession  != undefined">
                                        <input type="radio" name="package" [id]='"PackageSecTwo" + i'
                                          (click)="setSessionValue(package.PackageRate, package.PackageSession,'package', package.PackageId)">
                                        <label class="noMargin" [for]='"PackageSecTwo" + i'
                                          (click)="setSessionValue(package.PackageRate, package.PackageSession,'package', package.PackageId)">${{package.PackageRate}}
                                          <span class="lightColorSec">for</span> {{package.PackageSession}} <span
                                            class="lightColorSec">Session</span></label>
                                        <div class="hirePackTitle">{{package.PackageTitle}}</div>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div class="col-xs-12">
                                  <span class="usdRate pull-right marginTop1em">All fees shown are in U.S. Dollars.</span>
                                </div>
                              </div>
                              <div class="hireCoachRateSec marginTop1em" *ngIf="rehire == true">
                                <div class="col-lg-12 leftPadding25" *ngIf="singlePackage.length>0">
                                  <h3>Single Sessions</h3>
                                  <ul>
                                    <li class="marginBottom1em marginTop1em" *ngFor="let package of singlePackage; let i=index"><input
                                        type="radio" name="package" [value]="package.PackageRate" [id]='"PackageSecThree" + i'
                                        (click)="setSessionValue(package.PackageRate, package.PackageSession,'single', package.PackageId)">&nbsp;<label
                                        [for]='"PackageSecThree" + i'
                                        (click)="setSessionValue(package.PackageRate, package.PackageSession,'single', package.PackageId)">${{package.PackageRate}}
                                        <span class="lightColorSec">per session</span>
                                      </label>
                                      <div class="hirePackTitle">{{package.PackageTitle}}</div>
                                    </li>
                                  </ul>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 leftPadding25"
                                  *ngIf="multiplePackage.length > 0">
                                  <h3>Packages</h3>
                                  <div class="marginTop1em">
                                    <p class="marginBottom1em marginTop1em" *ngFor="let package of multiplePackage; let i=index">
                                      <span
                                        *ngIf="package.PackageRate  != '' && package.PackageRate  != null && package.PackageRate  != undefined && package.PackageSession  != '' && package.PackageSession  != null && package.PackageSession  != undefined">
                                        <input type="radio" name="package" [value]="package.PackageRate"
                                          [id]='"PackageSecFour" + i'
                                          (click)="setSessionValue(package.PackageRate, package.PackageSession,'package', package.PackageId)">
                                        <label class="noMargin" [for]='"PackageSecFour" + i'
                                          (click)="setSessionValue(package.PackageRate, package.PackageSession,'package', package.PackageId)">${{package.PackageRate}}
                                          <span class="lightColorSec">for</span> {{package.PackageSession}} <span
                                            class="lightColorSec">Session</span></label>
                                        <div class="hirePackTitle">{{package.PackageTitle}}</div>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div *ngIf="(multiplePackage.length == 0 || multiplePackage.length == undefined || multiplePackage.length == null) && (singlePackage.length == 0 || singlePackage.length == undefined || singlePackage.length == null)">
                                  No Packages Yet
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                <div class="col-lg-7 col-xs-12 col-sm-6 noPaddingRight noPaddingLeft noPaddingXs">
                  <div class="panel panel-default fullWidth">
                    <div class="panel-body hirePagePadding">
                      <div class="col-xs-12 noPadding">
                        <h3><span>Coaching Category</span></h3>
                        <div class="col-lg-12 marginTop1em leftPadding25 hireCoachExpertiseSec"
                          *ngIf="profileData.Expertise; else noSkills">
                          <ul class="noMargin" *ngIf="rehire == false">
                            <li *ngFor="let skill of skills" class="hireSkills">
                              <label [for]="skill" (change)='hireCoachSkill(skill)' class="nonBold noMargin"><input
                                  type="checkbox" name="hireCoachSkill" [id]="skill"><span
                                  class="hireSkillName">{{skill}}</span></label>
                            </li>
                          </ul>
                          <ul class="noMargin" *ngIf="rehire == true">
                            <li *ngFor="let skill of rehireSkillsSelected" class="hireSkills">
                              <div *ngIf="skill.selected == 1; else unselected">
                                <label [for]="skill.name" (change)='hireCoachSkill(skill.name)'
                                  class="nonBold noMargin"><input type="checkbox" name="hireCoachSkill"
                                    [id]="skill.name" checked><span class="hireSkillName">{{skill.name}}</span></label>
                              </div>
                              <ng-template #unselected>
                                <label [for]="skill.name" (change)='hireCoachSkill(skill.name)'
                                  class="nonBold noMargin"><input type="checkbox" name="hireCoachSkill"
                                    [id]="skill.name"><span class="hireSkillName">{{skill.name}}</span></label>
                              </ng-template>
                            </li>
                          </ul>
                        </div>
                        <ng-template #noSkills>
                          <div class="col-lg-12 marginTop1em noPadding">
                            None
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default fullWidth col-lg-7 hireCoachDesc">
                  <div class="panel-body hirePagePadding ">
                    <div class="col-lg-12  fullWidth noPadding ">
                      <h3 class="col-lg-12 noPadding"><span>Description</span></h3>
                      <div class="col-lg-12 marginTop1em noPadding">
                        <textarea type="text" rows="8" maxlength="1000"
                          placeholder="Please describe your coaching needs in as much detail as possible"
                          name="categoryDesc" [(ngModel)]="categoryDesc" class="form-control"></textarea>
                      </div>
                      <div class="col-xs-12 marginTop1em noPadding agreeArea">
                        <input type="checkbox" name="iAgree" id="iAgree" value="true" class="pull-left"
                          [(ngModel)]="agree">
                        <label for="iAgree">I agree with the Koachee <a href="https://www.koachee.com/terms-of-service/"
                            target="_blank">Terms of Service</a> and <a href="https://www.koachee.com/privacy-policy/"
                            target="_blank">Privacy Policy</a></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <a class="linkBtn" data-dismiss="modal" (click)="cancelSec()">Cancel</a>&nbsp;&nbsp;
            <button type="submit" class="btn btnBlue pull-right" (click)="hireCoach()">Send Hire Request</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
