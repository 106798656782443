  <div class="modal-dialog contractDetailModalArea">
    <div class="modal-content fullWidth">
      <!-- <div class="modal-header">
        <h4 class="modal-title modalHeader16px">Contact</h4>
      </div> -->
      <div class="modal-body fullWidth">
        <h3 class="defaultHeading"><b>Contract Details</b></h3>
        <button type="submit" (click)="clearData()" class="close" data-dismiss="modal">&times;</button>

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding marginTop2em">
          <div class="panel panel-default marginTop0Imp coachMainInfoSec">
            <div class="panel-body">
              <div class="col-xs-12 col-sm-8">
                <div class="offerProfileImageSec">
                  <div
                    *ngIf="offer.ProfileImageURL != 'NULL' && offer.ProfileImageURL != null && offer.ProfileImageURL != ''; else noImageTemplate">
                    <img src="{{offer.ProfileImageURL}}" alt=""  (error)="genericFunc.errorHandler($event)" class="hirePersonImage">
                  </div>
                  <ng-template #noImageTemplate>
                      <img src="../../../assets/images/female.png" alt="" class="hirePersonImage">
                  </ng-template>
                </div>

                <div class="coachInfo contractModalInfo">
                  <div class="col-lg-12 noPadding">
                    <h4 class="col-lg-12 noPadding">
                      <b>{{offer.OpponentName}}</b></h4>
                  </div>
                  <div class="col-lg-12 noPadding marginTop1em coachInfoDesc"
                    *ngIf="genericFunc.isEmpty(offer.Description) == true">{{offer.Description}}</div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-4">
                <div class="coachInfo proposalStatusInfo">

                  <div class="col-lg-12 noPadding " *ngIf="currentUserType == 'Coach'">
                    <h4 class="col-lg-12 noPadding nocursor orangeHeading"
                      *ngIf="offer.EventName == 'OfferSend' && offer.Rate != 0">
                      <b>New Hire Request</b></h4>
                    <h4 class="col-lg-12 noPadding nocursor orangeHeading"
                      *ngIf="offer.EventName == 'OfferSend' && offer.Rate == 0">
                      <b>Free Consultation Request</b></h4>

                    <h4 class="col-lg-12 noPadding nocursor blueHeading"
                      *ngIf="offer.EventName == 'OfferAccepted' && offer.Rate != 0">
                      <b>Active Contract</b></h4>
                    <h4 class="col-lg-12 noPadding nocursor blueHeading"
                      *ngIf="offer.EventName == 'OfferAccepted' && offer.Rate == 0">
                      <b>Active Free Consultation</b></h4>

                    <h4 class="col-lg-12 noPadding nocursor greyHeading"
                      *ngIf="offer.EventName == 'OfferCompleted' && offer.Rate != 0">
                      <b>Contract Ended</b></h4>
                    <h4 class="col-lg-12 noPadding nocursor greyHeading"
                      *ngIf="offer.EventName == 'OfferFreeCompleted' && offer.Rate == 0">
                      <b>Ended Free Consultation</b></h4>

                    <h4 class="col-lg-12 noPadding nocursor greyHeading"
                      *ngIf="offer.EventName == 'OfferReject' && offer.Rate != 0">
                      <b>Refused</b></h4>
                    <h4 class="col-lg-12 noPadding nocursor greyHeading"
                      *ngIf="offer.EventName == 'OfferReject' && offer.Rate == 0">
                      <b>Refused Free Consultation by coach</b></h4>

                    <h4 class="col-lg-12 noPadding nocursor orangeHeading"
                      *ngIf="offer.EventName == 'OfferPendingCompletion'  && offer.Rate != 0"><b>Awaiting Approval</b></h4>
                    <h4 class="col-lg-12 noPadding nocursor orangeHeading"
                      *ngIf="offer.EventName == 'OfferPendingCompletion'  && offer.Rate == 0"><b>
                        Awaiting Approval Free Consultation</b></h4>

                    <h4 class="col-lg-12 noPadding nocursor greyHeading"
                      *ngIf="offer.EventName == 'OfferWithdrawn' && offer.Rate != 0"><b>
                        Withdrawn by the Coachee</b></h4>
                    <h4 class="col-lg-12 noPadding nocursor greyHeading"
                      *ngIf="offer.EventName == 'OfferWithdrawn' && offer.Rate == 0"><b>
                       Withdrawn Free Consultation by Coachee</b></h4>
                  </div>

                  <div class="col-lg-12 noPadding" *ngIf="currentUserType != 'Coach'">
                    <h4 class="col-lg-12 noPadding nocursor orangeHeading"
                      *ngIf="offer.EventName == 'OfferSend' && offer.Rate != 0">
                      <b>Hire Request Waiting</b></h4>
                    <h4 class="col-lg-12 noPadding nocursor orangeHeading"
                      *ngIf="offer.EventName == 'OfferSend' && offer.Rate == 0">
                      <b>Request Waiting Free Consultation</b></h4>

                    <h4 class="col-lg-12 noPadding nocursor blueHeading"
                      *ngIf="offer.EventName == 'OfferAccepted' && offer.Rate != 0">
                      <b>Active Contract</b>
                    </h4>
                    <h4 class="col-lg-12 noPadding nocursor blueHeading"
                      *ngIf="offer.EventName == 'OfferAccepted' && offer.Rate == 0">
                      <b>Active Free Consultation</b>
                    </h4>
                    <h4 class="col-lg-12 noPadding nocursor greyHeading"
                      *ngIf="offer.EventName == 'OfferCompleted' && offer.Rate != 0">
                      <b>Contract Ended</b></h4>
                    <h4 class="col-lg-12 noPadding nocursor greyHeading"
                      *ngIf="offer.EventName == 'OfferFreeCompleted' && offer.Rate == 0">
                      <b>Ended Free Consultation</b></h4>
                    <h4 class="col-lg-12 noPadding nocursor greyHeading"
                      *ngIf="offer.EventName == 'OfferReject' && offer.Rate != 0">
                      <b>Refused by the Coach</b></h4>
                    <h4 class="col-lg-12 noPadding nocursor greyHeading"
                      *ngIf="offer.EventName == 'OfferReject' && offer.Rate == 0">
                      <b>Refused Free Consultation by Coach</b></h4>
                    <h4 class="col-lg-12 noPadding nocursor orangeHeading"
                      *ngIf="offer.EventName == 'OfferPendingCompletion' && offer.Rate != 0"><b>Awaiting Approval</b></h4>
                    <h4 class="col-lg-12 noPadding nocursor orangeHeading"
                      *ngIf="offer.EventName == 'OfferPendingCompletion' && offer.Rate == 0"><b>Awaiting Approval Free Consultation</b></h4>
                    <h4 class="col-lg-12 noPadding nocursor greyHeading"
                      *ngIf="offer.EventName == 'OfferWithdrawn' && offer.Rate != 0"><b>
                        Withdrawn</b></h4>
                    <h4 class="col-lg-12 noPadding nocursor greyHeading"
                      *ngIf="offer.EventName == 'OfferWithdrawn' && offer.Rate == 0"><b>
                      Withdrawn Free Consultation</b></h4>
                  </div>

                  <div class="col-lg-12 marginTop4px noPadding lightColor">For:
                    {{genericFunc.getUserSkill(offer.CategoryIDs).split(',')}}</div>
                  <div class="col-lg-12 noPadding marginTop4px coachInfoDesc">
                    <span class="lightColor" *ngIf="offer.SessionsIncluded == 1">
                      Rates: <b>${{offer.Rate}}</b> Per Session
                    </span>
                    <span class="lightColor" *ngIf="offer.SessionsIncluded > 1 && offer.SessionsIncluded != null">
                      Rates: <b>${{offer.Rate}}</b> For {{offer.SessionsIncluded}} Sessions
                    </span>
                    <span class="lightColor" *ngIf="(offer.SessionsIncluded == 0 || offer.SessionsIncluded == null)">
                      Rates:
                      <b>$0</b> for <b>1</b> Session
                    </span>

                  </div>
                  <div class="col-lg-12 noPadding marginTop4px coachInfoDate"
                    *ngIf="offer.EventName == 'OfferCompleted'">
                    Started: {{offer.OfferAcceptedAt | date: 'MMM d, y'}} - Ended:
                    {{offer.OfferCompletedAt | date: 'MMM d, y'}}
                  </div>
                  <div class="col-lg-12 noPadding marginTop4px coachInfoDate"
                    *ngIf="offer.EventName == 'OfferFreeCompleted'">
                    Started: {{offer.OfferAcceptedAt | date: 'MMM d, y'}} - Ended:
                    {{offer.OfferCompletedAt | date: 'MMM d, y'}}
                  </div>
                  <div class="col-lg-12 noPadding marginTop4px coachInfoDate" *ngIf="offer.EventName == 'OfferSend'">
                    Date Recieved: {{offer.OfferCreatedAt | date:'MMM d, y'}}
                  </div>
                  <div class="col-lg-12 noPadding marginTop4px coachInfoDate"
                    *ngIf="offer.EventName == 'OfferAccepted'">
                    Started: {{offer.OfferCreatedAt | date:'MMM d, y'}}
                  </div>
                  <div class="col-lg-12 noPadding marginTop4px coachInfoDate" *ngIf="offer.EventName == 'OfferReject'">
                    Refused On: {{offer.OfferCreatedAt | date:'MMM d, y'}}
                  </div>
                  <div class="col-lg-12 noPadding marginTop4px coachInfoDate"
                    *ngIf="offer.EventName == 'OfferWithdrawn'">
                    Withdrawn On: {{offer.OfferCompletedAt | date:'MMM d, y'}}
                  </div>
                  <div class="col-lg-12 noPadding marginTop4px coachInfoDate"
                    *ngIf="offer.EventName == 'OfferPendingCompletion'">
                    Received: {{offer.OfferCreatedAt | date:'MMM d, y'}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 noPaddingLeft noPaddingRightXs">
          <div class="panel panel-default marginTop0Imp coachMainInfoSec contractHistorySec">
            <div class="panel-body">
              <h3 class="fullWidth defaultHeading"><b>History</b></h3>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding">
                <div class="marginTop10px fullWidth">
                  <div class="col-xs-4 noPaddingLeft">
                    <b>Date</b>
                  </div>
                  <div class="col-xs-8 noPaddingRight">
                    <b>Event</b>
                  </div>
                </div>
              </div>
              <div class="historyData">
                <div
                  *ngIf="((dropdown == 'Coach' || dropdown == null || dropdown == undefined || dropdown == '') && (currentUserType == 'Coach')); else coacheeSec">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding"
                    *ngIf="offer.EventName == 'OfferSend' || offer.EventName == 'OfferAccepted' || offer.EventName == 'OfferCompleted' || offer.EventName == 'OfferFreeCompleted'">
                    <div class="marginTop10px fullWidth" *ngIf="genericFunc.isEmpty(offer.OfferCompletedAt) == true">
                      <div class="col-xs-4 noPaddingLeft">
                        {{offer.OfferCompletedAt | date:'MMM d, y'}}
                      </div>
                      <div class="col-xs-8 noPaddingRight">
                        <span *ngIf="currentUserId != offer.EndContractInitiator; else endedSec1">
                          {{currentUserName}} ended the contract
                        </span>
                        <ng-template #endedSec1>
                          {{offer.OpponentName}} ended the contract
                        </ng-template>
                      </div>
                    </div>
                    <div class="marginTop10px fullWidth" *ngIf="genericFunc.isEmpty(offer.OfferInitiatedAt) == true">
                      <div class="col-xs-4 noPaddingLeft">
                        {{offer.OfferInitiatedAt | date:'MMM d, y'}}
                      </div>
                      <div class="col-xs-8 noPaddingRight">
                        <span *ngIf="currentUserId != offer.EndContractInitiator; else endedSec1">
                          {{offer.OpponentName}} initiated the end contract
                        </span>
                        <ng-template #endedSec1>
                          {{currentUserName}} initiated the end contract
                        </ng-template>
                      </div>
                    </div>
                    <div class="marginTop10px fullWidth" *ngIf="genericFunc.isEmpty(offer.OfferAcceptedAt) == true">
                      <div class="col-xs-4 noPaddingLeft">
                        {{offer.OfferAcceptedAt | date:'MMM d, y'}}
                      </div>
                      <div class="col-xs-8 noPaddingRight">
                        <span *ngIf="currentUserType != 'Coach'; else acceptedSec1">
                          {{offer.OpponentName}} accepted the <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                          <ng-template #hireText>hire request</ng-template>
                        </span>
                        <ng-template #acceptedSec1>
                          {{currentUserName}} accepted the <span *ngIf="offer.Rate == 0
                          ; else hireText">free consultation</span>
                          <ng-template #hireText>hire request</ng-template>
                        </ng-template>
                      </div>
                    </div>
                    <div class="marginTop10px fullWidth" *ngIf="genericFunc.isEmpty(offer.OfferCreatedAt) == true">
                      <div class="col-xs-4 noPaddingLeft">
                        {{offer.OfferCreatedAt | date:'MMM d, y'}}
                      </div>
                      <div class="col-xs-8 noPaddingRight">
                        <span *ngIf="currentUserType == 'Coach'; else hireReqSec1">
                          {{offer.OpponentName}} sent a <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                          <ng-template #hireText>hire request</ng-template>
                        </span>
                        <ng-template #hireReqSec1>
                          {{currentUserName}} sent a <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                          <ng-template #hireText>hire request</ng-template>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding"
                    *ngIf="offer.EventName == 'OfferPendingCompletion'">
                    <div class="marginTop10px fullWidth" *ngIf="genericFunc.isEmpty(offer.OfferInitiatedAt) == true">
                      <div class="col-xs-4 noPaddingLeft">
                        {{offer.OfferInitiatedAt | date:'MMM d, y'}}
                      </div>
                      <div class="col-xs-8 noPaddingRight">
                        <span *ngIf="currentUserId != offer.EndContractInitiator; else endedSec1">
                          {{offer.OpponentName}} initiated the end contract
                        </span>
                        <ng-template #endedSec1>
                          {{currentUserName}} initiated the end contract
                        </ng-template>
                      </div>
                    </div>
                    <div class="marginTop10px fullWidth" *ngIf="genericFunc.isEmpty(offer.OfferAcceptedAt) == true">
                      <div class="col-xs-4 noPaddingLeft">
                        {{offer.OfferAcceptedAt | date:'MMM d, y'}}
                      </div>
                      <div class="col-xs-8 noPaddingRight">
                        <span *ngIf="currentUserType != 'Coach'; else acceptedSec1">
                          {{offer.OpponentName}} accepted the <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                          <ng-template #hireText>hire request</ng-template>
                        </span>
                        <ng-template #acceptedSec1>
                          {{currentUserName}} accepted the <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                          <ng-template #hireText>hire request</ng-template>
                        </ng-template>
                      </div>
                    </div>
                    <div class="marginTop10px fullWidth" *ngIf="genericFunc.isEmpty(offer.OfferCreatedAt) == true">
                      <div class="col-xs-4 noPaddingLeft">
                        {{offer.OfferCreatedAt | date:'MMM d, y'}}
                      </div>
                      <div class="col-xs-8 noPaddingRight">
                        <span *ngIf="currentUserType == 'Coach'; else hireReqSec1">
                          {{offer.OpponentName}} sent a <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                          <ng-template #hireText>hire request</ng-template>
                        </span>
                        <ng-template #hireReqSec1>
                          {{currentUserName}} sent a <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                          <ng-template #hireText>hire request</ng-template>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding"
                    *ngIf="offer.EventName == 'OfferReject'">
                    <div class="marginTop10px fullWidth" *ngIf="genericFunc.isEmpty(offer.OfferRejectedAt) == true">
                      <div class="col-xs-4 noPaddingLeft">
                        {{offer.OfferRejectedAt | date:'MMM d, y'}}
                      </div>
                      <div class="col-xs-8 noPaddingRight">
                        <span *ngIf="currentUserType != 'Coach'; else refuse1">
                          {{offer.OpponentName}} refused the <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                          <ng-template #hireText>hire request</ng-template>
                        </span>
                        <ng-template #refuse1>
                          {{currentUserName}} refused the <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                          <ng-template #hireText>hire request</ng-template>
                        </ng-template>
                      </div>
                    </div>
                    <div class="marginTop10px fullWidth" *ngIf="genericFunc.isEmpty(offer.OfferCreatedAt) == true">
                      <div class="col-xs-4 noPaddingLeft">
                        {{offer.OfferCreatedAt | date:'MMM d, y'}}
                      </div>
                      <div class="col-xs-8 noPaddingRight">
                        <span *ngIf="currentUserType == 'Coach'; else hireReq2">
                          {{offer.OpponentName}} sent a <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                          <ng-template #hireText>hire request</ng-template>
                        </span>
                        <ng-template #hireReq2>
                          {{currentUserName}} sent a <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                          <ng-template #hireText>hire request</ng-template>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding"
                    *ngIf="offer.EventName == 'OfferWithdrawn'">
                    <div class="marginTop10px fullWidth" *ngIf="genericFunc.isEmpty(offer.OfferCompletedAt) == true">
                      <div class="col-xs-4 noPaddingLeft">
                        {{offer.OfferCompletedAt | date:'MMM d, y'}}
                      </div>
                      <div class="col-xs-8 noPaddingRight">
                        <span *ngIf="currentUserType == 'Coach'; else withdrawReq1">
                          {{offer.OpponentName}} withdrawn the <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                          <ng-template #hireText>hire request</ng-template>
                        </span>
                        <ng-template #withdrawReq1>
                          {{currentUserName}} withdrawn the <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                          <ng-template #hireText>hire request</ng-template>
                        </ng-template>
                      </div>
                    </div>
                    <div class="marginTop10px fullWidth" *ngIf="genericFunc.isEmpty(offer.OfferCreatedAt) == true">
                      <div class="col-xs-4 noPaddingLeft">
                        {{offer.OfferCreatedAt | date:'MMM d, y'}}
                      </div>
                      <div class="col-xs-8 noPaddingRight">
                        <span *ngIf="currentUserType == 'Coach'; else hireReq3">
                          {{offer.OpponentName}} sent a <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                          <ng-template #hireText>hire request</ng-template>
                        </span>
                        <ng-template #hireReq3>
                          {{currentUserName}} sent a <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                          <ng-template #hireText>hire request</ng-template>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>

                <ng-template #coacheeSec>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding"
                    *ngIf="offer.EventName == 'OfferSend' || offer.EventName == 'OfferAccepted' || offer.EventName == 'OfferCompleted'|| offer.EventName == 'OfferFreeCompleted'">
                    <div class="marginTop10px fullWidth" *ngIf="genericFunc.isEmpty(offer.OfferCompletedAt) == true">
                      <div class="col-xs-4 noPaddingLeft">
                        {{offer.OfferCompletedAt | date:'MMM d, y'}}
                      </div>
                      <div class="col-xs-8 noPaddingRight">
                        <span *ngIf="currentUserId != offer.EndContractInitiator; else endedSec1">
                          {{currentUserName}} ended the contract
                        </span>
                        <ng-template #endedSec1>
                          {{offer.OpponentName}} ended the contract
                        </ng-template>
                      </div>
                    </div>
                    <div class="marginTop10px fullWidth" *ngIf="genericFunc.isEmpty(offer.OfferInitiatedAt) == true">
                      <div class="col-xs-4 noPaddingLeft">
                        {{offer.OfferInitiatedAt | date:'MMM d, y'}}
                      </div>
                      <div class="col-xs-8 noPaddingRight">
                        <span *ngIf="currentUserId != offer.EndContractInitiator; else endedSec1">
                          {{offer.OpponentName}} initiated the end contract
                        </span>
                        <ng-template #endedSec1>
                          {{currentUserName}} initiated the end contract
                        </ng-template>
                      </div>
                    </div>
                    <div class="marginTop10px fullWidth" *ngIf="genericFunc.isEmpty(offer.OfferAcceptedAt) == true">
                      <div class="col-xs-4 noPaddingLeft">
                        {{offer.OfferAcceptedAt | date:'MMM d, y'}}
                      </div>
                      <div class="col-xs-8 noPaddingRight">
                        <span
                          *ngIf="((currentUserType == 'Coachee') || ((currentUserType == 'Coach') || (dropdown == 'Coachee'))); else acceptedSec1">
                          {{offer.OpponentName}} accepted the <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                          <ng-template #hireText>hire request</ng-template>
                        </span>
                        <ng-template #acceptedSec1>
                          {{currentUserName}} accepted the <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                          <ng-template #hireText>hire request</ng-template>
                        </ng-template>
                      </div>
                    </div>
                    <div class="marginTop10px fullWidth" *ngIf="genericFunc.isEmpty(offer.OfferCreatedAt) == true">
                      <div class="col-xs-4 noPaddingLeft">
                        {{offer.OfferCreatedAt | date:'MMM d, y'}}
                      </div>
                      <div class="col-xs-8 noPaddingRight">
                        <span
                          *ngIf="((currentUserType == 'Coachee') || ((currentUserType == 'Coach') || (dropdown == 'Coachee'))); else hireReqSec1">
                          {{currentUserName}} sent a <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                          <ng-template #hireText>hire request</ng-template>
                        </span>
                        <ng-template #hireReqSec1>
                          {{offer.OpponentName}} sent a <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                          <ng-template #hireText>hire request</ng-template>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding"
                    *ngIf="offer.EventName == 'OfferPendingCompletion'">
                    <div class="marginTop10px fullWidth" *ngIf="genericFunc.isEmpty(offer.OfferInitiatedAt) == true">
                      <div class="col-xs-4 noPaddingLeft">
                        {{offer.OfferInitiatedAt | date:'MMM d, y'}}
                      </div>
                      <div class="col-xs-8 noPaddingRight">
                        <span *ngIf="currentUserId != offer.EndContractInitiator; else endedSec1">
                          {{offer.OpponentName}} initiated the end contract
                        </span>
                        <ng-template #endedSec1>
                          {{currentUserName}} initiated the end contract
                        </ng-template>
                      </div>
                    </div>
                    <div class="marginTop10px fullWidth" *ngIf="genericFunc.isEmpty(offer.OfferAcceptedAt) == true">
                      <div class="col-xs-4 noPaddingLeft">
                        {{offer.OfferAcceptedAt | date:'MMM d, y'}}
                      </div>
                      <div class="col-xs-8 noPaddingRight">
                        <span
                          *ngIf="((currentUserType == 'Coachee') || ((currentUserType == 'Coach') || (dropdown == 'Coachee'))); else acceptedSec1">
                          {{offer.OpponentName}} accepted the <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                          <ng-template #hireText>hire request</ng-template>
                        </span>
                        <ng-template #acceptedSec1>
                          {{currentUserName}} accepted the <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                          <ng-template #hireText>hire request</ng-template>
                        </ng-template>
                      </div>
                    </div>
                    <div class="marginTop10px fullWidth" *ngIf="genericFunc.isEmpty(offer.OfferCreatedAt) == true">
                      <div class="col-xs-4 noPaddingLeft">
                        {{offer.OfferCreatedAt | date:'MMM d, y'}}
                      </div>
                      <div class="col-xs-8 noPaddingRight">
                        <span *ngIf="((currentUserType == 'Coach') && (dropdown == 'Coach')); else hireReqSec1">
                          {{offer.OpponentName}} sent a <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                          <ng-template #hireText>hire request</ng-template>
                        </span>
                        <ng-template #hireReqSec1>
                          {{currentUserName}} sent a <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                          <ng-template #hireText>hire request</ng-template>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding"
                    *ngIf="offer.EventName == 'OfferReject'">
                    <div class="marginTop10px fullWidth" *ngIf="genericFunc.isEmpty(offer.OfferRejectedAt) == true">
                      <div class="col-xs-4 noPaddingLeft">
                        {{offer.OfferRejectedAt | date:'MMM d, y'}}
                      </div>
                      <div class="col-xs-8 noPaddingRight">
                        {{offer.OpponentName}} refused the <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                        <ng-template #hireText>hire request</ng-template>
                      </div>
                    </div>
                    <div class="marginTop10px fullWidth" *ngIf="genericFunc.isEmpty(offer.OfferCreatedAt) == true">
                      <div class="col-xs-4 noPaddingLeft">
                        {{offer.OfferCreatedAt | date:'MMM d, y'}}
                      </div>
                      <div class="col-xs-8 noPaddingRight">
                        {{currentUserName}} sent a <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                        <ng-template #hireText>hire request</ng-template>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding"
                    *ngIf="offer.EventName == 'OfferWithdrawn'">
                    <div class="marginTop10px fullWidth" *ngIf="genericFunc.isEmpty(offer.OfferCompletedAt) == true">
                      <div class="col-xs-4 noPaddingLeft">
                        {{offer.OfferCompletedAt | date:'MMM d, y'}}
                      </div>
                      <div class="col-xs-8 noPaddingRight">
                        {{currentUserName}} withdrawn the <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                        <ng-template #hireText>hire request</ng-template>
                      </div>
                    </div>
                    <div class="marginTop10px fullWidth" *ngIf="genericFunc.isEmpty(offer.OfferCreatedAt) == true">
                      <div class="col-xs-4 noPaddingLeft">
                        {{offer.OfferCreatedAt | date:'MMM d, y'}}
                      </div>
                      <div class="col-xs-8 noPaddingRight">
                        {{currentUserName}} sent a <span *ngIf="offer.Rate == 0; else hireText">free consultation</span>
                        <ng-template #hireText>hire request</ng-template>
                      </div>
                    </div>
                  </div>
                </ng-template>

              </div>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 noPaddingRight noPaddingLeftXs">
          <div class="panel panel-default marginTop0Imp coachMainInfoSec contractPaymentSec">
            <div class="panel-body">
              <h3 class="fullWidth defaultHeading"><b>Payments</b></h3>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding">
                <div class="marginTop10px fullWidth">
                  <div class="col-xs-4 noPaddingLeft">
                    <b>Date</b>
                  </div>
                  <div class="col-xs-4 noPadding">
                    <b>Invoice / Payment</b>
                  </div>
                  <div class="col-xs-4 noPaddingRight">
                    <b>Amount</b>
                  </div>
                </div>
              </div>

              <div class="historyData" *ngIf="shareUserData.contractPaymentHistory.length > 0">
                <div *ngFor="let payment of shareUserData.contractPaymentHistory">
                  <div *ngIf="payment.status == 'paid'; else onlyInvoice">
                    <div class="marginTop10px fullWidth" (click)="contractOutputFunc(payment.invoiceID)">
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding">
                        <div class="col-xs-4 noPaddingLeft">
                          {{payment.datePaid | date:'MMM d, y'}}
                        </div>
                        <div class="col-xs-4 noPadding">
                          Payment
                        </div>
                        <div class="col-xs-4 noPaddingRight">
                          ${{payment.amount}}
                        </div>
                      </div>
                    </div>
                    <div class="marginTop10px fullWidth" (click)="contractOutputFunc(payment.invoiceID)">
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding">
                        <div class="col-xs-4 noPaddingLeft showCursor">
                          {{payment.dateCreated | date:'MMM d, y'}}
                        </div>
                        <div class="col-xs-4 noPadding showCursor">
                          Invoice
                        </div>
                        <div class="col-xs-4 noPaddingRight showCursor">
                          ${{payment.amount}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <ng-template #onlyInvoice>
                    <div class="marginTop10px fullWidth" (click)="contractOutputFunc(payment.invoiceID)">
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 noPadding">
                        <div class="col-xs-4 noPaddingLeft showCursor">
                          {{payment.dateCreated | date:'MMM d, y'}}
                        </div>
                        <div class="col-xs-4 noPadding showCursor">
                          Invoice
                        </div>
                        <div class="col-xs-4 noPaddingRight showCursor">
                          ${{payment.amount}}
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer fullWidth optionsSetting">
        <a class="btn blueBtn equalSizeBtn orangeBtn noMargin" (click)="clearData()" data-dismiss="modal">Close</a>
      </div>
    </div>
  </div>