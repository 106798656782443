import { Component, Inject, Input, OnInit, Output, PLATFORM_ID, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Constants } from '../../consts';
import { DatePipe, isPlatformBrowser } from '@angular/common';
import * as moment from 'moment';
import { Router } from '@angular/router';
import 'moment-timezone';
import { ToasterService } from '../../services/toaster/toaster.service';
import { SchedulerService } from '../../services/scheduler/scheduler.service';
import { MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { SchedulerModal } from './scheduler.model';
import { GenericFunctionsComponent } from '../../providers/generic-functions/generic-functions';
@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.css'],
  providers: [SchedulerModal, SchedulerService]
})
export class SchedulerComponent implements OnInit {
  @Input() userId: number;
  @Input() packageId: number;
  @Output() modalClose: EventEmitter<boolean> = new EventEmitter();
  public loading = false;

  constructor(
    public schedulerService: SchedulerService,
    public _router: Router,
    private genericFunc: GenericFunctionsComponent,
    public reload: ChangeDetectorRef,
    public currentModel: SchedulerModal,
    private toast: ToasterService,
    public datepipe: DatePipe,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit(): void {

    if (isPlatformBrowser(this.platformId)) {
      this.currentModel.timeZoneList = moment.tz.names();
      this.currentModel.selectedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.getSchedule()
    }
  }

  closeeModal() {
    // this.showConcurrencyModal == false
    this.modalClose.emit(this.currentModel.modal);
  }
  selectedDate = new Date();
  startAt = this.selectedDate;
  minDate = this.selectedDate;
  maxDate = new Date(new Date().setMonth(new Date().getMonth() + 6));

  onSelect(selectedDate: Date) {
    this.selectedDate = selectedDate;
    let dateSelected = this.datepipe.transform(this.selectedDate, 'yyyy-MM-dd')
    this.currentModel.timings = [];
    this.loading = true;
    let data = {
      "CoachId": this.userId,
      "StartDate": this.genericFunc.selectedTimezoneToUtcWithDate(dateSelected, this.currentModel.selectedTimeZone),
      "selectedDayId": this.getDayIdFunc(dateSelected),
      "currentDate": dateSelected,
      "PackageID": this.packageId,
    }
    this.currentModel.getSchedulerAPI = this.schedulerService.getScheduleByDate(data).subscribe((jsonData: any) => {
      this.loading = false;
      if (jsonData) {
        if (jsonData.response) {
          let timing = '';
          timing = jsonData.response.data;
          this.currentModel.timings = timing;
          this.currentModel.coachTimeZone = jsonData.response.coachTimeZone
        }
      }
    }, (error: any) => {
      this.loading = false;
      this.toast.error(error, Constants.errorTitle);
    })
    this.currentModel.fullScheduleData.forEach((element: any) => {
      if ((selectedDate.getDay() == 0) && (element.dayId == 6)) {
        this.currentModel.selectedElement = '';
        this.currentModel.selectedElement = element
        let duration = parseInt(this.currentModel.durationHours) * 60 + parseInt(this.currentModel.durationMinutes)
        this.intervalTime(element.startTime, element.endTime, duration)
      }
      else {
        if (element.dayId === (selectedDate.getDay() - 1)) {
          this.currentModel.selectedElement = '';
          this.currentModel.selectedElement = element
          let duration = parseInt(this.currentModel.durationHours) * 60 + parseInt(this.currentModel.durationMinutes)
          this.intervalTime(element.startTime, element.endTime, duration)
        }
      }
    });
    this.currentModel.timebtn = true
  }


  getTimeZone(timeZone: any) {
    this.onSelect(this.selectedDate)
    // this.currentModel.timings.find((item: any) => {
    //   item.time = this.gettimedetail(item.time, timeZone)
    // })
    // this.currentModel.selectedElement
    // let duration = parseInt(this.currentModel.durationHours) * 60 + parseInt(this.currentModel.durationMinutes)
    // this.intervalTime(this.currentModel.selectedElement.startTime, this.currentModel.selectedElement.endTime, duration)
  }

  gettimedetail(time: any, timezone: any) {
    return new Date('January 01, 2015' + ' ' + time + ' ' + 'GMT+00:00').toLocaleTimeString("en-US", { timeZone: timezone, hour: '2-digit', minute: '2-digit' })
  }

  confirmTime(index: number) {
    this.currentModel.currentIndex = index
    this.currentModel.timings[index].booked = true;
  }

  cancelTime(index: number) {
    this.currentModel.timings[index].booked = false;
    this.currentModel.selectDateTimeModal = true;
  }

  closeModal() {
    this.currentModel.selectDateTimeModal = false;

  }
  sessionPage() {
    this._router.navigateByUrl(Constants.appointmentsPath)
  }

  showConfirmBox(time: any, index: number) {
    this.currentModel.showConfirmModal = true;
    this.currentModel.selectedTime = this.genericFunc.utcToSelectedTimezoneForBackend(time, this.currentModel.selectedTimeZone);
    this.currentModel.selectedIndex = index;
    this.currentModel.coachScheduleDate = this.genericFunc.currentUtcToSelectedTimezone(time, this.currentModel.coachTimeZone);
    this.currentModel.coacheeScheduleDate = this.genericFunc.currentUtcToSelectedTimezone(time, this.currentModel.selectedTimeZone);
  }

  closeConfirmBox() {
    this.currentModel.showConfirmModal = false;
    this.currentModel.selectedTime = '';
  }

  bookScheduling() {
    let scheduledDate = this.datepipe.transform(this.selectedDate, 'yyyy-MM-dd') + ' ' + this.currentModel.selectedTime
    let finalDate = this.genericFunc.selectedTimezoneToUtcWithDate(scheduledDate, this.currentModel.selectedTimeZone)

    let data = {
      "CoachId": this.userId,
      "ScheduledDate": finalDate,
      "Duration": this.currentModel.durationHours + ':' + this.currentModel.durationMinutes,
      "CoacheeFullName": this.genericFunc.getLoggedInUserName(),
      "CoacheeEmail": this.genericFunc.getLoggedInEmailID(),
      "coachScheduleDate": this.currentModel.coachScheduleDate,
      "coacheeScheduleDate": this.currentModel.coacheeScheduleDate,
    }
    
    this.loading = true;
    this.currentModel.getSchedulerAPI = this.schedulerService.insertSchedule(data).subscribe((jsonData: any) => {
      if (jsonData) {
        if (jsonData.status) {
          if (jsonData.response) {
            this.loading = false;
            this.currentModel.showConfirmModal = false;
            this.cancelTime(this.currentModel.selectedIndex)
            this.onSelect(this.selectedDate)
            this.closeeModal()
            this.closeModal()
            this.sessionPage()
            this.toast.success(jsonData.response.data)
            if (!(this.reload as any).destroyed) {
              this.reload.detectChanges();
            }
          }
        }
      }
    }, (error: any) => {
      this.loading = false;
      this.toast.error(error, Constants.errorTitle);
    });
  }

  dateTimeConfirm() {
    this.currentModel.selectDateTimeModal = false
    this.currentModel.timings[this.currentModel.currentIndex].dateTimeDisable = true
  }

  dateClass() {
    return (date: Date): MatCalendarCellCssClasses => {
      const highlightDate = this.currentModel.datesToHighlight
        .map((strDate: any) => strDate)
        .some((d: any) => d === date.getDay());

      return highlightDate ? 'highlight-dates' : 'highlight-dates';
    };
  }

  intervalTime(starttime: any, endtime: any, interval: any) {
    this.currentModel.timings = [];
    let i = 0
    while (starttime <= endtime) {
      if((starttime <= endtime) && (i !== 0)) {
        break;
      }
      let obj;
      let newTime;
      if (i == 0) {
        newTime = this.seprateTime(starttime, 0)
      } else {
        newTime = this.seprateTime(starttime, interval)
      }

      if (Date.parse('01/01/2015 ' + newTime + ':00') >= Date.parse('01/01/2015 ' + endtime + ':00')) {
        starttime = newTime
      } else {
        obj = { id: 0, time: starttime = newTime, booked: false, dateTimeDisable: false }
        this.currentModel.timings.push(obj);
      }

      i++
    }
  }

  seprateTime(time: any, minute: any) {
    var date = new Date(new Date('01/01/2015' + ' ' + time).getTime() + minute * 60000);
    var tempTime = ((date.getHours().toString().length == 1) ? '0' + date.getHours() : date.getHours()) + ':' +
      ((date.getMinutes().toString().length == 1) ? '0' + date.getMinutes() : date.getMinutes());
    return tempTime
  }

  demo() { }

  getSchedule() {
    let data = {
      "UserID": this.userId,
      "TimeZone": this.currentModel.selectedTimeZone,
      "PackageID": this.packageId,
    }
    this.currentModel.loadCalendar = false;
    this.currentModel.getSchedulerAPI = this.schedulerService.getScheduler(data).subscribe((jsonData: any) => {
      if (jsonData) {
        if (jsonData.status) {
          if (jsonData.response) {
            this.currentModel.fullScheduleData = [];
            let schedulerData = jsonData.response.data;
            this.currentModel.fullScheduleData = schedulerData;
            if (schedulerData.length > 0) {
              let tduration = schedulerData[0].Duration.split(':')
              this.currentModel.durationHours = tduration[0]
              this.currentModel.durationMinutes = tduration[1]
            }
            this.currentModel.datesToHighlight = [];
            schedulerData.forEach((element: any) => {
              if (element.status) {
                this.currentModel.datesToHighlight.push(element.dayId)
              }
            });
            this.dateClass()
            this.currentModel.loadCalendar = true;
            this.onSelect(new Date())
            if (!(this.reload as any).destroyed) {
              this.reload.detectChanges();
            }
          }
        }
      }
    }, (error: any) => {
      this.toast.error(error, Constants.errorTitle);
    });
  }

  showConvertedTime(timings: any) {
    return this.genericFunc.utcToSelectedTimezoneFrontend(timings, this.currentModel.selectedTimeZone);
  }

  getDayIdFunc(selectedDate: any) {
    const id = parseInt(moment.utc(selectedDate).format('d'));
    if (id === 0) {
      return 6
    } else {
      return (id - 1)
    }
  }
}
