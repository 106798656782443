import { Injectable } from '@angular/core';
import { ApiManagerComponent } from '../../../providers/api-manager/api-manager';
import { Observable } from 'rxjs';
import { Constants } from '../../../consts';
import { map } from "rxjs/operators";

@Injectable()
export class FollowProfileService {

  constructor(
    private apiManager: ApiManagerComponent,
  ) { }

  isFollow(data: any): Observable<any> {
    return this.apiManager.postMethod(Constants.isFollow, false, data).pipe(map((apiResponse) => {
      return apiResponse
    }));
  }

}
