import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { faMicrophoneAlt, faMicrophoneAltSlash, faVideo, faVideoSlash, faCog } from '@fortawesome/free-solid-svg-icons'
import { MediaService, MediaStreamType } from '../../../services/meeting/media.service';
import { ShareDataProviderComponent } from '../../../providers/share-data-provider/share-data-provider.component';

@Component({
  selector: 'app-camera-preview',
  templateUrl: './camera-preview.component.html',
  styleUrls: ['./camera-preview.component.css']
})
export class CameraPreviewComponent implements AfterViewInit, OnDestroy {

  @ViewChild('camera') cameraElementRef!: ElementRef;
  @Input() showSettingsPopup = false;
  private subscriptions: Subscription[] = [];
  private stream?: MediaStream;
  public microphoneIcon = faMicrophoneAlt;
  public microphoneMutedIcon = faMicrophoneAltSlash;
  public cameraIcon = faVideo;
  public cameraOffIcon = faVideoSlash;
  public settingIcon = faCog;
  public isCameraOff = false;
  public isMicrophoneMute = true;
  public selectedVideoInId?: string;
  showSettings = false;
  isBrowser: any;

  constructor(
    private mediaService: MediaService,
		public sharedData: ShareDataProviderComponent,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (isPlatformBrowser(this.platformId)) {
      this.sharedData.audioUpdate = this.isMicrophoneMute;
      this.sharedData.videoUpdate = this.isCameraOff;
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const vsubscription = this.mediaService.selectedVideoInputId.subscribe(id => {
        this.selectedVideoInId = id;
        this.startCameraMic(this.selectedVideoInId);
      });
      this.subscriptions.push(vsubscription);
    }
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    if (this.stream) {
      this.stream.getTracks().forEach(track => {
        track.stop();
      });
    }
  }

  async startCameraMic(camDeviceId?: string) {
    if (this.isCameraOff) {
      if (this.stream) {
        this.stream.getTracks().forEach(track => {
          track.stop();
        });
      }
      return;
    }
    const mediaStreamType = MediaStreamType.video;
    this.stream = await this.mediaService.getMediaStream(mediaStreamType, undefined, undefined, camDeviceId);

    this.cameraElementRef.nativeElement.srcObject = this.stream;
    this.cameraElementRef.nativeElement.onloadedmetadata = () => {
      this.cameraElementRef.nativeElement.play();
    };
  }

  onMicrophoneClicked(state: boolean) {
    this.isMicrophoneMute = state;
    this.sharedData.audioUpdate = this.isMicrophoneMute;
  }

  onCameraClicked(state: boolean) {
    this.isCameraOff = state;
    this.sharedData.videoUpdate = this.isCameraOff;
    this.startCameraMic(this.selectedVideoInId);
  }

  onShowSettings() {
    this.showSettings = true;
  }

  onCloseSettings() {
    this.showSettings = false;
  }

}
