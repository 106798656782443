import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'FirstLettersofName'
})

export class FirstLettersofName implements PipeTransform {
    transform(value: string): string {
        return value.split(' ').slice(0,2).map((n: any) => n[0]).join('');
    }
}