import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef, OnChanges, Output, EventEmitter, AfterContentChecked, ɵConsole } from '@angular/core';
import { Constants } from '../../../consts';
import { Router } from '@angular/router';
import { ShareDataProviderComponent } from '../../../providers/share-data-provider/share-data-provider.component';
import { GenericFunctionsComponent } from '../../../providers/generic-functions/generic-functions';
import { ApiManagerComponent } from '../../../providers/api-manager/api-manager';
import { ToasterService } from '../../../services/toaster/toaster.service';
import { HomeComponent } from '../../../../app/pages/search/home/home.component';
import { FollowProfileService } from '../../../services/shared-services/follow-profile/follow-profile.service';
import { inherits } from 'util';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-show-current-card',
  templateUrl: './show-current-card.component.html',
  styleUrls: ['./show-current-card.component.css'],
  providers: [FollowProfileService,]
})
export class ShowCurrentCardComponent implements OnChanges, OnInit {

  @Input() currentCoachData: any;
  @Input() loggedInUserData: any;
  @Output() notifyhome: EventEmitter<string> = new EventEmitter<string>();

  // @Input() showSkills:any;
  public currentUserID: any;
  public currentType: any;
  public IsUserLoggedIn: any = false;
  public isFollow: boolean = false;
  public loadingExt = false;
  public enableBtn: boolean = true;
  public openHireModal: boolean = false
  public showAlreadyHire: boolean = true
  public popUpId: any = '';
  public hireButtonVisibility: boolean = false;
  public isUserVerified: any;
  public getPackageDataAPI: any;
  public isFollowAPI: any;

  constructor(
    private _router: Router,
    private apiManager: ApiManagerComponent,
    public reload: ChangeDetectorRef,
    private toast: ToasterService,
    public genericFunc: GenericFunctionsComponent,
    public shareUserData: ShareDataProviderComponent,
    public homeCom: HomeComponent,
    public FollowProfileService: FollowProfileService,
  ) {
    this.currentUserID = this.genericFunc.getLoggedInUserID();
    this.currentType = this.genericFunc.getLoggedInUserType();
    this.isUserVerified = this.genericFunc.getIsverifiedEmail();
  }
  ngOnInit() {
  }

  ngOnChanges() {

    this.IsUserLoggedIn = this.genericFunc.isUserLoggedIn();
  }

  alreadyHired() {
    if (((this.currentCoachData.ContractsID != null) && (this.currentCoachData.ContractsID != '') && (this.currentCoachData.ContractsID != undefined))) {
      return true
    }
    return false
  }

  noRates() {
    this.reload.detectChanges()
    if ((this.currentCoachData.FreeConsultStatus === 1 && this.currentCoachData.singlePackage === 0 && this.currentCoachData.multiplePackage === 0 && this.currentCoachData.FreeConsultAlreadyTaken == 1)) {
      return true
    }
    else if ((this.currentCoachData.FreeConsultStatus === 0 && this.currentCoachData.singlePackage === 0 && this.currentCoachData.multiplePackage === 0 && this.currentCoachData.FreeConsultAlreadyTaken == 1)) {
      return true
    } else if ((this.currentCoachData.FreeConsultStatus === 0 && this.currentCoachData.singlePackage === 0 && this.currentCoachData.multiplePackage === 0 && this.currentCoachData.FreeConsultAlreadyTaken == 0)) {
      return true
    }
    else {
      return false
    }
  }

  noVerified() {
    if (this.currentCoachData.StatusID != 1) {
      return "opponent"
    } else if (this.isUserVerified != 1) {
      return "me"
    }
    return "verified"
  }

  freeDemoModel(type: any) {
    if (type == 'free') {
      this.genericFunc.amplitudeFunc(Constants.requestFreeConsultationEvent)
    } else {
      this.genericFunc.amplitudeFunc(Constants.hireCoachButtonEvent)
    }
    if (this.alreadyHired() == true) {
      this.genericFunc.amplitudeFunc(Constants.alreadyHiredPopUpEvent)
      this.popUpId = 'hireCoachAlert'
      this.hireButtonVisibility = true
      return
    }
    else if (this.noRates() == true) {
      this.genericFunc.amplitudeFunc(Constants.noRatesPublishedPopUpEvent)
      this.popUpId = 'zeroRateDialog'
      this.hireButtonVisibility = true
      return
    }
    else if (this.noVerified() == "opponent") {
      this.genericFunc.amplitudeFunc(Constants.EmailVerificationAlertEvent)

      this.popUpId = 'CoachEmailVerifAlert'
      this.hireButtonVisibility = true
      return
    }
    else if (this.noVerified() == "me") {
      this.genericFunc.amplitudeFunc(Constants.EmailVerificationAlertEvent)
      this.popUpId = 'hireCoachEmailVerifyAlert'
      this.hireButtonVisibility = true
      return
    }

    this.hireButtonVisibility = false
    this.shareUserData.seperateData = []
    this.shareUserData.singlePackage = []
    this.shareUserData.multiplePackage = []
    this.shareUserData.freeConsultData = []
    let data = {
      "currentUserId": this.currentCoachData.ID
    }
    this.loadingExt = true
    this.getPackageDataAPI = this.apiManager.postMethod(Constants.getPackageData, false, data).subscribe((jsonData) => {
      this.loadingExt = false
      if (jsonData.response.data.length > 0) {
        this.shareUserData.seperateData = this.genericFunc.seperatePackageData(jsonData.response.data)
        this.shareUserData.singlePackage = this.shareUserData.seperateData[0]
        this.shareUserData.multiplePackage = this.shareUserData.seperateData[1]
        this.shareUserData.freeConsultData = this.shareUserData.seperateData[2]
      }

      if (this.currentCoachData.ContractsID == null) {
        this.showAlreadyHire = false
      }
      this.openHireModal = true
      this.genericFunc.amplitudeFunc(Constants.hireModalEvent)
      this.popUpId = ''
      // this.currentCoachData.ContractsID = ''

      this.reload.detectChanges()
    })
  }

  closeBtn(val: any) {
    this.openHireModal = false
    this.popUpId = ''
    this.hireButtonVisibility = false
    // this.currentCoachData.ContractsID = val.ContractsID
  }

  // selectHirePopUp() {
  //   if (this.genericFunc.isUserLoggedIn()) {
  //     switch (this.selectHireCategory()) {
  //       case 1: this.popUpId = ''; break;
  //       case 2: this.popUpId = 'CoachEmailVerifAlert'; break;
  //       case 3: this.popUpId = 'zeroRateDialog'; break;
  //       case 4: this.popUpId = 'hireCoachEmailVerifyandRateAlert'; break;
  //       case 5: this.popUpId = 'hireCoachEmailVerifyAlert'; break;
  //       case 6: this.popUpId = 'hireCoachAlert'; break;
  //       case 7: this.popUpId = 'hireCoachEmailVerifyAlert'; break;
  //       case 8: this.popUpId = 'deleteCoachAlert'; break;
  //       default: this.popUpId = ''; break;
  //     }
  //     this.hireButtonVisibility = true
  //     this.reload.detectChanges()
  //   }
  // }


  selectHireCategory(): number {
    if ((this.IsUserLoggedIn) && (this.isUserVerified == 1) && ((this.shareUserData.singlePackage.length > 0) || (this.shareUserData.multiplePackage.length > 0)) && (this.currentCoachData.ContractsID == null) && (this.currentCoachData.StatusID == 1)) {
      return 1;
    }
    else
      if ((this.IsUserLoggedIn) && (this.isUserVerified == 1) && ((this.shareUserData.singlePackage.length > 0) || (this.shareUserData.multiplePackage.length > 0)) && (this.currentCoachData.ContractsID == null) && (this.currentCoachData.StatusID != 1)) {
        return 2;
      }
      else if ((this.IsUserLoggedIn) && (this.isUserVerified == 1) && (this.currentCoachData.StatusID == 1) && (this.shareUserData.singlePackage.length <= 0) && (this.shareUserData.multiplePackage.length <= 0)) {
        return 3
      }
      else if ((this.IsUserLoggedIn) && (this.isUserVerified == 1) && (this.currentCoachData.StatusID != 1) && ((this.shareUserData.singlePackage.length <= 0) && (this.shareUserData.multiplePackage.length <= 0))) {
        return 4
      }
      else if ((this.IsUserLoggedIn) && (this.isUserVerified == 1) && (this.currentCoachData.StatusID != 1) && ((this.shareUserData.singlePackage.length > 0) && (this.shareUserData.multiplePackage.length > 0))) {
        return 5
      }
      else if ((this.IsUserLoggedIn) && (this.currentCoachData.StatusID == 1) && (this.currentCoachData.ContractsID != null) && (this.isUserVerified == 1)) {
        return 6
      }
      else if ((this.IsUserLoggedIn) && (this.isUserVerified != 1)) {
        return 7
      }
      else if ((this.IsUserLoggedIn) && (this.currentCoachData.StatusID == -1) && (this.isUserVerified == 1)) {
        return 8
      }
    // else {
    //   return 0
    // }
  }
  // selectPopUpCategory(): number {
  //   if (this.currentUserID != this.currentCoachData.ID && this.currentCoachData.FreeConsultStatus == true && this.currentCoachData.ContractsID == null && this.currentCoachData.StatusID == 1 && this.loggedInUserData.StatusID != 1) {
  //     return 1;
  //   }
  //   else if (this.currentUserID != this.currentCoachData.ID && this.currentCoachData.FreeConsultStatus == true && this.currentCoachData.ContractsID == null && this.currentCoachData.StatusID != 1 && this.loggedInUserData.StatusID == 1) {
  //     return 2;
  //   }
  // else if (this.currentUserID != this.currentCoachData.ID && this.currentCoachData.FreeConsultStatus == true && this.currentCoachData.ContractsID == null && this.currentCoachData.StatusID == 1 && this.loggedInUserData.StatusID == 1) {
  //   return 3;
  // }
  // }

  loginPageLink() {
    this._router.navigateByUrl(Constants.loginPath);
  }

  SignupPageLink() {
    this._router.navigateByUrl(Constants.signUpPath);
  }

  isFollowUser(CoachId: any, isFollow: boolean) {
    let data = {
      "CoachId": CoachId,
      "isFollow": isFollow
    }
    this.enableBtn = false;

    this.isFollowAPI = this.FollowProfileService.isFollow(data).subscribe((jsonData) => {
      this.enableBtn = true;
      if (jsonData.response) {
        if (jsonData.response.data.isFollow == true) {
          this.currentCoachData.isFollow = true;
          this.updateHomeData(Constants.homeDataVar, jsonData.response.data.isFollow, CoachId);
        } else {
          this.currentCoachData.isFollow = false;
          this.updateHomeData(Constants.homeDataVar, jsonData.response.data.isFollow, CoachId);
        }
        this.reload.detectChanges();
      } else {
        this.toast.error(jsonData.response.err, Constants.errorTitle);
      }
    }, error => {
      this.loadingExt = false;
    })
  }

  updateHomeData(fullData: any, status: boolean, coachId: any) {
    var count = 0;
    var coachSkillCount = 0;

    if (fullData.featuredUsers.length > 0) {
      fullData.featuredUsers.forEach((featuredUserData: any) => {
        if (featuredUserData.ID == coachId) {
          fullData.featuredUserData[count].isFollow = status;
          Constants.homeDataVar = fullData;
        }
        count++;
      });
    }

    count = 0;
    if (fullData.lastSearchUsers.length > 0) {
      fullData.lastSearchUsers.forEach((lastUserSearch: any) => {
        if (lastUserSearch.ID == coachId) {
          fullData.lastSearchUsers[count].isFollow = status;
          Constants.homeDataVar = fullData;
        }
        count++;
      });
    }

    count = 0;
    if (fullData.allSkills.length > 0) {
      let allSkills = fullData.allSkills;
      let currentSkill;
      allSkills.forEach((singleSkillData: any) => {
        count = 0;
        currentSkill = singleSkillData.skillData;
        currentSkill.forEach((currentSkillUser: any) => {
          if (currentSkillUser.ID == coachId) {
            fullData.allSkills[coachSkillCount].skillData[count].isFollow = status;
            Constants.homeDataVar = fullData;
          }
          count++;
        });
        coachSkillCount++;
      });
    }
  }


  removeTags(str: any) {
    str = str.toString();
    return str.replace(/(<([^>]+)>)/ig, '');
  }

  onClick(val: any = '') {
    this.notifyhome.emit(val)
  }

  onNotifyClicked(val: any) {
    if (val == 'Hire Request Send') {
      this.onClick('Hire Request Send');
      this.reload.detectChanges()
    }
    if (val == '') {
      this.openHireModal = false
    }
  }

  ngOnDestroy() {
    if (this.getPackageDataAPI) {
      this.getPackageDataAPI.unsubscribe();
    }

    if (this.isFollowAPI) {
      this.isFollowAPI.unsubscribe();
    }
  }

  redirect(handle:any, event:any) {
    this.genericFunc.amplitudeFunc(event);
    this._router.navigate(['coach', handle])
    // this._router.navigateByUrl(environment.angularSiteURL+'coach/'+handle);
  }
}