<div class="settings-container">
  <div class="row">
    <div class="col-xs-12">
      <div class="row marginTop2em">
        <h6 class="setting-title">Microphone</h6>
      </div>
      <div class="row">
        <div class="col-xs-8">
          <select class="form-control form-control-sm" [value]="selectedAudioInId"
            (change)="onMicChange($event.target.value)">
            <option *ngFor="let info of micDevicesInfos" [value]="info.deviceId">
              {{ info.label }}
            </option>
          </select>
        </div>
        <div class="col-xs-4">
          <div class="d-flex">
            <i class="fa fa-microphone" aria-hidden="true"></i>
            <app-sound-meter></app-sound-meter>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row marginTop2em">
    <div class="col-xs-12">
      <div class="row">
        <h6 class="setting-title">Speaker</h6>
      </div>
      <div class="row">
        <div class="col-xs-8">
          <select class="form-control form-control-sm" [value]="selectedAudioOutId"
            (change)="onSpeakerChange($event.target.value)">
            <option *ngFor="let info of speakerDevicesInfos" [value]="info.deviceId">
              {{ info.label }}
            </option>
          </select>
        </div>
        <div class="col-xs-4">
          <a (click)="onTestAudio()">
            <span>
              <i class="fa fa-bullhorn" aria-hidden="true"></i>
              Test
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <div class="row marginTop2em">
        <h6 class="setting-title">Camera</h6>
      </div>
      <div class="row">
        <div class="col-xs-8">
          <select class="form-control form-control-sm" [value]="selectedVideoId"
            (change)="onCameraChange($event.target.value)">
            <option *ngFor="let info of camDevicesInfos" [value]="info.deviceId">
              {{ info.label }}
            </option>
          </select>
        </div>
        <div class="col-xs-4">
          <div class="d-flex">
            <!-- <div class="camera-container">
              <video #videoSample id="videoSample" class="camera"></video>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

