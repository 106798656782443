import { Injectable } from '@angular/core';
import { ApiManagerComponent } from '../../../providers/api-manager/api-manager';
import { Constants } from '../../../consts';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";

@Injectable()
export class HomeService {

  constructor(
    private apiManager: ApiManagerComponent,
  ) { }

  getHomeData(): Observable<any> {
    return this.apiManager.getMethod(Constants.getHomeData, true).pipe(map((apiResponse) => {
      return apiResponse
    }));
  }

  getMethodWithoutToken(): Observable<any> {
    return this.apiManager.getMethodWithoutToken(Constants.getMethodWithoutToken, true).pipe(map((apiResponse) => {
      return apiResponse
    }));
  }

}
