import { Constants } from './../consts';



export class koacheeOfferListModel{
    public loading = false;
    public offerData: any = [];
    public noImage = Constants.noImg;
    public offersDropdown: any = 'Active';
    public contractsDropdown: any = 'Coach';
    public currentUserType: any;
    public getAllOffersList: any;
    public keyword: any = '';
    public sessionRate: any;
    public rate: any;
    public currentUserID: any;
    public currentOffer: any;
    public userAccessAPI: any;
    public totalAmount: any;
    public invoiceUserName: any = 0;
    public currentOfferId: number = 0;
    public additionalComments: any = '';
    public modalType: string = '';
    public offerUserName: string = '';
    public name: string = '';
    public offerId: number;
    public showSendInvoice: boolean = false;
    public offerUserId: number;
    public sessionCount: any;  
    public showConcurrencyModal: boolean = false;
    public showRecurringPaymentModal: boolean = false;
    public concurrencyModalMessage: any;
    public allInvoiceData:any;
    public proposalId:any;
    public proposalUserId:any;
    public isLaKarte:boolean = false;
    public isPackage:boolean =  false;
    public reviewRecieverId:any;
    public coachName:any;
    public question0:any;
    public question1:any;
    public question2:any;
    public question3:any;
    public reviewComment:any;
    public ratingQuestions:any;
    public enableButton:any;
    public sendReviewOfferId:any;
    public sendReviewRecieverId:any;
    public reviewAddedForOfferId:any;
    public alreadyHiredModal:any;
    public alreadyHiredUserName:any;
    public userExpertise:any;
    public currentProposal:any;
    public rehireProfileData:any;
    public rehire:any;
    public showSubmitReviewModal:boolean = false;
    public getInvoiceDetailAPI:any;
    public getSingleInvoiceDetailAPI:any;
    public getRatingQuestionAPI: any;
    public reviewByKoacheeAPI: any;
    public getProfileAPI: any;
    public getAllOffersListAPI: any;
    public invoiceSubmittedAPI: any;
    public getContractDetailsAPI: any;
    public getSessionStatusAPI: any;
    public checkEndContractSec: any;
    public showPayInvoiceModal: any = false;
    public changeContactStatusModal: any = false;
    public openModalCoachModal: any = false;
    public reviewDetailModal: any = false;
    public noSchedulingServiceModal: any = false;
    public currentCardIndex: any;
    public schedulingModalCoachUserID: any;
    public schedulingModal: boolean= false;

    public currentInvoiceAmount: number = 0;
    public currentInvoiceDividedAmount: any = 0;
    public numberOfPayments: number = 1;

    public paymentType: any = 'singleInvPayment';
    public billingType: any = 'Month';
    public timePeriod: any = 1;
}