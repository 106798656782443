import { Injectable } from '@angular/core';
import { Constants } from '../../consts'

@Injectable({
  providedIn:'root'
})
export class LocalStorageComponent {
  public constructor() { }

  // Profile Key Start
  getProfile() {
    if (localStorage.getItem(Constants.profileKey)) {
      return localStorage.getItem(Constants.profileKey);
    }
  }

  setProfile(response: any) {
    localStorage.setItem(Constants.profileKey, response);
    return true;
  }

  clearProfile() {
    localStorage.removeItem(Constants.profileKey);
  }
  // Profile Key Ends

  // Token Start
  getToken() {
    if (localStorage.getItem(Constants.tokenKey)) {
      return localStorage.getItem(Constants.tokenKey);
    }
  }

  setToken(response: any) {
    localStorage.setItem(Constants.tokenKey, response);
    return true;
  }

  clearToken() {
    localStorage.removeItem(Constants.tokenKey);
  }
  // Token Ends

  // Skills Start
  getSkills() {
    if (localStorage.getItem(Constants.skillsKey)) {
      return JSON.parse(localStorage.getItem(Constants.skillsKey));
    }
  }

  setSkills(response: any) {
    this.clearSkills();
    localStorage.setItem(Constants.skillsKey, JSON.stringify(response));
  }

  clearSkills() {
    localStorage.removeItem(Constants.skillsKey);
  }
  // Skills Ends

  // Notification Start
  getNotification() {
    if (localStorage.getItem(Constants.notificationKey)) {
      return JSON.parse(localStorage.getItem(Constants.notificationKey));
    }
  }

  setNotification(response: any) {
    localStorage.setItem(Constants.notificationKey, response);
    return true;
  }

  clearNotification() {
    localStorage.removeItem(Constants.notificationKey);
  }
  // Notification Ends


  // Notification Count Start
  getNotificationCount() {
    if (localStorage.getItem(Constants.notificationCountKey)) {
      return JSON.parse(localStorage.getItem(Constants.notificationCountKey));
    }
  }

  setNotificationCount(response: any) {
    localStorage.setItem(Constants.notificationCountKey, response);
    return true;
  }

  clearNotificationCount() {
    localStorage.removeItem(Constants.notificationCountKey);
  }
  // Notification Count Ends

  // Unread Message Count Start
  getUnreadMessagesCount() {
    if (localStorage.getItem(Constants.unreadMessagesCountKey)) {
      return JSON.parse(localStorage.getItem(Constants.unreadMessagesCountKey));
    }
  }

  setUnreadMessagesCount(response: any) {
    localStorage.setItem(Constants.unreadMessagesCountKey, response);
    return true;
  }

  clearUnreadMessagesCount() {
    localStorage.removeItem(Constants.unreadMessagesCountKey);
  }
  // Unread Message Count Ends

  // Notification Start
  getPublishProfile() {
    if (localStorage.getItem(Constants.publishProfileKey)) {
      return JSON.parse(localStorage.getItem(Constants.publishProfileKey));
    }
  }

  setPublishProfile(response: any) {
    localStorage.setItem(Constants.publishProfileKey, response);
    return true;
  }

  clearPublishProfile() {
    localStorage.removeItem(Constants.publishProfileKey);
  }
  // Notification Ends


  // Notification Start
  getExpertReviewBar() {
    if (localStorage.getItem(Constants.expertReviewBarKey)) {
      return JSON.parse(localStorage.getItem(Constants.expertReviewBarKey));
    }
  }

  setExpertReviewBar(response: any) {
    localStorage.setItem(Constants.expertReviewBarKey, response);
    return true;
  }

  clearExpertReviewBar() {
    localStorage.removeItem(Constants.expertReviewBarKey);
  }
  // Notification Ends


  // Notification Start
  getCompleteProfilePage() {
    if (localStorage.getItem(Constants.completeProfileKey)) {
      return localStorage.getItem(Constants.completeProfileKey);
    }
  }

  setCompleteProfilePage(response: any) {
    localStorage.setItem(Constants.completeProfileKey, response);
    return true;
  }

  clearCompleteProfilePage() {
    localStorage.removeItem(Constants.completeProfileKey);
  }
  // Notification Ends

  // Notepad Start
  getNotePad() {
    if (localStorage.getItem(Constants.notepadKey)) {
      return localStorage.getItem(Constants.notepadKey);
    }
  }

  setNotePad(response: any) {
    localStorage.setItem(Constants.notepadKey, response);
    return true;
  }

  clearNotePad() {
    localStorage.removeItem(Constants.notepadKey);
  }


  getResizeNotePad() {
    if (localStorage.getItem(Constants.resizeNotepadKey)) {
      return localStorage.getItem(Constants.resizeNotepadKey);
    }
  }

  setResizeNotePad(response: any) {
    localStorage.setItem(Constants.resizeNotepadKey, response);
    return true;
  }

  clearResizeNotePad() {
    localStorage.removeItem(Constants.resizeNotepadKey);
  }

  // Notepad Ends


  getKoacheeSuitePlan() {
    if (localStorage.getItem(Constants.koacheeSuitePlanKey)) {
      return localStorage.getItem(Constants.koacheeSuitePlanKey);
    }
  }

  setKoacheeSuitePlan(response: any) {
    localStorage.setItem(Constants.koacheeSuitePlanKey, response);
    return true;
  }

  clearKoacheeSuitePlan() {
    localStorage.removeItem(Constants.koacheeSuitePlanKey);
  }

  getSuitePlan() {
    if (localStorage.getItem(Constants.suitePlanKey)) {
      return JSON.parse(localStorage.getItem(Constants.suitePlanKey)) ;
    }
  }

  setSuitePlan(response: any) {
    localStorage.setItem(Constants.suitePlanKey, response);
    return true;
  }

  clearSuitePlan() {
    localStorage.removeItem(Constants.suitePlanKey);
  }

  clearAllValues() {
    this.clearProfile();
    this.clearToken();
    this.clearPublishProfile();
    this.clearNotification();
    this.clearNotificationCount();
    this.clearUnreadMessagesCount()
    this.clearExpertReviewBar()
    this.clearCompleteProfilePage()
    this.clearNotePad();
    this.clearResizeNotePad();
    this.clearKoacheeSuitePlan();
    this.clearSuitePlan();
    // this.clearSkills(); // Not clearing the skills because it is used at both with and without login using local storage.
  }
}
