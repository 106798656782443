import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GenericFunctionsComponent } from '../../providers/generic-functions/generic-functions';
import * as io from 'socket.io-client'
import { Constants } from '../../consts';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn:'root'
})
export class SocketServiceService {

  public data = {}

  // broadcast the value which we get from the server on the socket into the whole app
  public broadCastSocket = new BehaviorSubject<Object>(this.data);

  socket: SocketIOClient.Socket;
  public currentUserID: any;

  constructor(private genericFunc: GenericFunctionsComponent) {
    this.currentUserID = this.genericFunc.getLoggedInUserID();
  }

  // connect to socket to send and recieve the data
  connectSocket() {
    // if (this.socket) {
    this.closeSocket();
    // }
    // if (!this.socket) {

    // setTimeout(() => {
    this.socket = io.connect(environment.baseUrl);
    this.socket.emit('connection', {
      msg: 'Client to server, can you hear me server?'
    });
    this.currentUserID = this.genericFunc.getLoggedInUserID();
    this.socket.emit('disconnect', { userId: JSON.stringify(this.currentUserID) });
    this.socket.emit('login', { userId: JSON.stringify(this.currentUserID) });
    this.configureSocketFunc()
    // }, 3000);
    // }

  }

  // close the socket and delete the socket
  closeSocket() {
    if (this.socket) {
      this.socket.disconnect();
      delete this.socket;
      this.socket = null;
    }
  }

  // recieve the value from the server and check that is the value is for current user or not
  configureSocketFunc() {
    this.currentUserID = this.genericFunc.getLoggedInUserID();
    this.socket.on('socketActivity', (response: any) => {
      if (response) {
        if (response.socketMsg) {
          if (this.currentUserID === Number(response.socketMsg.receiverID)) {
            this.broadCastSocket.next(response.socketMsg);
            this.broadCastSocket.next(this.data);
          }
        }
      }
    });
  }

  // Update the reciever chat last message status and set it to true while doing chat on the socket
  updateRecieverChatReadStatus(senderId: any) {
    this.currentUserID = this.genericFunc.getLoggedInUserID();
    this.socket.emit('updateRecieverChatReadStatus',
      {
        RecipientUserID: JSON.stringify(this.currentUserID),
        CreatedByUserID: JSON.stringify(senderId),
      }
    );
  }

}
